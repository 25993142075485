import React from 'react';
import { Stack, Typography, Popover, Box, Grid } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

type UserInfo = {
  Name: string;
  Email: string;
};
interface IProps {
  from: UserInfo[];
  to: UserInfo[];
  cc: UserInfo[];
}
const MetadataSection = ({ from, to, cc }: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  return (
    <>
      <Stack direction="row" gap={0.5} alignItems="center">
        <Typography variant="p12" color="neutral.n300">
          to:{' '}
        </Typography>
        <Typography variant="p12"> {to.map((e) => e?.Name).join(', ')} </Typography>
        <Box
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
          sx={{ cursor: 'pointer' }}
        >
          <ArrowDropDownIcon sx={{ fontSize: '14px', color: 'neutral.n400' }} />
        </Box>
      </Stack>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={(e) => {
          // @ts-ignore
          e.stopPropagation();
          setAnchorEl(null);
        }}
      >
        <Stack p={1} sx={{ minWidth: '350px' }} gap={1.5} onClick={(e) => e.stopPropagation()}>
          <Stack direction="row" gap={2}>
            <Stack sx={{ width: '60px' }} direction="row" justifyContent="end">
              <Typography variant="p12" color="neutral.n400">
                from:
              </Typography>
            </Stack>
            <Stack sx={{ flexGrow: '1' }} gap={0.5}>
              {from.map((user) => (
                <Stack key={user?.Email} gap={0.3} direction="row" alignItems="center">
                  <Typography variant="labelMedium12">{user?.Name}</Typography>
                  <Typography variant="p12">{`<${user?.Email}>`}</Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
          <Stack direction="row" gap={2}>
            <Stack sx={{ width: '60px' }} direction="row" justifyContent="end">
              <Typography variant="p12" color="neutral.n400">
                to:
              </Typography>
            </Stack>
            <Stack sx={{ flexGrow: '1' }} gap={0.5}>
              {to.map((user) => (
                <Stack key={user?.Email} gap={0.3} direction="row" alignItems="center">
                  <Typography variant="labelMedium12">{user?.Name}</Typography>
                  <Typography variant="p12">{`<${user?.Email}>`}</Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
          <Stack direction="row" gap={2}>
            <Stack sx={{ width: '60px' }} direction="row" justifyContent="end">
              <Typography variant="p12" color="neutral.n400">
                cc:
              </Typography>
            </Stack>
            <Stack sx={{ flexGrow: '1' }} gap={0.5}>
              {cc.map((user) => (
                <Stack key={user?.Email} gap={0.3} direction="row" alignItems="center">
                  <Typography variant="labelMedium12">{user?.Name}</Typography>
                  <Typography variant="p12">{`<${user?.Email}>`}</Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export default MetadataSection;
