import React from 'react';
import { Stack, Typography, Avatar, CircularProgress, AvatarGroup, Tooltip } from '@mui/material';
import format from 'date-fns/format';
import { useGetEventsDataByEventsIdQuery, useGetMigrationTableDataByIdQuery } from 'store/migration/api';
import { NodeToolbar, Position } from 'reactflow';
import { isBefore } from 'date-fns';

interface IProps {
  migrationId: string;
  eventId: string;
  isOpen?: boolean;
}
const EventTooltip = ({ eventId, migrationId, isOpen = false }: IProps) => {
  const [tooltipVisible, setTooltipVisible] = React.useState<boolean>(false);

  const { data: eventsData = [], isFetching: eventsLoading } = useGetEventsDataByEventsIdQuery({
    migrationId,
    eventsIds: [eventId],
  });

  const data = React.useMemo(() => eventsData[0], [eventsData]);

  const { data: contacts = [], isFetching: contactsLoading } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'Contact',
      skip: 0,
      take: 0,
      conditions: [{ field: 'Id', operator: 'IN', value: `('${data?.WhoId}','${data?.WhatId}','${data?.OwnerId}')` }],
    },
    {
      skip: !data,
    },
  );

  const isVisible = React.useMemo(() => tooltipVisible || isOpen, [tooltipVisible, isOpen]);

  const meetingPast = React.useMemo(() => {
    if (data?.ActivityDate || data?.ActivityDateTime) {
      return isBefore(new Date(data?.ActivityDate ?? data?.ActivityDateTime), new Date());
    }
    return false;
  }, [data]);
  return (
    <NodeToolbar
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
      isVisible={isVisible}
      position={Position.Left}
    >
      <Stack
        sx={{
          zIndex: 999,
          p: 1,
          backgroundColor: 'blue.dark',
          borderRadius: '5px',
          color: 'common.white',
          width: '230px',
        }}
        gap={0.5}
      >
        <Typography variant="b12" color="common.white" mb={1}>
          {eventsLoading ? <CircularProgress sx={{ color: 'white', width: '12px', height: '12px' }} /> : data?.Subject}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Date</Typography>
          {eventsLoading ? (
            <CircularProgress sx={{ color: 'white', width: '12px', height: '12px' }} />
          ) : (
            <Typography variant="labelMedium12">
              {data?.ActivityDateTime ? format(new Date(data?.ActivityDateTime), 'MMM dd, h:mm aa') : null}
            </Typography>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Location</Typography>
          {eventsLoading ? (
            <CircularProgress sx={{ color: 'white', width: '12px', height: '12px' }} />
          ) : (
            <Typography variant="labelMedium12">{data?.Location}</Typography>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Attendee</Typography>
          {contactsLoading ? (
            <CircularProgress sx={{ color: 'white', width: '12px', height: '12px' }} />
          ) : (
            <AvatarGroup>
              {contacts
                .filter((single) => single?.Id !== data?.OwnerId)
                .map((singleContact) => (
                  <Tooltip
                    key={`avatar_${singleContact?.Id}`}
                    title={singleContact?.Name ?? `${singleContact?.FirstName} ${singleContact?.LastName}`}
                  >
                    <Avatar sx={{ width: '18px', height: '18px', fontSize: '12px' }}>
                      {(singleContact?.Name || singleContact?.FirstName || '')[0]}
                    </Avatar>
                  </Tooltip>
                ))}
              {data?.Attendees?.filter(
                (singleAttendee: any) =>
                  !contacts.some((singleContact) => singleContact?.Email === singleAttendee?.emailAddress?.address),
              )?.map((singleAttendee: any, index: number) => (
                <Tooltip
                  key={`event_attendee_${singleAttendee?.emailAddress?.address}_${index}`}
                  title={singleAttendee?.emailAddress?.address}
                >
                  <Avatar sx={{ width: '20px', height: '20px', textTransform: 'uppercase', fontSize: '14px' }}>
                    {singleAttendee?.emailAddress?.name
                      ?.split(' ')
                      .map((e: string) => e?.[0])
                      .join('')}
                  </Avatar>
                </Tooltip>
              ))}
            </AvatarGroup>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Meeting status</Typography>
          {eventsLoading ? (
            <CircularProgress sx={{ color: 'white', width: '12px', height: '12px' }} />
          ) : (
            <Typography variant="labelMedium12" color={meetingPast ? 'green.main' : 'blue.main'}>
              {meetingPast ? 'Past' : 'Upcoming'}
            </Typography>
          )}
        </Stack>
      </Stack>
    </NodeToolbar>
  );
};

export default EventTooltip;
