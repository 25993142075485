import React, { useMemo } from 'react';
import { Stack, Typography, Grid, Avatar, Tooltip, AvatarGroup } from '@mui/material';
import { differenceInMinutes, format, isBefore } from 'date-fns';
import { useGetMigrationTableDataByIdQuery, useGetEventsDataByEventsIdQuery } from 'store/migration/api';
import { extractTeamsMeetingLink, isHtml } from '../../utils';
import LaunchIcon from '@mui/icons-material/Launch';

interface IProps {
  type: 'event' | 'task';
  nodeData: any;
  migrationId: string;
}

const TaskEventDetailsContainer = ({ type, nodeData, migrationId }: IProps) => {
  const {
    id,
    Status,
    ActivityDate,
    StartDateTime,
    EndDateTime,
    ActivityDateTime,
    Location,
    Description,
    OwnerId,
    CreatedDate,
  } = nodeData;

  const isDescriptionInHtml = isHtml(Description);

  const { data: usersData = [] } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'User',
      skip: 0,
      take: 0,
      conditions: [
        {
          field: 'Id',
          operator: '=',
          value: `'${OwnerId}'`,
        },
      ],
    },
    {
      skip: !OwnerId,
    },
  );
  const { data: eventDetails = [] } = useGetEventsDataByEventsIdQuery(
    {
      migrationId,
      eventsIds: [id],
    },
    {
      skip: type !== 'event',
    },
  );

  const eventDetail = useMemo(() => (eventDetails.length ? eventDetails[0] : null), [eventDetails]);

  return (
    <Grid direction="row" container columns={2} rowGap={2} p={1}>
      <Grid item xs={1}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            Status
          </Typography>
          <Stack
            sx={{
              px: 4,
              py: 1,
              borderRadius: '20px',
              backgroundColor: 'primary.subtone1',
              width: 'fit-content',
            }}
          >
            {type === 'event' && (
              <Typography variant="p12" color="common.white">
                {isBefore(new Date(ActivityDateTime), new Date()) ? 'Past' : 'Upcoming'}
              </Typography>
            )}
            {type === 'task' && (
              <Typography variant="p12" color="common.white">
                {Status}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={1}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            {type === 'event' ? 'Start' : 'Due on'}
          </Typography>
          {type === 'event' && (
            <Typography variant="p14">
              {StartDateTime ? format(new Date(StartDateTime), 'MMM dd, hh:mm aa') : ''}
            </Typography>
          )}
          {type === 'task' && (
            <Typography variant="p14">
              {ActivityDate ? format(new Date(ActivityDate), 'MMM dd, hh:mm aa') : ''}
            </Typography>
          )}
        </Stack>
      </Grid>
      {type === 'event' && (
        <Grid item xs={1}>
          <Stack gap={0.5}>
            <Typography variant="p12" color="neutral.n400">
              Duration
            </Typography>
            {EndDateTime && StartDateTime && (
              <Typography variant="p14">
                {differenceInMinutes(new Date(EndDateTime), new Date(StartDateTime))} minutes
              </Typography>
            )}
          </Stack>
        </Grid>
      )}
      <Grid item xs={1}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            Attendees
          </Typography>
          <AvatarGroup sx={{ justifyContent: 'start' }}>
            {eventDetail?.Attendees?.map((singleAttendee: any, index: number) => (
              <Tooltip
                key={`event_attendee_${singleAttendee?.emailAddress?.address}_${index}`}
                title={singleAttendee?.emailAddress?.address}
              >
                <Avatar sx={{ width: '20px', height: '20px', textTransform: 'uppercase', fontSize: '14px' }}>
                  {singleAttendee?.emailAddress?.name
                    ?.split(' ')
                    .map((e: string) => e?.[0])
                    .join('')}
                </Avatar>
              </Tooltip>
            ))}
          </AvatarGroup>
        </Stack>
      </Grid>
      {type === 'event' && (
        <Grid item xs={2}>
          <Typography variant="p12" color="neutral.n400">
            Location
          </Typography>
          <Stack>
            {extractTeamsMeetingLink(Description) ? (
              <a href={extractTeamsMeetingLink(Description) as string} rel="noreferrer" target="_blank">
                <Stack direction="row" alignItems="center" gap={0.5}>
                  <Typography
                    variant="p14"
                    color="blue.main"
                    sx={{
                      ':hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {Location}
                  </Typography>
                  <LaunchIcon sx={{ color: 'blue.main', fontSize: '14px' }} />
                </Stack>
              </a>
            ) : (
              <Typography variant="p14" fontWeight="600">
                {Location}
              </Typography>
            )}
          </Stack>
        </Grid>
      )}
      <Grid item xs={2}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            Notes
          </Typography>
          {isDescriptionInHtml ? (
            <div
              style={{ border: '1px solid #00000010', padding: '5px' }}
              dangerouslySetInnerHTML={{ __html: Description }}
            />
          ) : (
            <Typography variant="p14">{Description}</Typography>
          )}
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            Follow-up required
          </Typography>

          <Typography variant="p14">Yes</Typography>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            {type === 'event' ? 'Organizer' : 'Created by'}
          </Typography>

          <Typography variant="p14">
            {usersData[0]?.Name} on {CreatedDate ? format(new Date(CreatedDate), 'MMM dd, hh:mm aa') : ''}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default TaskEventDetailsContainer;
