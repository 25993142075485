const nodeDefaults = {
  style: {
    borderRadius: '100%',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export const specialEventTypes = ['Call', 'Email', 'Meeting', 'Task', 'Event'];

export enum NodeRecordTypeEnum {
  RecordNode = 'RecordNode',
  AccountNode = 'AccountNode',
  EventRecordNode = 'EventRecordNode',
}

export interface NodeBridgeData {
  id?: string;
  [x: string]: any;
}
export interface NodeBridgeProperties {
  position?: { x: number; y: number };
  type?: NodeRecordTypeEnum;
  hidden: boolean;
  draggable?: boolean;
}

export class RecordNode {
  public data: any;
  public properties: any;

  constructor(
    id: string,
    label: string,
    options: {
      data?: NodeBridgeData;
      bridgeLabel?: string;
      metaData?: any;
      properties?: NodeBridgeProperties;
      style?: React.CSSProperties;
    } = {},
  ) {
    const { data = {}, metaData = {}, properties = { hidden: false }, style } = options;
    const styleByType = this.getNodeTypeStyle(properties.type || NodeRecordTypeEnum.RecordNode);
    this.data = {
      ...data,
      metaData: metaData,
      label: label,
      id: id,
    };
    this.properties = {
      type: 'RecordNode',
      style: { ...nodeDefaults.style, ...styleByType, ...style },
      ...properties,
    };
  }

  getNodeTypeStyle(type: NodeRecordTypeEnum) {
    switch (type) {
      case NodeRecordTypeEnum.RecordNode:
        return { width: '20px', height: '20px' };
      case NodeRecordTypeEnum.AccountNode:
        return { width: '100px', height: '100px' };
      case NodeRecordTypeEnum.EventRecordNode:
        return { width: '15px', height: '15px' };
      default:
        return { width: '25px', height: '25px' };
    }
  }
}
