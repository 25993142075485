import React from 'react';
import { Stack, Switch, SvgIconProps, Typography, Checkbox, TextField, Radio, MenuItem } from '@mui/material';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';

const SettingsView = () => {
  const [collapsedSections, setCollapsedSections] = React.useState<string[]>([]);

  const handleCollapse = (section: string) => {
    setCollapsedSections((prev) =>
      prev.includes(section) ? prev.filter((element) => element !== section) : [...prev, section],
    );
  };
  return (
    <Stack gap={2}>
      <Stack gap={0.5}>
        <Typography variant="b16">Settings</Typography>
        <Typography variant="p12" color="neutral.n300">
          Adjust your preferences to ensure you stay informed without being overwhelmed.
        </Typography>
      </Stack>

      <Stack gap={0.5}>
        <Stack
          direction={'row'}
          alignItems="center"
          justifyContent="space-between"
          p={0.5}
          sx={{ backgroundColor: 'primary.subtone2', color: 'primary.main', cursor: 'pointer' }}
          onClick={() => handleCollapse('push')}
        >
          <Typography variant="p14">ALLOW PUSH NOTIFICATIONS</Typography>

          {collapsedSections.includes('push') ? (
            <ExpandMoreOutlinedIcon sx={{ fontSize: '20px' }} />
          ) : (
            <ExpandLessOutlinedIcon sx={{ fontSize: '20px' }} />
          )}
        </Stack>
        {!collapsedSections.includes('push') && (
          <Stack gap={0.5}>
            <Stack direction={'row'} alignItems="center" justifyContent="space-between" p={0.5}>
              <Stack direction="row" gap={2} alignItems="center">
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    border: '1px solid',
                    borderColor: 'primary.main',
                  }}
                >
                  <NotificationsOutlinedIcon sx={{ fontSize: '16px', color: 'primary.main' }} />
                </Stack>
                <Stack>
                  <Typography variant="p14">Allow Push Notifications</Typography>
                  <Typography variant="p12" color="neutral.n300">
                    Explicative description about the notification
                  </Typography>
                </Stack>
              </Stack>

              <Switch defaultChecked />
            </Stack>
          </Stack>
        )}
      </Stack>
      <Stack gap={0.5}>
        <Stack
          direction={'row'}
          alignItems="center"
          justifyContent="space-between"
          p={0.5}
          sx={{ backgroundColor: 'primary.subtone2', color: 'primary.main', cursor: 'pointer' }}
          onClick={() => handleCollapse('categories')}
        >
          <Typography variant="p14">WHAT WOULD YOU LIKE TO BE NOTIFIED ABOUT</Typography>

          {collapsedSections.includes('categories') ? (
            <ExpandMoreOutlinedIcon sx={{ fontSize: '20px' }} />
          ) : (
            <ExpandLessOutlinedIcon sx={{ fontSize: '20px' }} />
          )}
        </Stack>
        {!collapsedSections.includes('categories') && (
          <Stack gap={0.5}>
            <Stack direction={'row'} alignItems="center" justifyContent="space-between" p={0.5}>
              <Stack direction="row" gap={2} alignItems="center">
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    border: '1px solid',
                    borderColor: 'purple.main',
                    backgroundColor: 'purple.light',
                  }}
                >
                  <StorageOutlinedIcon sx={{ fontSize: '16px', color: 'purple.main' }} />
                </Stack>
                <Stack>
                  <Typography variant="p14">Data Quality</Typography>
                  <Typography variant="p12" color="neutral.n300">
                    Explicative description about the notification
                  </Typography>
                </Stack>
              </Stack>

              <Switch defaultChecked />
            </Stack>
            <Stack direction={'row'} alignItems="center" justifyContent="space-between" p={0.5}>
              <Stack direction="row" gap={2} alignItems="center">
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    border: '1px solid',
                    borderColor: 'green.main',
                    backgroundColor: 'green.light',
                  }}
                >
                  <StorageOutlinedIcon sx={{ fontSize: '16px', color: 'green.main' }} />
                </Stack>
                <Stack>
                  <Typography variant="p14">Data Enrichment</Typography>
                  <Typography variant="p12" color="neutral.n300">
                    Explicative description about the data enrichment
                  </Typography>
                </Stack>
              </Stack>

              <Switch defaultChecked />
            </Stack>
            <Stack direction={'row'} alignItems="center" justifyContent="space-between" p={0.5}>
              <Stack direction="row" gap={2} alignItems="center">
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    border: '1px solid',
                    borderColor: 'violet.main',
                    backgroundColor: 'violet.light',
                  }}
                >
                  <StorageOutlinedIcon sx={{ fontSize: '16px', color: 'violet.main' }} />
                </Stack>
                <Stack>
                  <Typography variant="p14">Data security and compliance</Typography>
                  <Typography variant="p12" color="neutral.n300">
                    Explicative description about the data security and compliance
                  </Typography>
                </Stack>
              </Stack>

              <Switch defaultChecked />
            </Stack>
          </Stack>
        )}
      </Stack>
      <Stack gap={0.5}>
        <Stack
          direction={'row'}
          alignItems="center"
          justifyContent="space-between"
          p={0.5}
          sx={{ backgroundColor: 'primary.subtone2', color: 'primary.main', cursor: 'pointer' }}
          onClick={() => handleCollapse('means')}
        >
          <Typography variant="p14">HOW WOULD YOU LIKE TO RECEIVE YOUR NOTIFICATIONS</Typography>

          {collapsedSections.includes('means') ? (
            <ExpandMoreOutlinedIcon sx={{ fontSize: '20px' }} />
          ) : (
            <ExpandLessOutlinedIcon sx={{ fontSize: '20px' }} />
          )}
        </Stack>
        {!collapsedSections.includes('means') && (
          <Stack gap={0.5}>
            <Stack direction={'row'} alignItems="center" p={0.5}>
              <Checkbox defaultChecked />
              <Typography variant="p14">Alerts in customer city</Typography>
            </Stack>
            <Stack direction={'row'} alignItems="center" p={0.5}>
              <Checkbox defaultChecked />
              <Stack>
                <Typography variant="p14">Email</Typography>
                <TextField value="johndoe@mail.com" />
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
      <Stack gap={0.5}>
        <Stack
          direction={'row'}
          alignItems="center"
          justifyContent="space-between"
          p={0.5}
          sx={{ backgroundColor: 'primary.subtone2', color: 'primary.main', cursor: 'pointer' }}
          onClick={() => handleCollapse('frequency')}
        >
          <Typography variant="p14">HOW OFTEN WOULD YOU LIKE TO BE NOTIFIED</Typography>

          {collapsedSections.includes('frequency') ? (
            <ExpandMoreOutlinedIcon sx={{ fontSize: '20px' }} />
          ) : (
            <ExpandLessOutlinedIcon sx={{ fontSize: '20px' }} />
          )}
        </Stack>
        {!collapsedSections.includes('frequency') && (
          <Stack gap={0.5}>
            <Stack direction={'row'} alignItems="center" p={0.5}>
              <Radio defaultChecked />
              <Typography variant="p14">As soon as event occurs</Typography>
            </Stack>
            <Stack direction={'row'} alignItems="center" p={0.5}>
              <Radio defaultChecked />
              <Stack>
                <Typography variant="p14">No more often than</Typography>
                <TextField select value="30m">
                  <MenuItem value="30m">Every 30 minute</MenuItem>
                  <MenuItem value="1h">Every hour</MenuItem>
                </TextField>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default SettingsView;
