import { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { PUBLIC_ABS_ROUTE_PATHS } from 'core/constants';
import { Login, CreatePassword, ResetPassword, Signup, SignupStep2, SignupStep3, SignupStep4 } from 'pages';
import { IntegrationRedirect } from 'pages/IntegrationRedirect';
import { VerifyEmail } from 'pages/Signup/VerifyEmail';
import { Invitation } from 'pages/ControlTower/components/Team/components/Invitation';

const PublicRoutes: FC = () => {
  return (
    <Routes>
      <Route path={PUBLIC_ABS_ROUTE_PATHS.login} element={<Login />} />
      <Route path={PUBLIC_ABS_ROUTE_PATHS.signup} element={<Signup />} />
      <Route path={PUBLIC_ABS_ROUTE_PATHS.signup1} element={<SignupStep2 />} />
      <Route path={PUBLIC_ABS_ROUTE_PATHS.signup2} element={<SignupStep3 />} />
      <Route path={PUBLIC_ABS_ROUTE_PATHS.signup3} element={<SignupStep4 />} />
      <Route path={PUBLIC_ABS_ROUTE_PATHS.integrationSignOn} element={<Login />} />
      <Route path={PUBLIC_ABS_ROUTE_PATHS.integrationRedirect} element={<IntegrationRedirect />} />,
      <Route path={PUBLIC_ABS_ROUTE_PATHS.resetPassword} element={<ResetPassword />} />
      <Route path={PUBLIC_ABS_ROUTE_PATHS.verifyEmail} element={<VerifyEmail />} />
      <Route path={PUBLIC_ABS_ROUTE_PATHS.invitation} element={<Invitation />} />
      <Route path={PUBLIC_ABS_ROUTE_PATHS.createPassword}>
        <Route index element={<CreatePassword />} />
        <Route path=":token" element={<CreatePassword />} />
      </Route>
      <Route path="*" element={<Navigate to={PUBLIC_ABS_ROUTE_PATHS.login} replace />} />
    </Routes>
  );
};

export default PublicRoutes;
