import { FC, useState, MouseEvent } from 'react';

import { useNavigate } from 'react-router-dom';
import { ListItemText, List } from '@mui/material';

import { NavRoute } from 'router/types';
import { PRIVATE_ABS_ROUTE_PATHS } from 'core/constants';

import { ReactComponent as MenuHomeIcon } from 'assets/icons/menuHome.svg';
import { ReactComponent as MenuTowerIcon } from 'assets/icons/menuTower.svg';
import { ReactComponent as MenuSetupIcon } from 'assets/icons/setup.svg';

import { ReactComponent as MenuDataRaptorIcon } from 'assets/icons/menuDataRaptorIcon.svg';

import { ReactComponent as MenuDataGraphIcon } from 'assets/icons/menuDataGraph.svg';
import { ReactComponent as MenuSnowflakeIcon } from 'assets/icons/drawer.snowflake.svg';

import { ReactComponent as MenuRuleLibraryIcon } from 'assets/icons/menuRuleLibrary.svg';
import { ReactComponent as MenuIntegrationIcon } from 'assets/icons/menuIntegration.svg';

import { ReactComponent as DotsIcon } from 'assets/icons/dots.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';

import { ReactComponent as OutlineSunIcon } from 'assets/icons/outlineSun.svg';
import { ReactComponent as OutlineMoonIcon } from 'assets/icons/outlineMoon.svg';

import {
  Drawer,
  ListItem,
  ThemeIcon,
  ListItemIcon,
  ToggleButton,
  BottomContainer,
  MainListContainer,
  ToggleButtonGroup,
} from './ui';

import { MenuItem } from './components';

const setupList = [
  { name: 'Duplicate Config', path: PRIVATE_ABS_ROUTE_PATHS.duplicateConfig },
  { name: 'Lead Matching Rules', path: PRIVATE_ABS_ROUTE_PATHS.leadMatchingRules },
];

const routeList: NavRoute[] = [
  { name: 'Home', path: PRIVATE_ABS_ROUTE_PATHS.home, Icon: <MenuHomeIcon /> },
  { name: 'DataGraph', path: PRIVATE_ABS_ROUTE_PATHS.dataGraph, Icon: <MenuDataGraphIcon /> },
  { name: 'GraphSpace', path: PRIVATE_ABS_ROUTE_PATHS.graphSpace, Icon: <MenuSnowflakeIcon /> },
  { name: 'Data Raptor', path: PRIVATE_ABS_ROUTE_PATHS.dataRaptor, Icon: <MenuDataRaptorIcon /> },
  { name: 'Rule Library', path: PRIVATE_ABS_ROUTE_PATHS.ruleLibrary, Icon: <MenuRuleLibraryIcon /> },
  { name: 'Integration', path: PRIVATE_ABS_ROUTE_PATHS.integration, Icon: <MenuIntegrationIcon /> },
  { name: 'Control Tower', path: PRIVATE_ABS_ROUTE_PATHS.controlTower, Icon: <MenuTowerIcon /> },
  { name: 'Setup', path: PRIVATE_ABS_ROUTE_PATHS.setup, Icon: <MenuSetupIcon />, nestedRoutes: setupList },
];

interface DrawerComponentProps {
  open: boolean;
  toggleOpen: () => void;
}

const DrawerComponent: FC<DrawerComponentProps> = ({ open, toggleOpen }) => {
  const [activeTheme, setActiveTheme] = useState('light');
  const navigate = useNavigate();

  const handleChange = (event: MouseEvent<HTMLElement>, selectedTheme: string) => {
    setActiveTheme(selectedTheme);
  };

  const onNavigate = (path: string) => {
    navigate(path);
    toggleOpen();
  };

  return (
    <Drawer variant="temporary" open={open} onClose={toggleOpen}>
      <MainListContainer className="no-scrollbar">
        <List>
          {routeList.map((route) => (
            <MenuItem
              key={route.name}
              Icon={route.Icon}
              label={route.name}
              nestedItems={route.nestedRoutes}
              notifications={route.notifications}
              path={route.path}
              onClick={onNavigate}
            />
          ))}
        </List>
      </MainListContainer>

      <BottomContainer paddingTop={1}>
        <List>
          <ListItem nested onClick={() => onNavigate(PRIVATE_ABS_ROUTE_PATHS.more)}>
            <ListItemIcon>
              <DotsIcon />
            </ListItemIcon>
            <ListItemText primary="More" primaryTypographyProps={{ variant: 'p12' }} />
          </ListItem>

          <ListItem nested onClick={() => onNavigate(PRIVATE_ABS_ROUTE_PATHS.settings)}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" primaryTypographyProps={{ variant: 'p12' }} />
          </ListItem>
        </List>

        <ToggleButtonGroup color="primary" value={activeTheme} exclusive onChange={handleChange}>
          <ToggleButton value="light">
            <ThemeIcon>
              <OutlineSunIcon />
            </ThemeIcon>
            Light
          </ToggleButton>
          <ToggleButton value="dark">
            <ThemeIcon>
              <OutlineMoonIcon />
            </ThemeIcon>
            Dark
          </ToggleButton>
        </ToggleButtonGroup>
      </BottomContainer>
    </Drawer>
  );
};

export default DrawerComponent;
