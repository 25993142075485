// External libraries
import { FC, useCallback, useRef } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import ReactFlow, { applyEdgeChanges, applyNodeChanges, useReactFlow, useStoreApi } from 'reactflow';
// Local components and hooks
import { AccountNode } from './components/CustomNode/AccountNode';
import { EventRecordNode } from './components/CustomNode/EventRecordNode';
import { EventBridgeNode } from './components/CustomNode/EventBridgeNode';
import { RecordNode } from './components/CustomNode/RecordNode';
import { BridgeNode } from './components/CustomNode/BridgeNode';
import { LeadOpenNode } from './components/CustomNode/LeadOpenNode';
import { LeadViewCategoryNode } from './components/CustomNode/LeadView/LeadViewCategoryNode';
import { LeadViewCenterNode } from './components/CustomNode/LeadView/LeadViewCenterNode';
import { FloatingEdge } from './components/CustomEdge/FloatingEdge';
import { EventEdge } from './components/CustomEdge/EventEdge';
import { DuplicationSnackBar } from './components/DuplicationSnackBar';
import NeptuneMigrationModalStatus from './components/NeptuneMigrationModalStatus';
import DuplicateEdge from './components/CustomEdge/DuplicateEdge';
import { useGraphRenderHook } from './GraphRender.hook';
import { MergeModal } from '../MergeModal';
import CreateActivityModal from '../CreateActivityModal';
import { WhiteBackgroundEdge } from './components/CustomEdge/WhiteBackgroundEdge';
import { LeadViewPanel } from './components/ViewPanel/LeadViewPanel';
import { NodePanel } from './components/NodePanel/NodePanel';
import theme from 'core/theme';

const nodeTypes = {
  AccountNode: AccountNode,
  RecordNode: RecordNode,
  BridgeNode: BridgeNode,
  LeadOpenNode: LeadOpenNode,
  LeadViewCategoryNode: LeadViewCategoryNode,
  LeadViewCenterNode: LeadViewCenterNode,
  EventRecordNode: EventRecordNode,
  EventBridgeNode: EventBridgeNode,
};

const edgeTypes = {
  floating: FloatingEdge,
  duplicate: DuplicateEdge,
  whiteBackground: WhiteBackgroundEdge,
  EventEdge: EventEdge,
};

const GraphRender: FC = () => {
  const reactFlow = useReactFlow();
  const { setNodes, setEdges } = useStoreApi().getState();

  const onNodesChange = useCallback(
    (changes: any) => {
      const nodes = reactFlow.getNodes();
      setNodes(applyNodeChanges(changes, nodes));
    },
    [reactFlow, setNodes],
  );

  const onEdgesChange = useCallback(
    (changes: any) => {
      const edges = reactFlow.getEdges();
      setEdges(applyEdgeChanges(changes, edges));
    },
    [reactFlow, setEdges],
  );

  const containerRef = useRef();
  const {
    error,
    loading,
    migrationId,
    neptuneMigrationStatus,
    selectedNode,
    selectedNodeFromSearchBar,
    showDuplicationSnackBar,
    showNeptuneStatusModal,
    showAccountRecordPanel,
    showCreateActivityModal,
    showLeadViewSortPanel,
    setLeadViewSortPanelShow,
    setShowDuplicationSnackBar,
    setShowCreateActivityModal,
    setShowRecordPanel,
    // refreshData,
  } = useGraphRenderHook(containerRef);

  return (
    <>
      <Box sx={{ width: '100%', height: '100%' }}>
        <NeptuneMigrationModalStatus
          open={showNeptuneStatusModal}
          status={
            neptuneMigrationStatus || {
              status: '',
              feedCount: [0],
              progress: 0,
            }
          }
        />

        <Box position={'absolute'} width={'100%'} display={'flex'} justifyContent={'center'}>
          <Box sx={{ width: 'fit-content' }}>
            <DuplicationSnackBar
              open={showDuplicationSnackBar && loading === false}
              onClose={() => setShowDuplicationSnackBar(false)}
            />
          </Box>
        </Box>

        <MergeModal />

        {error && (
          <Grid container display={'flex'} justifyItems={'center'}>
            <Grid item xs={12} sx={{ my: 3 }}>
              <Typography align="center" variant="h2" sx={{ lineHeight: '24px', color: 'neutral.main' }}>
                {error}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Box sx={{ width: '100%', height: '100%', position: 'relative' }} ref={containerRef}>
          <ReactFlow
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            style={{ backgroundColor: theme.palette.neutral.subtone310 }}
            zoomOnDoubleClick={false}
            maxZoom={4}
          ></ReactFlow>
          {((selectedNode && selectedNode.data && selectedNode.data.bridge !== true) ||
            (selectedNodeFromSearchBar &&
              selectedNodeFromSearchBar.data &&
              selectedNodeFromSearchBar.data.bridge !== true)) && (
            <NodePanel
              node={selectedNode || selectedNodeFromSearchBar}
              toggleOpen={setShowRecordPanel}
              open={showAccountRecordPanel}
              migrationId={migrationId}
            />
          )}
          {showLeadViewSortPanel && <LeadViewPanel toggleOpen={setLeadViewSortPanelShow} migrationId={migrationId} />}
          <CreateActivityModal
            open={showCreateActivityModal}
            handleCloseMergeModal={() => setShowCreateActivityModal(false)}
            migrationId={migrationId}
          />
        </Box>
      </Box>
    </>
  );
};

export default GraphRender;
