import React from 'react';
import { Stack, Typography, Avatar, CircularProgress, AvatarGroup, Tooltip } from '@mui/material';
import format from 'date-fns/format';
import { useGetMigrationTableDataByIdQuery } from 'store/migration/api';
import { NodeToolbar, Position } from 'reactflow';
import { isBefore } from 'date-fns';

interface IProps {
  migrationId: string;
  event: any;
  isOpen?: boolean;
  position: {
    top: number;
    left: number;
  };
}
const EventTooltip = ({ event, migrationId, isOpen = false, position }: IProps) => {
  const [tooltipVisible, setTooltipVisible] = React.useState<boolean>(false);

  const { data: contacts = [], isFetching: contactsLoading } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'Contact',
      skip: 0,
      take: 0,
      conditions: [
        { field: 'Id', operator: 'IN', value: `('${event?.WhoId}','${event?.WhatId}','${event?.OwnerId}')` },
      ],
    },
    {
      skip: !event || (!event?.WhoId && !event?.WhatId && !event.OwnerId),
    },
  );

  const isVisible = React.useMemo(() => tooltipVisible || isOpen, [tooltipVisible, isOpen]);

  const meetingPast = React.useMemo(() => {
    if (event?.ActivityDate || event?.ActivityDateTime) {
      return isBefore(new Date(event?.ActivityDate ?? event?.ActivityDateTime), new Date());
    }
    return false;
  }, [event]);

  if (isVisible)
    return (
      <Stack
        sx={{
          zIndex: 999,
          p: 1,
          backgroundColor: 'blue.dark',
          borderRadius: '5px',
          color: 'common.white',
          width: '230px',
          position: 'absolute',
          top: position.top,
          left: 0,
          transform: 'translate(-80%,-10%)',
        }}
        gap={0.5}
        onMouseOver={() => {
          setTooltipVisible(true);
        }}
        onMouseOut={() => {
          setTimeout(() => {
            setTooltipVisible(false);
          }, 400);
        }}
      >
        <Typography variant="b12" color="common.white" mb={1}>
          {event?.Subject}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Date</Typography>

          <Typography variant="labelMedium12">
            {event?.ActivityDateTime ? format(new Date(event?.ActivityDateTime), 'MMM dd, h:mm aa') : null}
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Location</Typography>
          <Typography variant="labelMedium12">{event?.Location}</Typography>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Attendee</Typography>
          {contactsLoading ? (
            <CircularProgress sx={{ color: 'white' }} size={14} />
          ) : (
            <Typography variant="labelMedium12">
              <AvatarGroup>
                {(contacts || [])
                  .filter((single) => single?.Id !== event?.OwnerId)
                  .map((singleContact) => (
                    <Tooltip
                      key={`avatar_${singleContact?.Id}`}
                      title={singleContact?.Name ?? `${singleContact?.FirstName} ${singleContact?.LastName}`}
                    >
                      <Avatar sx={{ width: '18px', height: '18px', fontSize: '12px' }}>
                        {singleContact?.Name?.[0] ?? singleContact?.FirstName?.[0]}
                      </Avatar>
                    </Tooltip>
                  ))}
              </AvatarGroup>
            </Typography>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Meeting status</Typography>

          <Typography variant="labelMedium12" color={meetingPast ? 'green.main' : 'blue.main'}>
            {meetingPast ? 'Past' : 'Upcoming'}
          </Typography>
        </Stack>
      </Stack>
    );
  return <></>;
};

export default EventTooltip;
