import { Box, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Handle, Position, NodeProps, useReactFlow } from 'reactflow';

import { ReactComponent as CameraIcon } from 'assets/icons/graph/events/camera.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/graph/events/email.svg';
import { ReactComponent as GenericIcon } from 'assets/icons/graph/events/generic.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/graph/events/phone.svg';
import { ReactComponent as TaskIcon } from 'assets/icons/graph/events/task.svg';
import { ReactComponent as NoteIcon } from 'assets/icons/graph/events/note.svg';

import { ReactComponent as CollapseIcon } from 'assets/icons/graph/graphCollapsedIcon.svg';
import { ReactComponent as UnCollapseIcon } from 'assets/icons/graph/graphUncollapsedIcon.svg';
import theme from 'core/theme';
import { ComponentDesign } from '../../types';
import { useGraphRender } from 'store/graphRender/hooks';
import { useGraphRenderLayoutUtils } from '../../hooks/utils/useGraphRenderLayoutUtils';
import _ from 'lodash';
import { format } from 'date-fns';
import BridgeTooltip from '../ToolTips/BridgeTooltip';

const iconStyle = { width: '70%', height: '70%', stroke: 'white', fill: theme.palette.primary.darkBlueHigh };

export const EventBridgeNode: FC<NodeProps> = (props: NodeProps) => {
  const { onHide } = useGraphRenderLayoutUtils();
  const reactFlow = useReactFlow();
  const { data, id, selected, dragging } = props;
  const { bridgeLabel, _collapsed, label, metaData } = data;
  const { eventType, date, bridgeType } = metaData;
  const collapsed = _collapsed !== false;
  const HandlerStyle = { display: 'none' };

  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const hideFilterFunction = useCallback((node: any, recursionLevel: number, edge: any) => {
    return node?.type !== 'RecordNode';
  }, []);

  const {
    data: { customDesignMap, defaultDesign, edgesHashMap, nodeHashMap },
    setSelectedNode,
    setSelectedNodeFromSearchBar,
  } = useGraphRender();

  let design = defaultDesign ? defaultDesign : ComponentDesign.STANDARD;

  if (customDesignMap[id]) {
    design = customDesignMap[id];
  }

  useEffect(() => {
    if (selected) {
      const node = reactFlow.getNode(id);
      const nodeTmp = _.cloneDeep(node);
      setSelectedNode(nodeTmp);
      setSelectedNodeFromSearchBar(nodeTmp);
    }
  }, [dragging, id, reactFlow, selected, setSelectedNode, setSelectedNodeFromSearchBar]);

  const onClickHandler = useCallback(() => {
    onHide(id, !collapsed, { desiredEdgedLength: 25, filterFunction: hideFilterFunction, recursionLevel: 0 });
  }, [collapsed, hideFilterFunction, id, onHide]);

  const borderColor = useMemo(() => {
    if (design === ComponentDesign.SEARCHING || design === ComponentDesign.DUPLICATE_INACTIVE) {
      return theme.palette.neutral.subtone3;
    }
    return theme.palette.primary.darkBlueHigh;
  }, [design]);

  const shouldRenderIcon = useMemo(() => {
    if (design === ComponentDesign.DUPLICATE_INACTIVE) {
      return false;
    }
    return true;
  }, [design]);

  const getIcon = useCallback((eventType: string) => {
    if (eventType === 'Call') {
      return <PhoneIcon style={iconStyle} />;
    } else if (eventType === 'Email') {
      return <EmailIcon width={'70%'} height={'70%'} />;
    } else if (eventType === 'Meeting') {
      return <CameraIcon style={{ ...iconStyle, stroke: theme.palette.primary.darkBlueHigh }} />;
    } else if (eventType === 'Task') {
      return <TaskIcon style={{ ...iconStyle, stroke: theme.palette.primary.darkBlueHigh }} />;
    } else if (eventType === 'Note') {
      return <NoteIcon style={{ ...iconStyle, stroke: theme.palette.primary.darkBlueHigh }} />;
    } else {
      return <GenericIcon style={iconStyle} />;
    }
  }, []);

  const icon = useMemo(() => getIcon(eventType), [eventType, getIcon]);

  const activitiesRelated = useMemo(
    () =>
      Object.values(edgesHashMap)
        .filter((bridgeValue) => bridgeValue?.source?.includes(label) && !bridgeValue.target.includes('Bridge'))
        .map((singleEdge) => nodeHashMap[singleEdge.target]?.data),
    [edgesHashMap, label, nodeHashMap],
  );

  const count = useMemo(() => activitiesRelated.length, [activitiesRelated]);

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: borderColor,
        backgroundColor: theme.palette.neutral.white,
        borderRadius: '100%',
        width: '100%',
        height: '100%',
      }}
      onClick={onClickHandler}
      onMouseOver={() => setShowTooltip(true)}
      onMouseOut={(e) => {
        //@ts-expect-error
        if (e.relatedTarget === null || !e.currentTarget.contains(e.relatedTarget)) {
          setTimeout(() => setShowTooltip(false), 300);
        }
      }}
    >
      {['year', 'month'].includes(bridgeType) && (
        <BridgeTooltip type={bridgeType} isOpen={showTooltip} activities={activitiesRelated} />
      )}
      {shouldRenderIcon && (
        <Box width={'100%'} height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          {/*  {date ? (
            <Typography sx={{ fontSize: '6px', color: 'primary.darkBlueHigh' }}>
               {bridgeLabel.includes('W') ? bridgeLabel : format(new Date(date), 'MMM')}
            </Typography>
          ) : (
            icon
          )} */}
          <Typography sx={{ fontSize: '6px', color: 'primary.darkBlueHigh' }}>
            {!date ? bridgeLabel : format(new Date(date), 'MMM')}
          </Typography>
        </Box>
      )}

      <Box
        position={'absolute'}
        bottom={collapsed ? '-50%' : '-60%'}
        left={collapsed ? '-8%' : '-10%'}
        sx={{ color: theme.palette.primary.gray }}
      >
        {collapsed === true ? (
          <CollapseIcon width={'4px'} height={'4px'} />
        ) : (
          <UnCollapseIcon width={'4px'} height={'4px'} />
        )}
      </Box>

      <Box
        position={'absolute'}
        top={'-15%'}
        right={'-15%'}
        width={'5px'}
        height={'5px'}
        borderRadius={'100%'}
        padding={'3px'}
        border={`1px solid ${theme.palette.primary.darkBlueHigh}`}
        sx={{
          color: theme.palette.primary.darkBlueHigh,
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.palette.neutral.white,
        }}
      >
        <Typography variant="labelRegular12" sx={{ fontWeight: 'bold', fontSize: '5px' }}>
          {count}
        </Typography>
      </Box>
      <Handle position={Position.Right} type="source" style={HandlerStyle} />
      <Handle position={Position.Left} type="target" style={HandlerStyle} />
    </Box>
  );
};
