import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { ReactComponent as CloseRowIcon } from 'assets/icons/close-row.svg';
import { FC, useCallback, useState, useMemo, useEffect } from 'react';

import { useGraphRender } from 'store/graphRender/hooks';
import { getConfidenceScoreColor } from 'core/utils';
import { HiddenNodePanelView } from '../components/HiddenNodePanelView';
import { LoaderContainer } from 'components/Loader/ui';
import Header from './components/Header';
import Footer from './components/Footer';
import ActionsTab from './components/Tabs/Actions';
import DetailsTab from './components/Tabs/Details';
import { useGetMigrationTableDataByIdQuery } from 'store/migration/api';
import ThreadContainer from './components/Tabs/Thread';

interface ActivityPanelProps {
  node: any;
  toggleOpen: (value: boolean) => void;
  open: boolean;
  migrationId: string;
  nodeData: any;
  referencedUsers: any;
}

const selectedStyle = {
  color: 'primary.main',
  borderBottom: '3px solid',
  borderColor: 'primary.main',
  // paddingBottom: '4px',
};

enum Tabs {
  ACTIONS = 'Actions & Insights',
  Details = 'Details',
}

const ActivityPanel: FC<ActivityPanelProps> = (props: ActivityPanelProps) => {
  const { node, toggleOpen, open, migrationId, nodeData, referencedUsers } = props;
  const { confidence_score, id, label, TaskSubtype, threadData } = node.data;

  const confidenceScoreColor = getConfidenceScoreColor(confidence_score);

  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const {
    data: {
      AccountPanelView: { menu: menuOptionSelected },
    },
    setSelectedAccountPanelView,
  } = useGraphRender();

  const activityType = useMemo(() => (TaskSubtype || label || '').toLowerCase(), [label, TaskSubtype]);

  const tabs = useMemo(() => {
    if (activityType === 'note') return ['Details'];
    if (activityType === 'thread' || label === 'Thread') return ['Actions & Insights', 'Thread'];
    return Object.values(Tabs);
  }, [activityType]);

  const getMenuViewBarSelector = useCallback(() => {
    return tabs.map((key) => {
      return (
        <Grid
          item
          key={`menu-option-${key}`}
          sx={{
            color: 'neutral.n400',
            ':hover': { ...selectedStyle, cursor: 'pointer' },
            ...(menuOptionSelected === key && { ...selectedStyle }),
          }}
        >
          <Box
            onClick={() => setSelectedAccountPanelView(key as any)}
            sx={{
              paddingBottom: '8px',
            }}
          >
            <Typography variant="p14">
              {key} {key === 'Thread' ? `(${(threadData ?? node.data).ThreadCount + 1})` : null}
            </Typography>
          </Box>
        </Grid>
      );
    });
  }, [menuOptionSelected, setSelectedAccountPanelView, tabs, node, threadData]);

  const getMenuView = useCallback(() => {
    if (!nodeData) {
      return (
        <LoaderContainer>
          <CircularProgress sx={{ textAlign: 'center' }} />
        </LoaderContainer>
      );
    }
    //@ts-expect-error
    if (menuOptionSelected === Tabs.ACTIONS) {
      return <ActionsTab type={activityType} />;
      //@ts-expect-error
    } else if (menuOptionSelected === Tabs.Details) {
      return <DetailsTab type={activityType} nodeData={nodeData} migrationId={migrationId} />;
      //@ts-expect-error
    } else if (menuOptionSelected === 'Thread') {
      return (
        <ThreadContainer
          isFullscreen={isFullScreen}
          toggleFullScreen={() => setIsFullScreen((prev) => !prev)}
          thread={threadData ?? node.data}
          migrationId={migrationId}
        />
      );
    } else {
      return <></>;
    }
  }, [
    menuOptionSelected,
    migrationId,
    nodeData,
    referencedUsers,
    setSelectedAccountPanelView,
    activityType,
    isFullScreen,
    node,
    threadData
  ]);

  useEffect(() => {
    setSelectedAccountPanelView(activityType === 'thread' ? 'Thread' : (Tabs.Details as any));
  }, []);

  const { data: accounts = [] } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'Account',
      skip: 0,
      take: 0,
      conditions: [
        {
          field: 'Id',
          operator: '=',
          value: `'${nodeData.AccountId}'`,
        },
      ],
    },
    {
      skip: !nodeData.AccountId,
    },
  );

  const path = useMemo(() => {
    const accountName = accounts[0]?.Name;
    let bridge;
    if (['call', 'email', 'task', 'thread'].includes(activityType)) bridge = 'tasks';
    if (activityType === 'event') bridge = 'events';
    return [accountName, bridge];
  }, [accounts, nodeData]);

  return (
    <>
      {open ? (
        <Box
          position={'absolute'}
          sx={{
            width: '30vw',
            minWidth: '425px',
            maxWidth: '800px',
            height: '95%',
            right: 1,
            top: 1,
            ...(isFullScreen && activityType === 'thread'
              ? {
                  width: '100vw',
                  maxWidth: isFullScreen ? '100vw' : '800px',
                  height: isFullScreen ? '89vh' : '95%',
                  right: undefined,
                  top: undefined,
                  left: 0,
                  bottom: 0,
                  zIndex: 5,
                }
              : {}),
            marginRight: 3,
            marginTop: 2,
            background: 'white',
            paddingX: 2,
            paddingTop: 2,
            paddingBottom: 1,
            borderRight: '2px solid',
            borderLeft: '4px solid',
            borderRightColor: 'neutral.white',
            borderLeftColor: confidenceScoreColor,
            borderTopRightRadius: '3px',
            borderBottomRightRadius: '3px',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
          }}
          zIndex={2}
        >
          <Box position={'absolute'} onClick={() => toggleOpen(false)} sx={{ left: -38, cursor: 'pointer' }}>
            <CloseRowIcon />
          </Box>
          <Grid container display={'flex'} flexDirection={'column'} height={'100%'}>
            <Grid item flex={0}>
              <Header type={activityType} title={nodeData?.Subject} toggleOpen={toggleOpen} path={path} />
            </Grid>

            <Grid item sx={{ marginTop: 2 }} flex={0}>
              <Grid container display={'flex'} gap={2} sx={{ borderBottom: '1px solid', borderColor: 'neutral.n300' }}>
                {getMenuViewBarSelector()}
              </Grid>
            </Grid>

            <Grid item flex={1} width={'100%'} height={'1px'} sx={{ overflowY: 'scroll' }}>
              <Grid container display={'flex'} flexDirection={'column'}>
                {getMenuView()}
              </Grid>
            </Grid>
            <Grid
              container
              display={'flex'}
              maxHeight={'9%'}
              alignItems={'center'}
              sx={{ padding: '0.5rem', borderTop: '1px solid #00000015' }}
            >
              <Footer type={activityType} />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <HiddenNodePanelView
          confidenceScoreColor={confidenceScoreColor}
          toggleOpen={toggleOpen}
          node={nodeData}
          confidence_score={confidence_score}
        />
      )}
    </>
  );
};

export default ActivityPanel;
