import { styled, Box, Paper, Button, Switch, TextField } from '@mui/material';

export const Container = styled(Box)(() => ({
  padding: 20,
}));

export const DragContainer = styled(Paper)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#F6F8FB',
  height: '100%',
  boxShadow: 'none',
  padding: 20,
  '& .card-header': {
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    marginTop: '10px',
    padding: '10px 0px',
  },
}));

export const DragSideBarFields = styled(Paper)(() => ({
  background: '#F6F8FB',
  boxShadow: 'none',
  height: '20rem',
  overflow: 'auto',
  'overflow-x': 'hidden',
  '& .card-header': { display: 'flex', alignItems: 'center', marginBottom: 10, background: 'white', marginTop: '20px' },
}));

export const DropContainer = styled(Box)(() => ({
  height: '25rem',
  overflow: 'auto',
}));

export const Drag = styled(Paper)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#F6F8FB',
  height: '100%',
  boxShadow: 'none',
  padding: 20,
  '& .card-header': { display: 'flex', alignItems: 'center', marginBottom: 10, background: 'white', marginTop: '20px' },
}));

export const FieldItem = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px',
  marginBottom: '8px',
  backgroundColor: '#ffffff',
  border: '1px solid #e0e0e0',
  borderRadius: '4px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
}));

export const AddAllButton = styled(Button)(({ theme }) => ({
  padding: '8px 16px',
  backgroundColor: 'transparent',
  ':hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.black,
  },
  '& .MuiButton-startIcon': {
    marginRight: 8,
    '& svg': {
      fill: theme.palette.primary.main,
      transition: 'fill 0.3s ease', // Smooth transition for the hover effect
      ':hover': {
        fill: theme.palette.primary.black, // Change to black on hover
      },
    },
    '& svg path': {
      fill: theme.palette.primary.main,
      transition: 'fill 0.3s ease', // Smooth transition for the hover effect
      ':hover': {
        fill: theme.palette.primary.black, // Change to black on hover
      },
    },
  },
}));

export const DragDropArea = styled(Box)(() => ({
  textAlign: 'center',
  color: '#9e9e9e',
  fontSize: '16px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 10,
}));

export const DropIcon = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 10,
  borderWidth: 1,
  borderRadius: 100,
  background: 'white',
  width: '7rem',
  height: '7rem',
  '& .plus-icon': {
    width: '3rem',
    height: '3rem',
  },
}));

export const VerticalDivider = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  width: 1,
  height: 16,
  position: 'relative',
  '&::before': {
    content: '" "',
    height: 16,
    position: 'absolute',
    top: '-50%',
    width: 1,
    backgroundColor: theme.palette.neutral.n200,
    marginBottom: -4,
  },
}));

export const WeightScore = styled(Box)(({ theme }) => ({
  padding: '10px 15rem 10px 30px',
  margin: '10px 0px',
  border: '1px solid #60C67C',
  background: '#DFF4E5',
  borderRadius: '5px',
  display: 'flex',
  color: theme.palette.neutral.main,
  justifyContent: 'space-between',
}));

export const DragFields = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 8px 8px 0',
  cursor: 'move',
  transition: 'border-color 0.3s, background-color 0.3s',
  borderRadius: 4,
  justifyContent: 'space-between',

  // Hover styles
  '&:hover': {
    backgroundColor: theme.palette.primary.subtone1,
    borderRadius: '5px',
    '.plus-icon': {
      borderRadius: 20,
      width: 26,
      height: 24,
    },
  },

  // Focus styles
  '&:focus': {
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.action.selected,
    outline: 'none', // Removes default focus outline
  },

  // Hide the PlusIcon initially
  '.plus-icon': {
    visibility: 'hidden',
    opacity: 0,
    transition: 'opacity 0.3s',
  },

  // Show the PlusIcon on hover
  '&:hover .plus-icon': {
    background: 'white',
    visibility: 'visible',
    opacity: 1,
  },
}));

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-thumb': {
    color: theme.palette.primary.subtone1,
    width: 24,
    height: 24,
  },
  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.grey[400],
    borderRadius: 20 / 2,
  },
  '&.Mui-checked .MuiSwitch-thumb': {
    color: theme.palette.secondary.main,
  },
  '&.Mui-checked .MuiSwitch-track': {
    backgroundColor: theme.palette.secondary.light,
  },
  '&.MuiSwitch-track': {
    height: '50px',
  },
  width: 52,
  height: 36,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',

    '&.MuiSwitch-input': {},
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        ...theme.applyStyles('dark', {
          backgroundColor: '#8796A5',
        }),
      },
    },
  },
}));

export const DragContainerHeader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
}));

export const SearchFields = styled(TextField)(() => ({
  margin: '15px 0 5px 0px',
  '& .MuiOutlinedInput-root': {
    '&.Mui-disabled': {
      cursor: 'not-allowed !important', // Set the cursor to not-allowed
      backgroundColor: '#ececec !important', // Change the background color when disabled
      '& fieldset': {
        borderColor: '#dddddd !important', // Change the border color when disabled
      },
    },
  },
  '& .MuiInputBase-input': {
    '&.Mui-disabled': {
      color: '#a1a1a1 !important', // Change the text color when disabled
      backgroundColor: '#ececec !important', // Change the background color when disabled
      cursor: 'not-allowed !important', // Set the cursor to not-allowed
    },
  },
}));
