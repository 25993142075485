import React from 'react';
import { Grid, Box, CircularProgress, Typography, Divider } from '@mui/material';
import { useGetEmailsDataByTasksIdQuery, useGetMigrationTableDataByIdQuery } from 'store/migration/api';
import isSameMonth from 'date-fns/isSameMonth';
import PeriodSection from './components/PeriodSection';
import { format } from 'date-fns';
import { useGraphRender } from 'store/graphRender/hooks';

const Activity = ({ migrationId, node }: { migrationId: string; node: any }) => {
  const {
    data: {
      nodePanelState: {
        activityTab: { excludedStatuses = [], excludedTypes = [] },
      },
    },
  } = useGraphRender();
  const { data: eventsData = [], isFetching: eventsLoading } = useGetMigrationTableDataByIdQuery({
    migrationId: migrationId,
    tableId: 'Event',
    skip: 0,
    take: 0,
    conditions: [
      {
        field: node.label === 'Contact' ? 'WhoId' : node.label === 'Account' ? 'AccountId' : 'WhatId',
        operator: '=',
        value: `'${node.id}'`,
      },
    ],
  });

  const { data: tasksData = [], isFetching: tasksLoading } = useGetMigrationTableDataByIdQuery({
    migrationId: migrationId,
    tableId: 'Task',
    skip: 0,
    take: 100,
    conditions: [
      {
        field: node.label === 'Contact' ? 'WhoId' : node.label === 'Account' ? 'AccountId' : 'WhatId',
        operator: '=',
        value: `'${node.id}'`,
      },
    ],
  });
  const { data: emailDetails = [], isFetching: emailsDetailsLoading } = useGetEmailsDataByTasksIdQuery(
    {
      migrationId: migrationId,
      tasksIds: tasksData.filter((task) => task.TaskSubtype === 'Email').map((task) => task.Id),
    },
    {
      skip: tasksData.length === 0 || tasksData.filter((task) => task.TaskSubtype === 'Email').length === 0,
    },
  );

  const { data: contactsData = [], isFetching: contactsLoading } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'Contact',
      skip: 0,
      take: 0,
      conditions: [
        {
          field: 'Id',
          operator: 'in',
          value: `(${[...tasksData, ...eventsData].map((activity) => `'${activity.WhoId}'`).join(' , ')})`,
        },
      ],
    },
    {
      skip: (!tasksData.length && !eventsData.length) || node.label === 'Contact',
    },
  );

  const { data: opportunitiesData = [], isFetching: opportunitiesLoading } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'Opportunity',
      skip: 0,
      take: 0,
      conditions: [
        {
          field: 'Id',
          operator: 'in',
          value: `(${[...tasksData, ...eventsData]
            .filter((activity) => activity.WhatId)
            .map((activity) => `'${activity.WhatId}'`)
            .join(' , ')})`,
        },
      ],
    },
    {
      skip: (!tasksData.length && !eventsData.length) || node.label === 'Contact',
    },
  );

  const activitiesByPeriod = React.useMemo(() => {
    const emailDetailsWithThread = emailDetails.map((singleEmail) => {
      const threadCount = emailDetails.reduce((acc, curr) => {
        if (
          singleEmail.ThreadIdentifier === curr.ThreadIdentifier &&
          !!curr.ThreadIdentifier &&
          singleEmail.Id !== curr.Id
        )
          return acc + 1;
        return acc;
      }, 0);
      return !threadCount
        ? singleEmail
        : {
            ...singleEmail,
            HasThread: true,
            ThreadCount: threadCount,
          };
    });
    const noThreadsEmails = emailDetailsWithThread.filter((singleEmail) => !singleEmail.HasThread);
    const threads = [
      //@ts-expect-error
      ...new Set(
        emailDetailsWithThread
          .filter((singleEmail) => !!singleEmail.HasThread)
          .map((singleEmail) => singleEmail.ThreadIdentifier),
      ),
    ];

    const finalThreads = threads.map((threadId) => {
      const emails = emailDetailsWithThread.filter((e) => e.ThreadIdentifier === threadId);
      const mainEmail = emails.sort(
        (a, b) => new Date(a?.CreatedDate || 0).getTime() - new Date(b?.CreatedDate || 0).getTime(),
      )[0];
      return {
        ...mainEmail,
        ThreadElements: emails.filter((singleEmail) => singleEmail.Id !== mainEmail.Id),
        TaskSubtype: 'Thread',
      };
    });

    const allEmails = [...finalThreads, ...noThreadsEmails.map((e) => ({ ...e, TaskSubtype: 'Email' }))];
    const allActivities = [
      ...eventsData,
      ...tasksData.filter((task) => !emailDetails.some((email) => email.ActivityId === task.Id)),
      ...allEmails,
    ];

    const periodsObject = allActivities
      .filter(
        (activity) =>
          !excludedStatuses.includes(activity.Status?.toLowerCase()) &&
          !excludedTypes.includes(activity.EventSubtype?.toLowerCase() || activity?.TaskSubtype?.toLowerCase()),
      )
      .reduce((acc, currentActivity) => {
        const activityDate = new Date(currentActivity.CreatedDate);
        if (isSameMonth(activityDate, new Date()))
          return {
            ...acc,
            currentMonth: acc.currentMonth ? [...acc.currentMonth, currentActivity] : [currentActivity],
          };
        const activityMonth = format(activityDate, '01-MM-yyyy');
        return {
          ...acc,
          [activityMonth]: acc[activityMonth] ? [...acc[activityMonth], currentActivity] : [currentActivity],
        };
      }, {});

    return periodsObject;
  }, [eventsData, tasksData, excludedStatuses, excludedTypes, emailDetails]);

  React.useEffect(() => {
    console.log(activitiesByPeriod);
  }, [activitiesByPeriod]);

  const isLoading = eventsLoading || tasksLoading || contactsLoading || opportunitiesLoading || emailsDetailsLoading;

  return (
    <Grid display={'flex'} direction={'column'} gap={1} p={1} bgcolor={'#F6F8FB'}>
      <Box sx={{ height: '100%' }}>
        {isLoading ? (
          <Box display={'flex'} alignItems={'center'} height={'50px'} gap={1}>
            <CircularProgress size={'16px'} color="primary" />
            <Typography>Loading activities...</Typography>
          </Box>
        ) : (
          <>
            {activitiesByPeriod['currentMonth'] && (
              <PeriodSection
                key={`periodCOntainer_currentMonth`}
                isCurrentMonth
                migrationId={migrationId}
                activities={activitiesByPeriod['currentMonth']}
                node={node}
                contacts={[...contactsData, ...opportunitiesData]}
              />
            )}
            <Divider sx={{ width: '80%', mx: 'auto', my: 1 }} />
            {Object.keys(activitiesByPeriod)
              .filter((period) => period !== 'currentMonth')
              .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
              .map((period) => (
                <PeriodSection
                  key={`periodContainer_${period}`}
                  activities={activitiesByPeriod[period]}
                  migrationId={migrationId}
                  node={node}
                  contacts={[...contactsData, ...opportunitiesData]}
                />
              ))}
          </>
        )}
      </Box>
    </Grid>
  );
};

export default Activity;
