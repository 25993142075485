import { Box, Grid, Typography } from '@mui/material';
import { ReactComponent as OpenRowIcon } from 'assets/icons/open-row.svg';
import ConfidenceScore from 'components/ConfidenceScore/ConfidenceScore';
import { FC, useMemo } from 'react';

interface HiddenNodePanelViewProps {
  confidenceScoreColor?: string;
  toggleOpen: (value: boolean) => void;
  node: any;
  confidence_score: number;
}

export const HiddenNodePanelView: FC<HiddenNodePanelViewProps> = (props) => {
  const { confidenceScoreColor, toggleOpen, node: nodeData, confidence_score } = props;
  // const nodeData = node.data;
  const nodeName = useMemo(() => {
    if (nodeData && nodeData.data?.Name) {
      return nodeData.data?.Name;
    }

    if (nodeData && nodeData.Subject) {
      return nodeData.Subject;
    }

    return nodeData.data?.FirstName + ' ' + nodeData.data?.LastName;
  }, [nodeData]);

  return (
    <Box
      position={'absolute'}
      sx={{
        width: '15vw',
        minWidth: '100px',
        height: 'fit-content',
        right: 1,
        top: 1,
        marginRight: 3,
        marginTop: 2,
        background: 'white',
        padding: 1,
        borderRight: '2px solid',
        borderLeft: '4px solid',
        borderRightColor: 'neutral.white',
        borderLeftColor: confidenceScoreColor,
        borderTopRightRadius: '3px',
        borderBottomRightRadius: '3px',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        textAlign: 'center',
      }}
      zIndex={0}
    >
      <Box position={'absolute'} onClick={() => toggleOpen(true)} sx={{ left: -38, cursor: 'pointer' }}>
        <OpenRowIcon />
      </Box>
      <Grid container display={'flex'} flexDirection={'column'}>
        <Grid item>
          <Grid container display={'flex'} width={'100%'} justifyContent={'space-between'}>
            <Grid item xs={12}>
              <Grid container display={'flex'} flexDirection={'column'}>
                <Grid item>
                  <Typography variant="p16" sx={{ fontWeight: 'bold' }}>
                    {nodeName}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="labelRegular10" sx={{ color: 'neutral.n400' }}>
                    CONFIDENCE SCORE
                  </Typography>
                </Grid>
                <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
                  <ConfidenceScore confidenceScore={confidence_score} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
