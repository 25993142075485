export const loadFromLocalStorage = (key: string) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : {};
};

export const saveToLocalStorage = (key: string, value: any) => {
  const existingData = loadFromLocalStorage(key);
  const updatedData = { ...existingData, ...value }; // Merge existing and new data
  localStorage.setItem(key, JSON.stringify(updatedData));
};
