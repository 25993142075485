import { FC, useState, ChangeEvent, useEffect, useCallback } from 'react';
import { Typography, Box, Slider, TextField, Grid, List, ListItemText, Button } from '@mui/material';
import format from 'date-fns/format';

import {
  ConfigurationHeader,
  ConfigurationText,
  Container,
  ListItem,
  ListItemIcon,
  SidebarConfigList,
  SidebarContainer,
  SidebarList,
  TitleContainer,
  VerticalDivider,
} from './ui';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ReactComponent as MenuConfigIcon } from 'assets/icons/config.svg';
import { ReactComponent as MenuThresholdIcon } from 'assets/icons/threshold.svg';
import { ReactComponent as MenuNoConfigIcon } from 'assets/icons/noConfigIcon.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ConfigAction, SidebarDuplicateConfig } from 'core/types';
import { SecondaryButton } from 'components/ui';
import { Configuration } from './components';
import { ConfigurationList } from './components/ConfigurationList';
import {
  createduplicationConfigApi,
  deleteConfigurationObject,
  getConfigurationList,
  getDuplicationConfigurationById,
  getDuplicateConfigurationFields,
  getDuplicateConfigurationObjects,
  updateConfigurationObject,
  getConfigurationThreshold,
  updateConfigurationThreshold,
  createConfigurationThreshold,
} from 'http/duplicateConfig';
import { useTenant } from 'store/tenant/hooks';
import {
  ConfigurationList as list,
  Field,
  ConfigurationResponse,
  CardData,
  TenantPayload,
  ThresholdResponse,
  ThresholdPayload,
} from 'store/duplicateConfig/types';
import { CustomDropdown } from 'components/CustomDropdown';
import { Loader } from 'components/Loader';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';

const DuplicateConfig: FC = () => {
  const [value, setValue] = useState<number>(100); // State for the slider value
  const [tab, setTab] = useState<string>(SidebarDuplicateConfig.THRESHOLD); // State for the selected tab
  const [isAddConfiguration, setIsAddConfiguration] = useState(false);
  const [isConfgurationList, setIsConfgurationList] = useState(false);
  const [fieldLoading, setFieldLoading] = useState(false);
  const [configurationListData, setConfigurationListData] = useState<CardData[]>([]);
  const [configurationList, setConfigurationList] = useState<list[]>([]);
  const [configurationThreshold, setConfigurationThreshold] = useState<ThresholdResponse>({
    id: 0,
    threshold: 0,
  });
  const [configLoader, setConfigLoader] = useState(false);
  const [configurationListLoading, setConfigurationListLoading] = useState(true);
  const [configurationObjectData, setConfigurationObjectData] = useState<ConfigurationResponse>();
  const [selectSalesForce, setSelectSalesForce] = useState('');
  const [availableFields, setAvailableFields] = useState<Field[]>([]);
  const [fetchedFields, setFetchedFields] = useState<Field[]>([]);
  const [createdFields, setCreatedFields] = useState<Field[]>([]);
  const navigate = useNavigate();
  const { tenant, getTenants } = useTenant();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const update_id = searchParams.get('update_id');
  const totalWeight = 100;

  useEffect(() => {
    getTenants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handler for slider value change
  const handleSliderChange = (_event: Event, newValue: number | number[]): void => {
    setValue(newValue as number);
  };

  // Handler for text input value change
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = Number(event.target.value);
    if (newValue >= 0 && newValue <= 100) {
      setValue(newValue);
    }
  };

  const handleConfigurationThreshold = async () => {
    setFieldLoading(true);

    try {
      const payload: ThresholdPayload = {
        tenantId: Number(tenant?.tenantId),
        dataSourceId: 'salesforce',
        threshold: value,
      };

      // Handle update vs create logic
      if (configurationThreshold?.id) {
        await updateConfigurationThreshold(String(configurationThreshold.id), payload);
      } else {
        await createConfigurationThreshold(payload);
      }
      fetchDuplicateConfigThreshold();
    } catch (error) {
      console.error('Error saving configuration:', error);
    } finally {
      // Ensure loading states are reset
      setFieldLoading(false);
    }
  };

  const handleResetThreshold = () => {
    navigate('/d');
  };

  const handleSaveConfiguration = async () => {
    setFieldLoading(true);
    setConfigurationListLoading(true);

    try {
      const configurationFields = createdFields?.map((item) => ({
        id: item?.Id,
        fieldName: item?.name,
        isLocked: item?.locked ?? false,
        weightValue: item?.weight,
        checkSegmentation: item?.checkSegmentation ?? false,
      }));

      const payload: TenantPayload = {
        tenantId: Number(tenant?.tenantId),
        dataSourceId: 'salesforce',
        tableId: selectSalesForce,
        configurationFields,
        threshold: value,
      };

      // Handle update vs create logic
      if (update_id) {
        await updateConfigurationObject(update_id, payload);
      } else {
        await createduplicationConfigApi(payload);
      }

      // Common success actions
      setIsConfgurationList(true);
      fetchConfigurationList();
      navigate('/d/duplicate-config');
    } catch (error) {
      console.error('Error saving configuration:', error);
    } finally {
      // Ensure loading states are reset
      setFieldLoading(false);
      setConfigurationListLoading(false);
    }
  };

  const fetchDuplicateConfigObjectById = async (id: string) => {
    try {
      await getDuplicationConfigurationById(String(id))
        .then((response) => {
          setConfigurationObjectData(response);
          setSelectSalesForce(response.tableId);
          setFieldLoading(true);
          fetchDuplicateConfigFields(response.tableId);
        })
        .finally(() => {
          setConfigLoader(false);
          setConfigurationListLoading(false);
        });
    } catch (error) {
      console.error('Error fetching duplicate configuration objects', error);
    }
  };

  const deleteConfiguration = async (cardData: CardData) => {
    setConfigurationListLoading(true);
    try {
      await deleteConfigurationObject(String(cardData?.id))
        .then(() => {
          Swal.fire({
            title: 'Delete Object Configuration',
            text: 'The Object Configuration has been successfully deleted.',
            icon: 'success',
            confirmButtonText: 'Got it!',
          });
          setConfigurationListLoading(true);
          fetchConfigurationList();
        })
        .finally(() => {
          setConfigLoader(false);
        });
    } catch (error) {
      console.error('Error fetching duplicate configuration objects', error);
    }
  };

  const handleConfigActions = async (type: string, cardData: CardData) => {
    if (type === ConfigAction.DELETE) {
      const result = await Swal.fire({
        title: 'Delete Object Configuration',
        text: `Are you sure you want to delete ${cardData?.title}!`,
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
      });
      if (result.isConfirmed) {
        deleteConfiguration(cardData);
      }
    }
    if (type === ConfigAction.EDIT) {
      window.location.href = `/d/duplicate-config?update_id=${cardData?.id}`;
    }
  };

  const addConfigurationHeader = (configurationListData: number) => {
    return (
      <SidebarList>
        <Box>
          <Typography variant="h6">Salesforce Object Configuration</Typography>
          <Typography variant="body2" color="textSecondary">
            Prioritize the importance of fields within the same Salesforce object for duplicate detection.
            <br /> Higher weights mean greater significance in the duplicate scoring process.
          </Typography>
        </Box>
        {configurationListData ? (
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              height: '100%',
              justifyContent: 'end',
              padding: 2,
            }}
          >
            <SecondaryButton
              startIcon={<PlusIcon />}
              onClick={() => {
                setIsConfgurationList(false);
                setIsAddConfiguration(true);
                setSelectSalesForce('');
                setAvailableFields([]);
                setFetchedFields([]);
              }}
            >
              Add New Object Configurations
            </SecondaryButton>
          </Box>
        ) : null}
      </SidebarList>
    );
  };

  const handleChangeConfiguration = (event: string) => {
    setFieldLoading(true);
    fetchDuplicateConfigFields(event);
    setSelectSalesForce(event);
  };

  const fetchDuplicateConfigObjects = async () => {
    const query = `?tenantId=${tenant?.tenantId}&dataSourceId=salesforce`;
    try {
      const data = await getDuplicateConfigurationObjects(query).finally(() => {
        setConfigLoader(false);
      });
      setConfigurationList(data);
    } catch (error) {
      console.error('Error fetching duplicate configuration objects', error);
    }
  };

  const fetchDuplicateConfigThreshold = async () => {
    try {
      const data = await getConfigurationThreshold(`${tenant?.tenantId}`).finally(() => {
        setFieldLoading(false);
      });
      setValue(data.threshold);
      setConfigurationThreshold(data);
    } catch (error) {
      console.error('Error fetching duplicate configuration objects', error);
    }
  };

  const fetchDuplicateConfigFields = useCallback(
    async (tableId: string) => {
      const query = `?tenantId=${tenant?.tenantId}&dataSourceId=salesforce&tableId=${tableId}`;
      try {
        await getDuplicateConfigurationFields(query)
          .then((data) => {
            const fields = data.map((field, index) => ({
              ...field,
              name: field.fieldName,
              weight: 0,
              locked: false,
              label: field.fieldName,
              value: field.fieldName,
              index: index,
            }));
            setFetchedFields(fields ?? []);
            setAvailableFields(fields ?? []);
          })
          .finally(() => setFieldLoading(false));
      } catch (error) {
        console.error('Error fetching duplicate configuration fields', error);
      }
    },
    [tenant?.tenantId],
  );

  const fetchConfigurationList = async () => {
    try {
      await getConfigurationList(String(tenant?.tenantId))
        .then((reponse: ConfigurationResponse[]) => {
          const listData = reponse?.map((item) => {
            return {
              title: item?.tableId,
              createdBy: item?.creator?.userName,
              lastUpdated: item?.updateDate ? format(new Date(item?.updateDate), 'MM/dd/yyyy, hh:mm aa') : '-',
              id: item?.id,
              fields: item?.configurationFields?.map((fieldValue) => {
                return {
                  name: fieldValue?.fieldName,
                  weight: fieldValue?.weightValue,
                  checkSegmentation: fieldValue?.checkSegmentation,
                };
              }),
              additionalFields: [],
            };
          }) as unknown as CardData[];
          setConfigurationListData(listData);
        })
        .finally(() => {
          setConfigurationListLoading(false);
        });
    } catch (error) {
      console.error('Error fetching configuration list', error);
      setConfigurationListData([]);
      setConfigurationListLoading(false);
    }
  };

  const onSelectField = (field: Field[]) => {
    setCreatedFields(field);
  };

  const renderThresholdConfig = () => (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Detection Threshold
        </Typography>

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            height: '100%',
            justifyContent: 'end',
            padding: 2,
          }}
        >
          <Button variant="outlined" onClick={handleResetThreshold}>
            Cancel
          </Button>
          <Button onClick={handleConfigurationThreshold} variant="contained">
            {configurationThreshold?.id ? 'Update' : 'Save'}
          </Button>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', width: '20rem' }}>
        <Slider
          value={value}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
          max={100}
          sx={{ mr: 2, flex: 1 }}
        />
        <ConfigurationText
          value={value ? value : ''}
          aria-readonly
          onChange={handleInputChange}
          inputProps={{
            step: 1,
            min: 0,
            max: 100,
            type: 'number',
          }}
        />
      </Box>

      <Typography variant="body2" color="textSecondary" sx={{ mb: 4 }}>
        Adjust the threshold that determines when a record is likely a duplicate. This is the score that records must
        meet or exceed to be flagged for review.
      </Typography>
    </Box>
  );

  const handleResetData = () => {
    setConfigurationListLoading(true);
    fetchConfigurationList();
    fetchDuplicateConfigObjects();
    setIsAddConfiguration(false);
    setSelectSalesForce('');
    setAvailableFields([]);
    setFetchedFields([]);
    setIsConfgurationList(true);
    window.location.href = '/d/duplicate-config';
  };

  const renderSameObjectFieldConfig = () => (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography variant="h6">Salesforce Object Configuration</Typography>
          <Typography variant="body2" color="textSecondary">
            Prioritize the importance of fields within the same Salesforce object for duplicate detection.
            <br /> Higher weights mean greater significance in the duplicate scoring process.
          </Typography>
        </Box>
        {isAddConfiguration && (
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              height: '100%',
              justifyContent: 'end',
              padding: 2,
            }}
          >
            <Button variant="outlined" onClick={handleResetData}>
              Cancel
            </Button>
            <Button disabled={!createdFields?.length} onClick={handleSaveConfiguration} variant="contained">
              {update_id ? 'Update' : 'Save'}
            </Button>
          </Box>
        )}
      </Box>
      {configurationListLoading ? (
        <Loader />
      ) : isAddConfiguration ? (
        <>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} lg={3}>
              <CustomDropdown
                id="userRole"
                label={
                  <Typography variant="subtitle2" color={'#1E232C'}>
                    Salesforce Object
                  </Typography>
                }
                placeholder="Select Salesforce object"
                value={selectSalesForce}
                options={configurationList?.map((config) => ({
                  label: config.table_name,
                  value: config.table_name,
                }))}
                loading={configLoader}
                onSelect={handleChangeConfiguration}
                sx={{ width: '97%' }}
              />
            </Grid>
            <Grid item xs={12} lg={9}></Grid>
          </Grid>
          {fieldLoading ? (
            <Loader />
          ) : fetchedFields?.length ? (
            <Configuration
              totalWeight={totalWeight}
              onSelectField={onSelectField}
              availableFields={availableFields}
              setAvailableFields={setAvailableFields}
              fetchedFields={fetchedFields}
              editObjectFields={configurationObjectData?.configurationFields}
            />
          ) : null}
        </>
      ) : (
        <ConfigurationHeader>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              mt: 10,
            }}
          >
            <MenuNoConfigIcon />
            <Typography sx={{ color: '#9DA1B5', marginTop: '10px' }}>
              You don’t have any configurations added yet
            </Typography>
            <SecondaryButton sx={{ mt: 2 }} startIcon={<PlusIcon />} onClick={handleResetData}>
              Add New Object Configurations
            </SecondaryButton>
          </Box>
        </ConfigurationHeader>
      )}
    </Box>
  );

  const loadConfigurationForUpdate = useCallback(() => {
    setIsAddConfiguration(true);
    setIsConfgurationList(false);

    if (tenant?.tenantId) {
      fetchDuplicateConfigObjectById(update_id!); // `update_id` is guaranteed to exist here
      fetchDuplicateConfigObjects();
    }
  }, [update_id, tenant?.tenantId]);

  // Function to handle the default scenario
  const loadDefaultConfiguration = useCallback(() => {
    setFieldLoading(true);
    setIsConfgurationList(true);
    setConfigurationListLoading(true);
    setConfigLoader(true);

    if (tenant?.tenantId) {
      fetchConfigurationList();
      fetchDuplicateConfigObjects();
      fetchDuplicateConfigThreshold();
    }
  }, [tenant?.tenantId]);

  useEffect(() => {
    if (update_id) {
      loadConfigurationForUpdate();
    } else {
      loadDefaultConfiguration();
    }
  }, [update_id, tenant?.tenantId, loadConfigurationForUpdate, loadDefaultConfiguration]);

  return (
    <Container>
      {fieldLoading && <Loader />}
      <TitleContainer>
        <Typography variant="h2" sx={{ color: 'neutral.main', width: '15%' }}>
          Duplicate Config
        </Typography>

        <VerticalDivider />

        <Typography variant="subtitle1" style={{ color: '#868b98', marginBottom: '0.5rem' }} component="p">
          Welcome to the Duplicate Configuration page. Here you can define how Customer City identifies and scores
          potential duplicates across different Salesforce objects. Adjust thresholds and assign weights to specific
          fields so that the system prioritizes the attributes most critical to your business.
        </Typography>
      </TitleContainer>

      <Box sx={{ display: 'flex', m: 4 }}>
        {/* Left Navigation Tabs */}
        <Box
          sx={{
            pr: 2,
            minHeight: 'calc(100vh - 200px)',
          }}
        >
          <SidebarContainer>
            <ListItem
              active={tab === SidebarDuplicateConfig.THRESHOLD}
              onClick={() => setTab(SidebarDuplicateConfig.THRESHOLD)}
            >
              <ListItemIcon>
                <MenuThresholdIcon />
              </ListItemIcon>
              <ListItemText primary="Threshold Configuration" primaryTypographyProps={{ fontSize: '14px' }} />
              <KeyboardArrowRightIcon />
            </ListItem>

            <ListItem
              active={tab === SidebarDuplicateConfig.SAME_OBJECT_FIELD}
              onClick={() => setTab(SidebarDuplicateConfig.SAME_OBJECT_FIELD)}
            >
              <ListItemIcon>
                <MenuConfigIcon style={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText
                primary="Same-Object Field Weight Configuration"
                primaryTypographyProps={{ fontSize: '14px' }}
              />
              <KeyboardArrowRightIcon />
            </ListItem>
          </SidebarContainer>
        </Box>

        {/* Right Content */}

        {isConfgurationList && tab === SidebarDuplicateConfig.SAME_OBJECT_FIELD ? (
          <SidebarConfigList>
            {addConfigurationHeader(configurationListData?.length)}
            {configurationListLoading ? (
              <Loader />
            ) : (
              <>
                <Box sx={{ background: 'white', pb: 10 }}>
                  {!configurationListData?.length && (
                    <ConfigurationHeader>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          mt: 10,
                        }}
                      >
                        <MenuNoConfigIcon />
                        <Typography sx={{ color: '#9DA1B5', marginTop: '10px' }}>
                          You don’t have any configurations added yet
                        </Typography>
                        <SecondaryButton
                          sx={{ mt: 2 }}
                          startIcon={<PlusIcon />}
                          onClick={() => {
                            setIsConfgurationList(false);
                            setIsAddConfiguration(true);
                            setSelectSalesForce('');
                            setAvailableFields([]);
                            setFetchedFields([]);
                          }}
                        >
                          Add New Object Configurations
                        </SecondaryButton>
                      </Box>
                    </ConfigurationHeader>
                  )}
                </Box>
                <Box>
                  <ConfigurationList
                    configurationListData={configurationListData}
                    handleConfigActions={handleConfigActions}
                  />
                </Box>
              </>
            )}
          </SidebarConfigList>
        ) : (
          <SidebarContainer sx={{ p: 3 }}>
            {tab === SidebarDuplicateConfig.THRESHOLD && renderThresholdConfig()}
            {tab === SidebarDuplicateConfig.SAME_OBJECT_FIELD && renderSameObjectFieldConfig()}
          </SidebarContainer>
        )}
      </Box>
    </Container>
  );
};

export default DuplicateConfig;
