import { createSlice } from '@reduxjs/toolkit';
import {
  createduplicationConfig,
  getduplicationConfigById,
  getduplicationConfigList,
  deleteduplicationConfigById,
  getduplicationConfigObjects,
  getduplicationConfig,
} from './actions';
import { DuplicationConfigState } from './types';

export const initialState: DuplicationConfigState = {
  configurations: [],
  loading: false,
  error: null,
};

const duplicationConfigReducer = createSlice({
  name: 'duplicationConfig',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //Get Duplication Config Object Dropdown
      .addCase(getduplicationConfigObjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(getduplicationConfigObjects.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.configurations.push(payload);
      })
      .addCase(getduplicationConfigObjects.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message || 'Failed to create configuration';
      })

      //Get Duplication Config Object Fields
      .addCase(getduplicationConfig.pending, (state) => {
        state.loading = true;
      })
      .addCase(getduplicationConfig.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.configurations.push(payload);
      })
      .addCase(getduplicationConfig.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message || 'Failed to create configuration';
      })

      // Create Duplication Config
      .addCase(createduplicationConfig.pending, (state) => {
        state.loading = true;
      })

      .addCase(createduplicationConfig.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message || 'Failed to create configuration';
      })

      // Get Duplication Config Listing
      .addCase(getduplicationConfigList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getduplicationConfigList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.configurations = [payload];
      })
      .addCase(getduplicationConfigList.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message || 'Failed to fetch configuration';
      })

      // Get Duplication Config by Migration
      .addCase(getduplicationConfigById.pending, (state) => {
        state.loading = true;
      })

      .addCase(getduplicationConfigById.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message || 'Failed to fetch configurations';
      })
      // Delete Duplication Config by ID
      .addCase(deleteduplicationConfigById.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteduplicationConfigById.fulfilled, (state, { meta }) => {
        state.loading = false;
        state.configurations = state.configurations.filter((config) => config.id !== meta.arg.id);
      })
      .addCase(deleteduplicationConfigById.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message || 'Failed to delete configuration';
      });
  },
});

export default duplicationConfigReducer.reducer;
