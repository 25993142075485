export enum UserType {
  SUPER_AMIN = 'super_admin',
  ADMIN = 'admin',
  OWNER = 'owner',
  USER = 'user',
}

export enum UserRole {
  DATA_CREATOR = 'datacreator',
  DATA_EXPLORER = 'dataexplorer',
  DATA_VIEWER = 'dataviewer',
}

export enum InvitationStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  ACCEPTED = 'accepted',
}

export enum SidebarDuplicateConfig {
  THRESHOLD = 'threshold',
  SAME_OBJECT_FIELD = 'sameObjectField',
}

export enum UserStatusType {
  ACTIVE = 'Active',
  INACTIVE = 'Deactivated',
  PENDING_INVITATION = 'Pending Invitation',
}

export interface OptionValue<T> {
  label: string;
  value: T;
}

export interface TenantUpdateEventDto {
  tenantId: string;
  table: string;
  ruleIds?: string[];
  type: string;
}

export enum ConfigAction {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'Inactive',
}
