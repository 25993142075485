import React from 'react';
import { Stack, Typography, Avatar, CircularProgress, AvatarGroup, Tooltip } from '@mui/material';
import format from 'date-fns/format';
import { useGetMigrationTableDataByIdQuery } from 'store/migration/api';
import { parseEmailText } from '../../utils/parseEmailText';

interface IProps {
  task: any;
  isOpen?: boolean;
  migrationId: string;
  position: { top: number; left: number };
}
const EmailTooltip = ({ task, isOpen = false, migrationId, position }: IProps) => {
  const [tooltipVisible, setTooltipVisible] = React.useState<boolean>(false);

  const { data: contacts = [], isFetching: contactsLoading } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'Contact',
      skip: 0,
      take: 0,
      conditions: [{ field: 'Id', operator: 'IN', value: `('${task?.WhoId}','${task?.WhatId}','${task?.OwnerId}')` }],
    },
    {
      skip: !task || (!task?.WhoId && !task?.WhatId && !task.OwnerId),
    },
  );
  const emailData = React.useMemo(() => (task ? parseEmailText(task?.Description) : null), [task]);

  const isVisible = React.useMemo(() => tooltipVisible || isOpen, [tooltipVisible, isOpen]);

  if (isVisible)
    return (
      <Stack
        sx={{
          zIndex: 999,
          p: 1,
          backgroundColor: 'blue.dark',
          borderRadius: '5px',
          color: 'common.white',
          width: '230px',
          position: 'absolute',
          top: position.top,
          left: 0,
          transform: 'translate(-80%,-10%)',
        }}
        gap={0.5}
        onMouseOver={() => {
          setTooltipVisible(true);
        }}
        onMouseOut={() => {
          setTimeout(() => {
            setTooltipVisible(false);
          }, 400);
        }}
      >
        <Stack direction="row" gap={1} alignItems="baseline">
          <Typography variant="b12" color="common.white" mb={1}>
            {task?.Subject}
          </Typography>
          {task?.ThreadCount && (
            <Stack
              sx={{
                width: '18px',
                height: '18px',
                display: 'flex',
                borderRadius: '50%',
                px: '4px',
                py: '2px',
                fontSize: '12px',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'neutral.n400',
                color: 'white',
              }}
            >
              {task.ThreadCount}
            </Stack>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Date</Typography>
          <Typography variant="labelMedium12">
            {task?.CreatedDate ? format(new Date(task?.CreatedDate), 'MMM dd, h:mm aa') : null}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">To</Typography>
          <Typography variant="labelMedium12">{task?.ToAddress || emailData?.to}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Recipients</Typography>
          {contactsLoading ? (
            <CircularProgress sx={{ color: 'white' }} size={14} />
          ) : (
            <Typography variant="labelMedium12">
              <AvatarGroup>
                {(contacts || []).map((singleContact) => (
                  <Tooltip
                    key={`avatar_${singleContact?.Id}`}
                    title={singleContact?.Name ?? `${singleContact?.FirstName} ${singleContact?.LastName}`}
                  >
                    <Avatar sx={{ width: '18px', height: '18px', fontSize: '12px' }}>
                      {singleContact?.Name?.[0] ?? singleContact?.FirstName?.[0]}
                    </Avatar>
                  </Tooltip>
                ))}
              </AvatarGroup>
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  return <></>;
};

export default EmailTooltip;
