import { useCallback } from 'react';
import { useGraphRender } from 'store/graphRender/hooks';

export const useDataGraphUtils = () => {
  const {
    data: { nodeHashMap, edgesHashMap },
  } = useGraphRender();

  const findRecordRelatedAccount = useCallback(
    (nodeId: string) => {
      const edgeKeys = Object.keys(edgesHashMap);
      let nodeToProcess = [nodeId];
      let accountNodeId: string | undefined;
      const originNode = nodeHashMap[nodeId];
      if (originNode?.type === 'AccountNode') {
        return nodeId;
      }
      const maxCycles = 10;
      let cycle = 0;
      while (nodeToProcess.length > 0 && !accountNodeId && cycle < maxCycles) {
        cycle++;
        const nodeToProcessTmp: string[] = [];
        nodeToProcess.forEach((processNodeId) => {
          edgeKeys.forEach((edgeKey) => {
            if (edgesHashMap[edgeKey].target === processNodeId) {
              const sourceId = edgesHashMap[edgeKey].source;
              const sourceNode = nodeHashMap[sourceId];
              const labelComponents: string[] = sourceNode?.data?.label?.split('.');
              if (labelComponents) {
                const label = labelComponents[1] ? labelComponents[1] : labelComponents[0];
                if (label.toLowerCase() === 'account') {
                  accountNodeId = sourceId;
                } else {
                  nodeToProcessTmp.push(sourceId);
                }
              }
            }
          });
        });
        nodeToProcess = nodeToProcessTmp;
      }
      return accountNodeId;
    },
    [edgesHashMap, nodeHashMap],
  );

  const returnBreadSearchComponents = useCallback(
    (originNodeId: string, incomers = false, outers = false, stopNodeType = 'AccountNode') => {
      const edgeKeys = Object.keys(edgesHashMap);
      let nodeToProcess = [originNodeId];
      const lowerCaseStopNodeType = stopNodeType.toLocaleLowerCase();
      const edgesId: Set<string> = new Set([]);
      const nodesId: Set<string> = new Set([]);
      const nodeHistorical: any = {};
      const maxCycles = 10;
      let cycle = 0;
      while (nodeToProcess.length > 0 && cycle < maxCycles) {
        cycle++;
        const nodeToProcessTmp: string[] = [];
        nodeToProcess.forEach((processNodeId) => {
          edgeKeys.forEach((edgeKey) => {
            if (incomers) {
              if (edgesHashMap[edgeKey].target === processNodeId) {
                edgesId.add(edgeKey);
                const sourceId = edgesHashMap[edgeKey].source;
                const sourceNode = nodeHashMap[sourceId];
                const nodeType: string = sourceNode?.type;
                if (nodeType?.toLowerCase() !== lowerCaseStopNodeType && !nodeHistorical[sourceId]) {
                  nodeToProcessTmp.push(sourceId);
                  nodesId.add(sourceId);
                  nodeHistorical[sourceId] = true;
                }
              }
            }
            if (outers) {
              if (edgesHashMap[edgeKey].source === processNodeId) {
                edgesId.add(edgeKey);
                const targetId = edgesHashMap[edgeKey].target;
                const targetNode = nodeHashMap[targetId];
                const nodeType: string = targetNode.type;
                if (nodeType.toLowerCase() !== lowerCaseStopNodeType && !nodeHistorical[targetId]) {
                  nodeToProcessTmp.push(targetId);
                  nodesId.add(targetId);
                  nodeHistorical[targetId] = true;
                }
              }
            }
          });
        });
        nodeToProcess = nodeToProcessTmp;
      }
      return { nodesId: Array.from(nodesId), edgesId: Array.from(edgesId) };
    },
    [edgesHashMap, nodeHashMap],
  );

  return { findRecordRelatedAccount, returnBreadSearchComponents };
};
