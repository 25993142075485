import {
  styled,
  Box,
  Button,
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon,
  TextField,
} from '@mui/material';
import { color } from 'd3';

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.lightBg.main,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '24px 32px',
  backgroundColor: theme.palette.neutral.white,
}));

export const VerticalDivider = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  width: 1,
  height: 8,
  position: 'relative',
  '&::before': {
    content: '" "',
    height: 16,
    position: 'absolute',
    top: '-50%',
    width: 1,
    backgroundColor: theme.palette.neutral.n200,
    marginBottom: -4,
  },
}));

// Styled Components
export const SidebarContainer = styled(Box)({
  width: '100%',
  height: '100%',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
});

export const SidebarConfigList = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const ConfigurationHeader = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const SidebarList = styled(Box)({
  backgroundColor: 'white',
  padding: '16px',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
});

export const StyledButton = styled(Button)(({ theme }) => ({
  justifyContent: 'flex-start',
  borderRadius: '8px',
  borderColor: 'transparent',
  color: '#344054',
  fontSize: '14px',
  fontWeight: '500',
  textTransform: 'none',
  marginBottom: '12px',
  transition: 'border-color 0.3s, box-shadow 0.3s',
  padding: '30px 15px',
  '&:hover': {
    borderColor: theme.palette.primary.main, // Highlight on hover
    background: 'transparent',
    color: theme.palette.primary.main,
  },
  '&:focus': {
    borderColor: theme.palette.primary.main, // Highlight on focus
    boxShadow: `0px 0px 4px ${theme.palette.primary.main}`, // Add shadow effect on focus
    background: 'transparent',
    color: theme.palette.primary.main,
  },
  '&.Mui-disabled': {
    color: '#9AA0A6',
    borderColor: '#D0D5DD',
  },
}));

export const ListItem = styled(MuiListItem, {
  shouldForwardProp: (prop) => prop !== 'nested' && prop !== 'active',
})<{ nested?: boolean; active?: boolean }>(({ theme, nested = false, active = false }) => ({
  ...theme.typography.labelMedium14,
  height: '60px',
  color: active ? '#1554FF' : theme.palette.neutral.n400,
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  position: 'relative',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  border: active ? '1px solid #1554FF' : '1px solid transparent',
  marginBottom: '5px',
  borderRadius: '8px',
  '&::before': {
    content: '" "',
    width: 4,
    height: 'auto',
    position: 'absolute',
    left: 0,
    opacity: active ? 1 : 0,
  },
  '& svg:not(.path-fill) *': {
    stroke: active ? '#1554FF' : undefined,
  },
  '& svg.path-fill *': {
    fill: active ? '#1554FF' : undefined,
  },
  ':hover': {
    color: '#1554FF',
    border: '1px solid #1554FF',
    '&::before': {
      opacity: 1,
    },
    '& svg:not(.path-fill) *': {
      stroke: '#1554FF',
    },
    '& svg.path-fill *': {
      fill: '#1554FF',
    },
  },
}));

export const ListItemIcon = styled(MuiListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'marginRight' && prop !== 'small',
})<{ marginRight?: number; small?: boolean }>(({ theme, marginRight, small }) => ({
  height: small ? 16 : 24,
  width: small ? 16 : 24,
  minWidth: small ? 16 : 24,
  marginRight: marginRight ?? theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ConfigurationText = styled(TextField)(() => ({
  width: '4rem',
  '& .MuiInputBase-input': {
    padding: '8px 0px 8px 18px',
    // Remove up/down arrows for number inputs
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0,
    },
    '&[type=number]': {
      appearance: 'textfield',
    },
  },
}));
