export const extractTeamsMeetingLink = (content: string) => {
  const teamsMeetingRegex = /https:\/\/teams\.microsoft\.com\/l\/meetup-join\/[^\s"'<>]+/g;
  const matches = content.match(teamsMeetingRegex);
  return matches ? matches[0] : null;
};

export const isHtml = (content: string) => {
  const htmlRegex = /<\/?[a-z][\s\S]*>/i;
  return htmlRegex.test(content);
};
