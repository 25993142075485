import { forwardRef } from 'react';
import { styled, Box, Checkbox as MuiCheckbox, Button } from '@mui/material';

import { ReactComponent as BoxChecked } from 'assets/icons/boxChecked.svg';
import { ReactComponent as BoxUnchecked } from 'assets/icons/boxUnchecked.svg';
import { ReactComponent as SortUpIcon } from 'assets/icons/triangleUp.svg';
import { ReactComponent as SortDownIcon } from 'assets/icons/triangleDown.svg';
import { ReactComponent as UnsortedIcon } from 'assets/icons/unsorted.svg';

export const Container = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(3),
  background: '#F6F8FB',
}));

export const TitleContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 8,
}));

const Checkbox = styled(MuiCheckbox)(() => ({
  padding: '0 10px 0 0',
}));

// eslint-disable-next-line react/display-name
export const BaseCheckbox = forwardRef((props, ref) => {
  // @ts-ignore
  return <Checkbox ref={ref} {...props} icon={<BoxUnchecked />} checkedIcon={<BoxChecked />} />;
});

export const ColumnSortedAscendingIcon = () => {
  return <SortUpIcon className="sortup-icon" />;
};

export const ColumnSortedDescendingIcon = () => {
  return <SortDownIcon className="sortdown-icon" />;
};

export const ColumnUnsortedIcon = () => {
  return <UnsortedIcon />;
};

export const ActiveButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.green.dark,
  color: theme.palette.neutral.white,
  fontSize: 12,
  padding: '5px 20px',
  borderRadius: 20,
  [`&:hover`]: {
    backgroundColor: theme.palette.green.dark,
  },
  height: '25px',
}));

export const AcceptedInvitationButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark, // A lighter green for "accepted"
  color: theme.palette.neutral.white,
  fontSize: 12,
  padding: '5px 20px',
  borderRadius: 20,
  [`&:hover`]: {
    backgroundColor: theme.palette.primary.dark, // Darker shade on hover
  },
  height: '25px',
}));

export const DeactiveButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.red.dark,
  color: theme.palette.neutral.white,
  fontSize: 12,
  padding: '5px 20px',
  borderRadius: 20,
  [`&:hover`]: {
    backgroundColor: theme.palette.red.dark,
  },
  height: '25px',
}));

export const PendingInvitationButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.subtone1,
  color: theme.palette.neutral.white,
  fontSize: 12,
  padding: '5px 20px',
  borderRadius: 20,
  [`&:hover`]: {
    backgroundColor: theme.palette.primary.subtone1,
  },
  height: '25px',
}));

export const InvitationCancelledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.black,
  color: theme.palette.neutral.white,
  fontSize: 12,
  padding: '5px 20px',
  borderRadius: 20,
  [`&:hover`]: {
    backgroundColor: theme.palette.primary.black,
  },
  height: '25px',
}));

export const HighlightName = styled(Box)(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  fontWeight: '500',
}));

export const HighlightEmail = styled(Box)(() => ({
  color: '#898EA1',
}));

export const StyledPendingContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StyledSentInfo = styled(Box)(() => ({
  marginLeft: '30px',
}));
