import { FC, useEffect, useState } from 'react';

import { Loader } from 'components/Loader';
import { Container } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { acceptInvitation } from 'http/user';
import { PUBLIC_ABS_ROUTE_PATHS } from 'core/constants';
import { PrimaryButton } from 'components/ui';
import { User } from 'store/user/types';

const Invitation: FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const acceptInvitationByToken = async () => {
    setLoading(true);
    try {
      const response: User = await acceptInvitation(token!);
      if ('statusCode' in response) {
        if (response.statusCode === 404) {
          setErrorMessage('Invitation not found');
          setLoading(false);
        } else if (response.statusCode === 409) {
          navigate(`${PUBLIC_ABS_ROUTE_PATHS.login}`);
        }
      } else {
        navigate(`${PUBLIC_ABS_ROUTE_PATHS.signup}?token=${token}`);
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (token) {
      acceptInvitationByToken();
    }
  }, [token]);

  return loading ? (
    <Loader />
  ) : (
    <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
      <h1> {errorMessage ?? 'Something went wrong!'} </h1>
      <PrimaryButton href="/auth/login"> Back to Login</PrimaryButton>
    </Container>
  );
};

export default Invitation;
