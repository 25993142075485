import { useCallback, useState } from 'react';
import { getLayoutedElements } from '../../utils/LayoutUtils';

import { getEdgePositionPayload, incrementDistanceToAPointBasedOnADirection } from '../../utils/GeometryUtils';
import { useGraphRender } from 'store/graphRender/hooks';
import { useReactFlow, useStoreApi, Node } from 'reactflow';
import { ComponentDesign } from '../../types';
import _ from 'lodash';
import { useDataGraphUtils } from './useDataGraphUtils';
import { GraphRenderView } from 'store/graphRender/types';
import { NodeRecordTypeEnum } from '../../classes/RecordNode';
import { BridgeNodeTypeEnum } from '../../classes/BridgeNode';
import { getEventTypeClassification } from '../../utils/EnumerationUtils';

const desiredRelatedEdgeLength = 100;
const edgeIncrementRate = 60;

const initialNodes: any[] = [];
const initialEdges: any[] = [];

interface onLayoutParams {
  useInitialNodes?: boolean;
  expandBridgeNodes?: boolean;
}

export const useGraphRenderLayoutUtils = () => {
  const [edgesToExpand, setEdgesToExpand] = useState<string[]>([]);
  const reactFlow = useReactFlow();
  const { setNodes, setEdges } = useStoreApi().getState();
  const { findRecordRelatedAccount, returnBreadSearchComponents } = useDataGraphUtils();
  const {
    data: {
      renderEdges,
      renderNodes,
      edgesHashMap,
      searchFilterResult,
      nodeHashMap,
      selectedNode,
      selectedNodeFromSearchBar,
      searching,
      hoverAccountId,
      view: graphRenderView,
      duplicatedNodeIds,
      schemaName,
    },
    setEdgesHashMap,
    setNodesHashMap,
    setCustomDesignMap,
  } = useGraphRender();

  const getElkOption = useCallback(() => {
    const animationOptions = {
      'elk.animate': 'true',
      'elk.animTimeFactor': '100',
      'elk.maxAnimTime': '4000',
      'elk.minAnimTime': '1000',
    };

    if (graphRenderView === GraphRenderView.LEADS) {
      // return {
      //   'elk.algorithm': 'radial',
      //   'elk.layered.spacing.nodeNodeBetweenLayers': '50',
      //   'elk.layered.spacing.edgeNode': '50',
      //   'elk.layered.spacing.edgeEdge': '50',
      //   'elk.layered.spacing.nodeNode': '50',
      //   ...animationOptions,
      // };
      return {
        'elk.algorithm': 'stress',
        'elk.spacing.nodeNode': '250',
        'elk.stress.desiredEdgeLength': '130',
        ...animationOptions,
      };
    }
    return {
      'elk.algorithm': 'stress',
      'elk.spacing.nodeNode': '350',
      'elk.stress.desiredEdgeLength': '130',
      'elk.direction': 'DOWN',
      ...animationOptions,
    };
  }, [graphRenderView]);

  const getCalculatedDesiredEdgeLength = useCallback((nodesCount: number, initialDesiredLength: number) => {
    return initialDesiredLength + (nodesCount > 5 ? (nodesCount - 5) * 12 : 0);
  }, []);

  const shouldSkipOnHideProcessing = useCallback(
    (
      targetNode: any,
      recursionLevel: number,
      edge: any,
      checkPrefix?: string,
      filterFunction?: (node: any, recursionLevel: number, edge: any) => boolean,
    ) => {
      if (filterFunction) {
        return !filterFunction(targetNode, recursionLevel, edge);
      }
      if (targetNode && checkPrefix && !targetNode.data.metaData?.previousNodePrefix?.startsWith(checkPrefix)) {
        return true;
      }
      return false;
    },
    [],
  );

  const onHide = useCallback(
    (
      originNodeId: string,
      hideValue?: boolean,
      options?: {
        desiredEdgedLength?: number;
        checkPrefix?: string;
        recursionLevel?: number;
        sortingFunction?: (a: any, b: any) => number;
        filterFunction?: (node: any, recursionLevel: number, edge: any) => boolean;
        piMultiplier?: number;
        changeCollapseState?: boolean;
      },
    ) => {
      const {
        desiredEdgedLength = desiredRelatedEdgeLength,
        checkPrefix,
        recursionLevel,
        sortingFunction,
        filterFunction,
        piMultiplier,
        changeCollapseState = true,
      } = options || {};
      const bridgedExpanded: string[] = [];

      const edges = reactFlow.getEdges();
      const bridgeNode = reactFlow.getNode(originNodeId);
      const collapsed = bridgeNode?.data._collapsed == null ? true : bridgeNode?.data._collapsed;
      const hide = hideValue === undefined ? (collapsed === false ? true : false) : hideValue;
      const originNode = bridgeNode;
      const accountEdge = edges.find((edge: any) => edge.target === originNodeId && edge.type != 'whiteBackground');
      const accountNode = accountEdge && accountEdge.source ? reactFlow.getNode(accountEdge.source) : undefined;
      if (!originNode) return;
      const edgesToUpdate: string[] = [];
      const nodesToUpdate: string[] = [];
      let nodePayload: any = {};
      let nodesToProcess: any[] = [];

      let childNodes: any[] = [];
      for (const key in edgesHashMap) {
        const edge = edgesHashMap[key];
        if (edge.source === originNodeId && !edge.id.startsWith('duplicate-edge')) {
          const targetNode = nodeHashMap[edge.target];
          const shouldSkipProcessing = shouldSkipOnHideProcessing(targetNode, 0, edge, checkPrefix, filterFunction);
          if (shouldSkipProcessing) continue;
          edgesToUpdate.push(edge.id);
          nodesToUpdate.push(edge.target);
          childNodes.push(nodeHashMap[edge.target]);
          nodesToProcess.push({ fatherId: originNodeId, bridgeId: edge.target });
        }
      }

      if (sortingFunction) {
        childNodes.sort(sortingFunction);
      }

      const payload = getEdgePositionPayload(
        accountNode || bridgeNode,
        bridgeNode,
        childNodes.filter(node => !!node?.id).map((node) => node.id),
        getCalculatedDesiredEdgeLength(childNodes.length, desiredEdgedLength),
        piMultiplier || accountNode == null ? 2 : 1,
      );

      nodePayload = { ...nodePayload, ...payload };

      const maxCycles = recursionLevel != null && hide != true ? recursionLevel : 10;
      let cycle = 0;

      while (nodesToProcess.length > 0 && cycle < maxCycles) {
        cycle++;
        const nodeToProcessTmp: any[] = [];
        nodesToProcess.forEach(({ fatherId, bridgeId }) => {
          childNodes = [];
          let fatherNode: any = reactFlow.getNode(fatherId);
          if (!fatherNode) {
            fatherNode = _.cloneDeep(nodeHashMap[fatherId]);
            const { x, y } = nodePayload[fatherId];
            fatherNode.position = { x, y };
          }
          let bridgeNode: any = reactFlow.getNode(bridgeId);
          if (!bridgeNode) {
            bridgeNode = _.cloneDeep(nodeHashMap[bridgeId]);
            const { x, y } = nodePayload[bridgeId];
            bridgeNode.position = { x, y };
          }
          for (const key in edgesHashMap) {
            const edge = edgesHashMap[key];
            if (edge.source === bridgeId && !edge.id.startsWith('duplicate-edge')) {
              const targetNode = nodeHashMap[edge.target];
              const shouldSkipProcessing = shouldSkipOnHideProcessing(
                targetNode,
                cycle,
                edge,
                checkPrefix,
                filterFunction,
              );
              if (shouldSkipProcessing) {
                continue;
              }
              edgesToUpdate.push(edge.id);
              nodesToUpdate.push(edge.target);
              childNodes.push(nodeHashMap[edge.target]);
              if (bridgeNode.data.bridge === true) {
                bridgedExpanded.push(bridgeId);
              }
              nodeToProcessTmp.push({ fatherId: bridgeId, bridgeId: edge.target });
            }
          }
          if (sortingFunction) {
            childNodes.sort(sortingFunction);
          }
          const payload = getEdgePositionPayload(
            fatherNode,
            bridgeNode,
            childNodes.map((node) => node.id),
            getCalculatedDesiredEdgeLength(childNodes.length, desiredEdgedLength),
            piMultiplier,
          );
          nodePayload = { ...nodePayload, ...payload };
        });
        nodesToProcess = nodeToProcessTmp;
      }

      // TODO Nodes Calculation
      const nodeIdProcessed: string[] = [];
      const nodes = reactFlow.getNodes();
      const existingNodeUpdate = nodes.map((node: any) => {
        if (nodesToUpdate.includes(node.id)) {
          const nodeTmp = _.cloneDeep(node);
          nodeTmp.hidden = hide;
          const { x, y, dir } = nodePayload[nodeTmp.id] || { x: 0, y: 0, dir: 0 };
          nodeTmp.position = { x, y };
          nodeTmp.data = { ...nodeTmp.data, _dir: dir };
          nodeIdProcessed.push(nodeTmp.id);
          if (bridgedExpanded.includes(nodeTmp.id) && changeCollapseState === true) {
            nodeTmp.data._collapsed = hide;
          }
          return nodeTmp;
        }
        if (originNodeId === node.id && changeCollapseState === true) {
          const nodeTmp = _.cloneDeep(node);
          nodeTmp.data._collapsed = hide;
          return nodeTmp;
        }
        return node;
      });

      if (hide === false) {
        nodesToUpdate.forEach((nodeId) => {
          const nodeTmp: any = _.cloneDeep(nodeHashMap[nodeId]);
          if (nodeTmp && !nodeIdProcessed.includes(nodeId)) {
            const { x, y, dir } = nodePayload[nodeId] || { x: 0, y: 0, dir: 0 };
            nodeTmp.hidden = hide;
            nodeTmp.position = { x, y };
            nodeTmp.data = { ...nodeTmp.data, _dir: dir };
            if (bridgedExpanded.includes(nodeTmp.id) && changeCollapseState === true) {
              nodeTmp.data._collapsed = hide;
            }
            existingNodeUpdate.push(nodeTmp);
          }
        });
      }

      // TODO Edges Calculation
      const edgeMap: { [x: string]: any } = {};
      edges.forEach((edge) => {
        if (edgesToUpdate.includes(edge.id)) {
          const edgeTmp = _.cloneDeepWith(edge);
          edgeTmp.hidden = hide;
          edgeMap[edge.id] = edgeTmp;
        } else {
          edgeMap[edge.id] = edge;
        }
      });
      if (hide === false) {
        edgesToUpdate.forEach((edgeId) => {
          if (edgeMap[edgeId] == null && edgesHashMap[edgeId] !== null) {
            const edgeTmp: any = _.cloneDeep(edgesHashMap[edgeId]);
            edgeTmp.hidden = hide;
            edgeMap[edgeId] = edgeTmp;
          }
        });
      }

      setNodes(existingNodeUpdate.filter((x) => x));
      setEdges(Object.values(edgeMap));
      return [nodesToUpdate, edgesToUpdate];
    },
    [
      edgesHashMap,
      getCalculatedDesiredEdgeLength,
      nodeHashMap,
      reactFlow,
      setEdges,
      setNodes,
      shouldSkipOnHideProcessing,
    ],
  );

  const applyWhiteEdge = useCallback(
    (selectedNodeToApplyWhiteEdge: string | null) => {
      const whiteBackgroundEdge: any = {
        id: `whiteBackground.${selectedNodeToApplyWhiteEdge}`,
        type: 'whiteBackground',
        source: selectedNodeToApplyWhiteEdge,
        target: selectedNodeToApplyWhiteEdge,
        hidden: false,
        data: {
          id: `whiteBackground.${selectedNodeToApplyWhiteEdge}`,
          type: 'whiteBackground',
        },
      };

      const filteredEdges = reactFlow.getEdges().filter((edge) => edge.type !== 'whiteBackground');

      if (selectedNodeToApplyWhiteEdge) {
        setEdges([whiteBackgroundEdge, ...filteredEdges]);
      } else {
        setEdges(filteredEdges);
      }
    },
    [reactFlow, setEdges],
  );

  const showNodeActivities = useCallback(
    (originNodeId: string, hideValue?: boolean, options: { desiredEdgedLength?: number } = {}) => {
      const { desiredEdgedLength } = options || {};
      const edgesToDelete: any = {};

      const hideFilterFunction = (node: any, recursionLevel: number, edge: any) => {
        return node?.type !== 'RecordNode';
      };

      const filterFunction = (node: any, recursionLevel: number, currentEdge: any) => {
        if (recursionLevel === 0) {
          return node?.type === BridgeNodeTypeEnum.EventBridgeNode;
        }
        if (recursionLevel === 1) {
          const filteredEdges = reactFlow
            .getEdges()
            .filter((edge) => edge.target === node.id && edge.id !== currentEdge.id);

          if (hideValue === true) {
            const nodesInCommon = filteredEdges
              .map((edge) => {
                const source = reactFlow.getNode(edge.source);
                if (source && source.hidden === false) {
                  return source;
                }
                return null;
              })
              .filter((x) => x);
            if (nodesInCommon.length > 0) {
              edgesToDelete[currentEdge.id] = true;
              return false;
            }
          }

          return node?.type === NodeRecordTypeEnum.EventRecordNode;
        }
        return false;
      };

      const [nodeIds, edgesIds] =
        onHide(originNodeId, hideValue, {
          filterFunction: !hideValue ? filterFunction : hideFilterFunction,
          desiredEdgedLength,
          recursionLevel: 0,
        }) || [];

      if (hideValue === false) {
        const eventNodes: Node<any>[] = [];

        nodeIds.forEach((nodeId) => {
          const node = reactFlow.getNode(nodeId);
          if (node?.type === NodeRecordTypeEnum.EventRecordNode) {
            return eventNodes.push(node);
          }
        });

        const edges = Object.values(edgesHashMap);
        const edgesToAdd: any = {};

        eventNodes.forEach((eventNode) => {
          const relatedEdges = edges.filter((edge) => edge.source === eventNode.id || edge.target === eventNode.id);

          const directConnectionsEdges = relatedEdges.filter((edge) => edge.data?.metaData?.directConnection === true);
          const sourceDifferentThanOrigin = directConnectionsEdges.filter((edge) => edge.source !== originNodeId);
          const otherNodesIds = sourceDifferentThanOrigin.map((edge) => edge.source);
          const uniqueIds = Array.from(new Set<string>(otherNodesIds));

          uniqueIds.forEach((nodeId) => {
            const eventTypeClassification = getEventTypeClassification(eventNode.data.Type);
            const dummyNodeId = `${nodeId}-EventBridge-${eventTypeClassification}`;
            const dummyNode = reactFlow.getNode(dummyNodeId);
            if (dummyNode && dummyNode.hidden === false) {
              return; // Node already shown
            }
            let otherNode: any = reactFlow.getNode(nodeId);
            if (!otherNode || otherNode.hidden === true) {
              // Single show without changing collapseState
              otherNode = nodeHashMap[nodeId];
              const accountId = findRecordRelatedAccount(nodeId);
              const label = otherNode.data.label;
              const bridgeId = `${accountId}-${label}-bridge`;
              const existingNodes = edges.filter((edge) => {
                const targetNode = reactFlow.getNode(edge.target);
                return edge.source === bridgeId && targetNode?.hidden === false;
              });
              let existingNodesIds = existingNodes.map((edge) => edge.target);
              existingNodesIds = [...existingNodesIds, nodeId];
              onHide(bridgeId, hideValue, {
                recursionLevel: 0,
                filterFunction: (node) => {
                  return existingNodesIds.includes(node.id);
                },
                changeCollapseState: false,
              });
            }
            const edgesBetween = edges.filter(
              (edge) =>
                ((edge.source === nodeId && edge.target === eventNode.id) ||
                  (edge.source === eventNode.id && edge.target === nodeId)) &&
                edge.hidden === true &&
                edge.data.metaData.directConnection === true,
            );
            edgesBetween.forEach((edge) => (edgesToAdd[edge.id] = true));
          });
        });

        const edgesTmp = reactFlow.getEdges();
        const newEdgeHashMap: any = {};

        edgesTmp.forEach((edge) => {
          if (
            (hideValue === false && edge.data?.metaData?.directConnection === true && edge.source === originNodeId) ||
            edgesToDelete[edge.id]
          ) {
            return true;
          }
          return (newEdgeHashMap[edge.id] = edge);
        });

        const edgesToAddArray: any[] = Object.keys(edgesToAdd).filter((edgeId) => !edgesToDelete[edgeId]);

        edgesToAddArray.forEach((edgeId: string) => {
          const edgeTmp: any = reactFlow.getEdge(edgeId) || _.cloneDeep(edgesHashMap[edgeId]);
          edgeTmp.hidden = hideValue;
          return (newEdgeHashMap[edgeId] = edgeTmp);
        });

        setEdges(Object.values(newEdgeHashMap));
      }
    },
    [edgesHashMap, findRecordRelatedAccount, nodeHashMap, onHide, reactFlow, setEdges],
  );

  const triggerCollapseAnimation = useCallback(
    (edgesToExpand: string[]) => {
      console.log('TriggerCollapseAnimation triggered', edgesToExpand);
      const nodesToUpdate: string[] = [];
      const nodePayload: any = {};
      const edgesToRemove: string[] = [];
      edgesToExpand.forEach((edgeId) => {
        const edge = edgesHashMap[edgeId];
        if (!edge) {
          edgesToRemove.push(edgeId);
          return;
        }
        const source = reactFlow.getNode(edge.source);
        const target = reactFlow.getNode(edge.target);
        if (!source || !target) {
          edgesToRemove.push(edgeId);
          return;
        }
        const { x: sourceX, y: sourceY } = source.position;
        const { x: targetX, y: targetY } = target.position;
        const { _dir } = target.data;
        if (!_dir || target.type !== 'RecordNode') {
          edgesToRemove.push(edgeId);
          return;
        }
        // const dist = getDistanceBetweenPoints(sourceX, sourceY, targetX, targetY);
        // if (dist < desiredRelatedEdgeLength) {
        const newPoint: { x: number; y: number } = incrementDistanceToAPointBasedOnADirection(
          targetX,
          targetY,
          _dir,
          edgeIncrementRate,
        );
        nodesToUpdate.push(edge.target);
        nodePayload[edge.target] = newPoint;
        // } else {
        edgesToRemove.push(edgeId);
        // }
      });
      if (nodesToUpdate.length > 0) {
        const nodes = reactFlow.getNodes();
        setNodes(
          nodes.map((node) => {
            if (nodesToUpdate.includes(node.id)) {
              const nodeTmp = _.cloneDeep(node);
              const { x, y } = nodePayload[nodeTmp.id] || { x: 0, y: 0 };
              nodeTmp.position = { x, y };
              return nodeTmp;
            }
            return node;
          }),
        );
      }

      setTimeout(() => {
        setEdgesToExpand((edgesToExpand) => {
          const newEdgesToExpand = [...edgesToExpand];
          const finalEdgesToExpand = newEdgesToExpand.filter((edgeId) => !edgesToRemove.includes(edgeId));
          const uniqueEdges = new Set<string>(finalEdgesToExpand);
          return Array.from(uniqueEdges);
        });
      }, 0);
    },
    [edgesHashMap, reactFlow, setNodes],
  );

  const updateCustomNodesDesign = useCallback(() => {
    const sourceNodes: string[] = [];
    Object.keys(searchFilterResult).forEach((key) => {
      searchFilterResult[key].forEach((node) => {
        sourceNodes.push(node.Id);
      });
    });
    const sourceNodesIds = sourceNodes.map((nodeId) => `${schemaName}.${nodeId}`);
    const customDesignObject: any = {};
    let nodesToProcess = sourceNodesIds;
    const edgeKeys = Object.keys(edgesHashMap);
    const maxCycles = 10;
    let cycle = 0;
    while (nodesToProcess.length > 0 && cycle < maxCycles) {
      cycle++;
      let nodeToProcessTmp: string[] = [];
      nodesToProcess.forEach((sourceId) => {
        customDesignObject[sourceId] = ComponentDesign.HIGHLIGH;
        edgeKeys.forEach((edgeId) => {
          if (edgesHashMap[edgeId]?.target === sourceId) {
            customDesignObject[edgeId] = ComponentDesign.HIGHLIGH;
            nodeToProcessTmp.push(edgesHashMap[edgeId]?.source);
          }
        });
      });
      nodeToProcessTmp = nodeToProcessTmp.filter((x) => x);
      nodesToProcess = nodeToProcessTmp || [];
    }
    if (selectedNode && selectedNodeFromSearchBar === undefined && searching === true) {
      let unitedIds: string[] = [];
      const accountId = findRecordRelatedAccount(selectedNode.id);
      if (accountId) {
        const { nodesId, edgesId } = returnBreadSearchComponents(accountId, true, true);
        unitedIds = [...nodesId, ...edgesId];
        customDesignObject[accountId] = ComponentDesign.STANDARD;
        unitedIds.forEach((id) => {
          customDesignObject[id] = ComponentDesign.STANDARD;
        });
      }
    } else if (selectedNode === undefined && selectedNodeFromSearchBar && searching === true) {
      let unitedIds: string[] = [];
      const type = selectedNodeFromSearchBar.type;
      if (type === 'AccountNode') {
        const { nodesId, edgesId } = returnBreadSearchComponents(selectedNodeFromSearchBar.id, true, true);
        unitedIds = [...nodesId, ...edgesId];
      }
      if (type === 'RecordNode') {
        const { nodesId, edgesId } = returnBreadSearchComponents(selectedNodeFromSearchBar.id, true, false);
        unitedIds = [...nodesId, ...edgesId];
      }
      if (type === 'BridgeNode') {
        const { nodesId, edgesId } = returnBreadSearchComponents(selectedNodeFromSearchBar.id, false, true);
        unitedIds = [...nodesId, ...edgesId];
      }
      customDesignObject[selectedNodeFromSearchBar.id] = ComponentDesign.STANDARD;
      unitedIds.forEach((id) => {
        customDesignObject[id] = ComponentDesign.STANDARD;
      });
    }
    if (hoverAccountId && searching === true) {
      const { nodesId, edgesId } = returnBreadSearchComponents(hoverAccountId, true, true);
      const hoverAccountComponentIds = [...nodesId, ...edgesId];
      customDesignObject[hoverAccountId] = ComponentDesign.STANDARD;
      hoverAccountComponentIds.forEach((id) => {
        customDesignObject[id] = ComponentDesign.STANDARD;
      });
    }
    if (graphRenderView === GraphRenderView.DUPLICATES) {
      duplicatedNodeIds.forEach((nodeId) => {
        customDesignObject[nodeId] = ComponentDesign.DUPLICATE_ACTIVE;
      });
    }
    setCustomDesignMap(customDesignObject);
  }, [
    duplicatedNodeIds,
    edgesHashMap,
    findRecordRelatedAccount,
    graphRenderView,
    hoverAccountId,
    returnBreadSearchComponents,
    schemaName,
    searchFilterResult,
    searching,
    selectedNode,
    selectedNodeFromSearchBar,
    setCustomDesignMap,
  ]);

  const onLayout = useCallback(
    ({ useInitialNodes = false }: onLayoutParams) => {
      const elkOptions = getElkOption();
      const ns = useInitialNodes
        ? initialNodes
        : (Object.keys(renderNodes || {}) || [])
            .map((id) => _.cloneDeep(renderNodes[id]))
            .filter((node) => node.hidden === false);
      const es = useInitialNodes
        ? initialEdges
        : (Object.keys(renderEdges || {}) || [])
            .map((id) => _.cloneDeep(renderEdges[id]))
            .filter((node) => node.hidden === false);
      const filteredNodes = ns;
      const filteredEdges = es;
      getLayoutedElements(filteredNodes, filteredEdges, elkOptions).then((payload: any) => {
        if (payload == undefined) return;
        const layoutedNodes = payload.nodes;
        const layoutedEdges = payload.edges;
        const finishNodes = layoutedNodes.map((node: any) => {
          const nodeTmp = _.cloneDeep(node);
          return nodeTmp;
        });
        const finishEdges = layoutedEdges.map((edge: any) => {
          const edgeTmp = _.cloneDeep(edge);
          return edgeTmp;
        });
        const nodesHashMap: any = {};
        const edgesHashMap: any = {};
        layoutedNodes.forEach((node: any) => {
          nodesHashMap[node.id] = node;
        });
        layoutedEdges.forEach((edge: any) => {
          edgesHashMap[edge.id] = edge;
        });
        const newNodeHashMap = { ...renderNodes, ...nodesHashMap };
        const newEdgeHashMap = { ...renderEdges, ...edgesHashMap };
        setNodes(finishNodes);
        setEdges(finishEdges);
        setEdgesHashMap(newEdgeHashMap);
        setNodesHashMap(newNodeHashMap);
        setTimeout(() => {
          reactFlow.fitView();
        }, 0);
      });
    },
    [getElkOption, renderNodes, renderEdges, setNodes, setEdges, setEdgesHashMap, setNodesHashMap, reactFlow],
  );

  return {
    onHide,
    triggerCollapseAnimation,
    onLayout,
    updateCustomNodesDesign,
    returnBreadSearchComponents,
    showNodeActivities,
    applyWhiteEdge,
    edgesToExpand,
  };
};
