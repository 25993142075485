import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';
import { Show } from 'components/show';
import { Container } from './ui';
import Sidebar from './components/Sidebar';
import SettingsView from './components/SettingsView';

const NotificationsCenter = () => {
  const [selectedTab, setSelectedTab] = React.useState<'notifications' | 'settings'>('notifications');
  return (
    <Container sx={{ backgroundColor: 'lightBg.main' }}>
      <Grid container direction="row" columns={12} width="95%" mx="auto" mt={2} gap={3}>
        <Grid item xs={2} sx={{ backgroundColor: 'white', borderRadius: '8px' }}>
          <Sidebar selected={selectedTab} handleSelect={(entry) => setSelectedTab(entry as any)} />
        </Grid>
        <Grid
          item
          xs={9}
          sx={{ overflowY: 'auto', height: '100%', backgroundColor: 'white', p: 1, borderRadius: '8px', px: 3 }}
        >
          {selectedTab === 'settings' && (
            <Stack sx={{ width: '70%', mx: 'auto' }}>
              <SettingsView />
            </Stack>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotificationsCenter;
