import { useCallback, useEffect } from 'react';
import { useGraphData } from 'store/graphData/hooks';
import { useStoreApi } from 'reactflow';
import { useGraphRender } from 'store/graphRender/hooks';
import { GraphRenderView } from 'store/graphRender/types';
import { useGraphRenderDataHandlerUtils } from '../utils/useGraphRenderDataHandlerUtils';
import { useDuplicationDataHandlerUtils } from '../utils/useDuplicationDataHandlerUtils';
import { useMigration } from 'store/migration/hooks';
import { MIGRATION_STATUS_END_STATE } from 'store/graphData/types';
import { useDataGraphUtils } from '../utils/useDataGraphUtils';
import { useReactFlow } from 'reactflow';
import { useGraphRenderLayoutUtils } from '../utils/useGraphRenderLayoutUtils';
import { HideEventRelatedPostProcessing } from '../../utils/ComponentPostProcessing/HideEventRelatedPostProcessing';

const useAccountViewEffects = () => {
  const reactFlowStore = useStoreApi();
  const { setEdges } = reactFlowStore.getState();
  const reactFlow = useReactFlow();
  const {
    data: {
      view: graphRenderView,
      searching,
      graphSearchResult,
      graphAccountViewResult,
      graphFilter,
      selectedNode,
      selectedNodeFromSearchBar,
      whiteEdgeBlockNodeId,
    },
    error,
    setLoading,
    setError,
    setGraphAccountViewResult,
  } = useGraphRender();
  const { handleGraphComponentsLoad } = useGraphRenderDataHandlerUtils();
  const { applyWhiteEdge } = useGraphRenderLayoutUtils();
  const { findRecordRelatedAccount } = useDataGraphUtils();
  const { checkDeduplicationResult, checkDeduplicationResultByIds } = useDuplicationDataHandlerUtils();

  const {
    data: { migrationId },
  } = useMigration();
  const { getDataByLabel, migrationStatus: neptuneMigrationStatus } = useGraphData();

  useEffect(() => {
    if (
      [GraphRenderView.ACCOUNT, GraphRenderView.LEADS].includes(graphRenderView) &&
      !whiteEdgeBlockNodeId &&
      (selectedNode || selectedNodeFromSearchBar)
    ) {
      const calculatedNode = selectedNode || selectedNodeFromSearchBar;
      const id = calculatedNode.id;
      const selectedNodeToApplyWhiteEdge = id;

      applyWhiteEdge(selectedNodeToApplyWhiteEdge);
    }
  }, [
    applyWhiteEdge,
    findRecordRelatedAccount,
    graphRenderView,
    reactFlow,
    selectedNode,
    selectedNodeFromSearchBar,
    setEdges,
    whiteEdgeBlockNodeId,
  ]);

  useEffect(() => {
    if (
      graphRenderView === GraphRenderView.ACCOUNT &&
      graphAccountViewResult &&
      graphAccountViewResult.paths &&
      !searching
    ) {
      setLoading(true);
      handleGraphComponentsLoad(graphAccountViewResult.paths, graphAccountViewResult.accounts, []);
      setTimeout(() => {
        setLoading(false);
        checkDeduplicationResult();
      }, 0);
    }
  }, [
    checkDeduplicationResult,
    graphAccountViewResult,
    graphRenderView,
    handleGraphComponentsLoad,
    searching,
    setLoading,
  ]);

  const handleLoading = useCallback( async () => {
    if (graphRenderView === GraphRenderView.ACCOUNT && graphSearchResult && graphSearchResult.paths && searching) {
      setLoading(true);
      const { nodes } = await handleGraphComponentsLoad(
        graphSearchResult.paths,
        graphSearchResult.accounts,
        graphSearchResult.individualNodes || [],
        {
          hideExtendedNodes: false,
          nodePostProcessing: {
            filterFn: HideEventRelatedPostProcessing.nodeFilterFn,
            postProcessingFn: HideEventRelatedPostProcessing.nodePostProcessingFn,
          },
          edgePostProcessing: {
            filterFn: HideEventRelatedPostProcessing.edgeFilterFn,
            postProcessingFn: HideEventRelatedPostProcessing.edgePostProcessingFn,
          },
        },
      );
      const cleanNodeIds: string[] = [];
      Object.keys(nodes).forEach((nodeId) => {
        cleanNodeIds.push(nodeId.split('.')[1]);
      });
      setTimeout(() => {
        setLoading(false);
        checkDeduplicationResultByIds(cleanNodeIds);
      }, 0);
    }
  },[graphRenderView,
    graphSearchResult,
    searching,
    checkDeduplicationResultByIds,
    handleGraphComponentsLoad,
    setLoading,])

   useEffect(() => {
   handleLoading()
  }, [
    graphRenderView,
    graphSearchResult,
    searching,
    checkDeduplicationResultByIds,
    handleGraphComponentsLoad,
    setLoading,
    handleLoading
  ]);
 
  const loadDefaultAccountViewData = useCallback(() => {
    setLoading(true);
    getDataByLabel({
      migrationId: migrationId,
      label: 'Account',
      options: { limit: 50, levels: 2 },
      filter: graphFilter,
      onSuccess: (data) => {
        setGraphAccountViewResult(data);
        setLoading(false);
      },
      onError: (error) => {
        setLoading(false);
        setError(error.message || 'An error has ocurred!');
      },
    });
  }, [getDataByLabel, graphFilter, migrationId, setError, setGraphAccountViewResult, setLoading]);

  useEffect(() => {
    if (
      graphRenderView === GraphRenderView.ACCOUNT &&
      migrationId &&
      neptuneMigrationStatus &&
      Object.values(MIGRATION_STATUS_END_STATE).includes(neptuneMigrationStatus.status as MIGRATION_STATUS_END_STATE) &&
      !error
    ) {
      loadDefaultAccountViewData();
    }
  }, [error, graphRenderView, loadDefaultAccountViewData, migrationId, neptuneMigrationStatus]);

  return { loadDefaultAccountViewData };
};

export default useAccountViewEffects;
