import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createduplicationConfigApi,
  deleteConfigurationObject,
  getConfigurationList,
  getDuplicateConfigurationFields,
  getDuplicateConfigurationObjects,
  getDuplicationConfigurationById,
} from 'http/duplicateConfig';
import {
  GetduplicationConfigByMigrationArgs,
  DeleteduplicationConfigByIdArgs,
  ConfigurationPayload,
  AsyncFunction,
  Callback,
} from './types';

const CREATE_DUPLICATION_CONFIG = 'duplicationConfig/CREATE_DUPLICATION_CONFIG';
const GET_DUPLICATION_CONFIG_BY_ID = 'duplicationConfig/GET_DUPLICATION_CONFIG_BY_ID';
const GET_DUPLICATION_CONFIG_OBJECTS = 'duplicationConfig/GET_DUPLICATION_CONFIG_OBJECTS';
const GET_DUPLICATION_CONFIG_OBJECTS_FIELDS = 'duplicationConfig/GET_DUPLICATION_CONFIG_OBJECTS_FIELDS';
const DELETE_DUPLICATION_CONFIG_BY_ID = 'duplicationConfig/DELETE_DUPLICATION_CONFIG_BY_ID';
const GET_DUPLICATION_CONFIG_LIST = 'duplicationConfig/GET_DUPLICATION_CONFIG_LIST';

const asyncThunkWrapper = async <TParams, TResponse>(
  fn: AsyncFunction<TParams, TResponse>,
  params: TParams,
  onSuccess?: Callback<TResponse>,
  onError?: Callback<unknown>,
): Promise<TResponse> => {
  try {
    const response = await fn(params);
    if (onSuccess) onSuccess(response);
    return response;
  } catch (error) {
    if (onError) onError(error);
    throw error;
  }
};

export const getduplicationConfigObjects = createAsyncThunk(
  GET_DUPLICATION_CONFIG_OBJECTS,
  async ({ params, onError, onSuccess }: GetduplicationConfigByMigrationArgs) =>
    asyncThunkWrapper(getDuplicateConfigurationObjects, params, onSuccess, onError),
);

export const getduplicationConfig = createAsyncThunk(
  GET_DUPLICATION_CONFIG_OBJECTS_FIELDS,
  async ({ params, onError, onSuccess }: GetduplicationConfigByMigrationArgs) =>
    asyncThunkWrapper(getDuplicateConfigurationFields, params, onSuccess, onError),
);

export const createduplicationConfig = createAsyncThunk(
  CREATE_DUPLICATION_CONFIG,
  async ({ data, onError, onSuccess }: ConfigurationPayload) =>
    asyncThunkWrapper(createduplicationConfigApi, data, onSuccess, onError),
);

export const getduplicationConfigList = createAsyncThunk(
  GET_DUPLICATION_CONFIG_LIST,
  async ({ params, onError, onSuccess }: GetduplicationConfigByMigrationArgs) =>
    asyncThunkWrapper(getConfigurationList, params, onSuccess, onError),
);

export const getduplicationConfigById = createAsyncThunk(
  GET_DUPLICATION_CONFIG_BY_ID,
  async ({ params, onError, onSuccess }: GetduplicationConfigByMigrationArgs) =>
    asyncThunkWrapper(getDuplicationConfigurationById, params, onSuccess, onError),
);

export const deleteduplicationConfigById = createAsyncThunk(
  DELETE_DUPLICATION_CONFIG_BY_ID,
  async ({ id, onError, onSuccess }: DeleteduplicationConfigByIdArgs) =>
    asyncThunkWrapper(deleteConfigurationObject, id, onSuccess, onError),
);
