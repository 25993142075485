import { FC, useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { PUBLIC_ABS_ROUTE_PATHS } from 'core/constants';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from 'components/ui';
import { useAuth } from 'store/auth/hooks';
import { AuthLayout } from 'components/AuthLayout';
import { ReactComponent as GoogleWhiteSmallIcon } from 'assets/icons/googleWhiteSmall.svg';
import { loadFromLocalStorage } from './utils';
import { useLocation } from 'react-router-dom';
import { resendEmail as resendEmailApi } from 'http/auth';
import Swal from 'sweetalert2';

const SignupStep3: FC = () => {
  const [status, setStatus] = useState('verifying');
  const [message, setMessage] = useState('verifying');
  const { setSuccess } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const data = loadFromLocalStorage('signupData');
  const userEmail = data.email;
  useEffect(() => {
    const { verified, message } = location.state || {};
    if (message) {
      if (verified) {
        setStatus('success');
        return;
      } else {
        setMessage(message);
        setStatus('failed');
        return;
      }
    }
  }, [location]);

  const onGoBack = () => {
    setMessage('');
    setStatus('verifying');
    navigate(PUBLIC_ABS_ROUTE_PATHS.signup);
  };
  const handleResend = async () => {
    console.log('Resend clicked');
    Swal.fire({
      title: 'Sending...',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const sendRes = await resendEmailApi(userEmail);
      console.log('sendRes', sendRes);
      Swal.close();
      Swal.fire({
        title: 'Sent!',
        text: 'Verification email has been sent.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      console.log('error', error);
      Swal.close();
    }
  };
  const handleEditEmail = () => {
    console.log('Edit email clicked');
    navigate(PUBLIC_ABS_ROUTE_PATHS.signup);
  };
  return (
    <AuthLayout backButtonEnabled currentStep={4} totalSteps={4} onGoBack={onGoBack}>
      {status === 'success' ? (
        <Box>
          <Typography variant="h2" marginBottom={2}>
            Account Verified
          </Typography>

          <Typography variant="p12" sx={{ color: 'neutral.n400', maxWidth: 400 }} component="p">
            We&apos;ve verified your account.
          </Typography>

          <Typography variant="p12" sx={{ color: 'neutral.n400', maxWidth: 400 }} component="p" marginTop={1}>
            Thank you for registering! Please continue to log In
          </Typography>

          <LoadingButton onClick={() => navigate(PUBLIC_ABS_ROUTE_PATHS.login)} sx={{ marginTop: 4 }} fullWidth>
            Continue to Log In
          </LoadingButton>
        </Box>
      ) : status === 'verifying' ? (
        <Box>
          <Typography variant="h2" marginBottom={2}>
            Check your email
          </Typography>

          <Typography variant="p12" sx={{ color: 'neutral.n400', maxWidth: 400 }} component="p">
            We&apos;ve sent an email to{' '}
            <Typography variant="p12" sx={{ color: 'neutral.main' }}>
              {userEmail}
            </Typography>
            .
          </Typography>

          <Typography variant="p12" sx={{ color: 'neutral.n400', maxWidth: 400 }} component="p" marginTop={1}>
            Click the link in the email to confirm your address and activate your account.
          </Typography>

          <LoadingButton
            onClick={() => window.open(`mailto:${userEmail}`, '_blank', 'noopener')}
            sx={{ marginTop: 4 }}
            startIcon={<GoogleWhiteSmallIcon />}
            fullWidth
          >
            Go to Gmail
          </LoadingButton>

          <Typography variant="p12" sx={{ color: 'neutral.n400', maxWidth: 400 }} component="p" marginTop={4}>
            Didn&apos;t get the email?
          </Typography>

          <Typography
            variant="p12"
            sx={{ maxWidth: 400, color: '#898EA1' }}
            component="p"
            onClick={() => setSuccess(false)}
            marginTop={1}
          >
            <span
              onClick={handleResend}
              style={{
                color: '#1554FF',
                marginRight: 4,
                cursor: 'pointer',
              }}
            >
              Resend
            </span>
            or
            <span
              onClick={handleEditEmail}
              style={{
                color: '#1554FF',
                marginLeft: 4,
                cursor: 'pointer',
              }}
            >
              edit your email address
            </span>
          </Typography>
        </Box>
      ) : (
        <Box>
          <Typography variant="h2" marginBottom={2} sx={{ maxWidth: 400 }}>
            Verification Failed
          </Typography>

          <Typography variant="body2" sx={{ fontSize: 12, color: '#BD1E13', marginTop: '16px', maxWidth: 400 }}>
            {message}
          </Typography>
        </Box>
      )}
    </AuthLayout>
  );
};

export default SignupStep3;
