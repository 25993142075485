import React, { useState } from 'react';
import { Box, Typography, Menu, MenuItem, Divider, Popover, IconButton } from '@mui/material';
import { AdditionalItem, CardLabel, FieldChip, StyledCard, TitleItem } from './ui';
import { ReactComponent as DotsIcon } from 'assets/icons/dots.svg';
import { ReactComponent as EditConfiguration } from 'assets/icons/editConfig.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deletConfig.svg';

import AddIcon from '@mui/icons-material/Add';
import { CardComponentProps, ConfigurationListProps } from 'store/duplicateConfig/types';
import { ConfigAction } from 'core/types';

const CardComponent: React.FC<CardComponentProps> = ({ data, handleConfigActions }) => {
  const { title, createdBy, lastUpdated, fields } = data;
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [additionalMenuAnchor, setAdditionalMenuAnchor] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleAdditionalMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAdditionalMenuAnchor(event.currentTarget);
  };

  const handleAdditionalMenuClose = () => {
    setAdditionalMenuAnchor(null);
  };

  return (
    <StyledCard>
      <CardLabel>
        <Typography variant="h6">{title}</Typography>
        <IconButton onClick={handleMenuOpen}>
          <DotsIcon />
        </IconButton>
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            onClick={() => {
              handleConfigActions(ConfigAction.EDIT, data);
              setMenuAnchor(null);
            }}
            sx={{ display: 'flex', gap: 1 }}
          >
            <EditConfiguration />
            <Typography fontSize={14}>Edit</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleConfigActions(ConfigAction.DELETE, data);
              setMenuAnchor(null);
            }}
            sx={{ display: 'flex', gap: 1 }}
          >
            <DeleteIcon />
            <Typography fontSize={14} color="#FB4E6D">
              Delete
            </Typography>
          </MenuItem>
        </Menu>
      </CardLabel>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <TitleItem>Created:</TitleItem>
        <Typography variant="body2">{createdBy}</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <TitleItem>Last Updated:</TitleItem>
        <Typography variant="body2">{lastUpdated}</Typography>
      </Box>
      <Divider />
      <Box>
        <TitleItem variant="body2" color="#9DA1B5" gutterBottom>
          Prioritized fields and assigned weights
        </TitleItem>
        <Box display="flex" flexWrap="wrap" marginTop={1} gap={1}>
          {/* Display the first three fields */}
          {fields?.slice(0, 3).map((field, index) => (
            <FieldChip key={index} label={`${field.name} ${field.weight}`} />
          ))}

          {/* Additional fields menu */}
          {fields?.length > 3 && (
            <>
              <AdditionalItem
                label={`${fields.length - 3}`}
                icon={<AddIcon sx={{ fontSize: '15px' }} />}
                onClick={handleAdditionalMenuOpen}
              />
              <Menu
                anchorEl={additionalMenuAnchor}
                open={Boolean(additionalMenuAnchor)}
                onClose={handleAdditionalMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {fields.slice(3).map((field, index) => (
                  <MenuItem
                    key={index}
                    onClick={handleAdditionalMenuClose}
                    sx={{
                      backgroundColor: '#E3F4FF',
                      margin: '5px 10px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 2,
                    }}
                  >
                    <Typography fontSize={12}>{field.name}</Typography>
                    <Typography fontSize={12}>{field.weight}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </Box>
      </Box>
    </StyledCard>
  );
};

const ConfigurationList: React.FC<ConfigurationListProps> = ({ configurationListData, handleConfigActions }) => {
  const [openAdditional, setOpenAdditional] = useState<number>(0);

  return (
    <Box display="flex" flexWrap="wrap" width={'100%'} marginTop={3} gap={3}>
      {configurationListData?.map((item) => (
        <CardComponent
          key={item.id}
          data={item}
          openAdditional={openAdditional}
          setOpenAdditional={setOpenAdditional}
          handleConfigActions={handleConfigActions}
        />
      ))}
    </Box>
  );
};

export default ConfigurationList;
