import React from 'react';
import { Stack, Typography, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { useLazyDownloadEmailAttachmentQuery } from 'store/migration/api';
import AttachmentIcon from '@mui/icons-material/Attachment';
import PersonIcon from '@mui/icons-material/Person';
import MetadataSection from './components/MetadataSection';
import AttachmentSection from './components/AttachmentSection';
import UserTooltip from './components/UserTooltip';
import styled from '@mui/styles/styled';
import { format } from 'date-fns';
import { isHtml } from '../../../utils';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'black',
    minWidth: '300px',
    boxShadow: '2px 1px 15px -4px #000000',
  },
}));

interface IProps {
  email: any;
  persons: any[];
  expandedProp?: boolean;
}
const EmailCard = ({ email, persons, expandedProp = false }: IProps) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(expandedProp);

  const [downloadAttachment] = useLazyDownloadEmailAttachmentQuery();

  const handleAttachmentDownload = (id: string) => {
    downloadAttachment({
      messageId: email?.MessageSourceId,
      dataSource: email?.dataSourceId,
      attachmentId: id,
    });
  };

  const sender = persons?.find(
    (singlePerson) =>
      singlePerson.Id ===
      email?.Relations?.find((relation: any) => relation?.RelationType === 'FromAddress')?.RelationId,
  );

  const ccPersons =
    persons?.filter((person) =>
      email?.Relations?.filter((rel: any) => ['CcAddress', 'BccAddress'].includes(rel?.RelationType))
        .map((rel: any) => rel?.RelationId)
        .includes(person?.Id),
    ) || [];

  const toPersons =
    persons?.filter((person) =>
      email?.Relations?.filter((rel: any) => rel?.RelationType === 'ToAddress')
        .map((rel: any) => rel?.RelationId)
        .includes(person?.Id),
    ) || [];
  return (
    <Stack
      sx={{ backgroundColor: 'white', width: '100%', border: '1px solid', borderColor: '#00000015' }}
      py={1}
      px={2}
      gap={1}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <LightTooltip title={<UserTooltip user={sender} />} arrow>
            <Stack
              sx={{ width: '45px', height: '45px', borderRadius: '100%', backgroundColor: '#F6F8FB' }}
              alignItems="center"
              justifyContent="center"
            >
              <PersonIcon sx={{ color: '#00000050', fontSize: '28px' }} />
            </Stack>
          </LightTooltip>
          <Stack gap={0.1}>
            <Typography variant="labelMedium14">{sender?.Name}</Typography>
            {isExpanded && <MetadataSection cc={ccPersons} to={toPersons} from={[sender]} />}
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" gap={0.2}>
          {email?.Attachment && <AttachmentIcon sx={{ color: 'neutral.n300' }} />}
          <Typography variant="labelMedium14" color="neutral.n300">
            {email?.CreatedDate && format(new Date(email.CreatedDate), 'MMM dd, hh:mm aa')}
          </Typography>
        </Stack>
      </Stack>
      {isExpanded && email?.HtmlBody && isHtml(email?.HtmlBody) ? (
        <div
          style={{ border: '1px solid #00000010', padding: '5px' }}
          dangerouslySetInnerHTML={{ __html: email.HtmlBody }}
        />
      ) : (
        <Typography variant="p12" mx={1} noWrap={!isExpanded}>
          {email?.TextBody}
        </Typography>
      )}

      {isExpanded && (
        <AttachmentSection attachments={email?.Attachment || []} triggerDownload={handleAttachmentDownload} />
      )}
    </Stack>
  );
};

export default EmailCard;
