import theme from 'core/theme';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Handle, Position, NodeProps, useReactFlow } from 'reactflow';
import { useGraphRender } from 'store/graphRender/hooks';
import _ from 'lodash';
import { ReactComponent as CameraIcon } from 'assets/icons/graph/events/camera.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/graph/events/emailNode.svg';
import { ReactComponent as GenericIcon } from 'assets/icons/graph/events/generic.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/graph/events/phone.svg';
import { ReactComponent as TaskIcon } from 'assets/icons/graph/events/task.svg';
import { ReactComponent as NoteIcon } from 'assets/icons/graph/events/note.svg';
import { ReactComponent as ThreadIcon } from 'assets/icons/graph/events/thread.svg';
import { ToolBar } from './components/ToolBar';

import { Box } from '@mui/material';
import { useMigration } from 'store/migration/hooks';
import EmailTooltip from '../ToolTips/TasksTooltips/EmailTooltip';
import CallTooltip from '../ToolTips/TasksTooltips/CallTooltip';
import EventTooltip from '../ToolTips/EventsTooltips';
import ThreadTooltip from '../ToolTips/TasksTooltips/ThreadTooltip';

const iconStyle = { width: '60%', height: '60%', stroke: 'white' };

export const EventRecordNode: FC<NodeProps> = (props: NodeProps) => {
  const reactFlow = useReactFlow();
  const { data, id, selected } = props;
  const { Type, TaskSubtype, label } = data;

  const {
    data: { migrationId },
  } = useMigration();

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [scale, setScale] = useState(0)

  const eventType = useMemo(() => Type || TaskSubtype || label, [Type, TaskSubtype, label]);

  const { setSelectedNode, setSelectedNodeFromSearchBar } = useGraphRender();

  useEffect(() => {
    if (selected) {
      const node = reactFlow.getNode(id);
      const nodeTmp = _.cloneDeep(node);
      setSelectedNode(nodeTmp);
      setSelectedNodeFromSearchBar(nodeTmp);
    }
  }, [id, reactFlow, selected, setSelectedNode, setSelectedNodeFromSearchBar]);

  const HandlerStyle = { display: 'none' };

  const getBackgroundColor = useCallback((eventType: string) => {
    if (eventType === 'Call') {
      return theme.palette.violet.subtone1;
    } else if (eventType === 'Email') {
      return theme.palette.violet.subtone1;
    } else if (eventType === 'Task') {
      return theme.palette.blue.main;
    } else if (eventType === 'Meeting') {
      return theme.palette.green.main;
    } else if (eventType === 'Thread') {
      return theme.palette.violet.subtone1;
    } else if (eventType === 'Note') {
      return theme.palette.blue.subtone1;
    } else {
      return theme.palette.orange.n200;
    }
  }, []);

  const nodeColor = useMemo(() => getBackgroundColor(eventType), [eventType, getBackgroundColor]);

  const getIcon = useCallback(
    (eventType: string) => {
      if (eventType === 'Call') {
        return <PhoneIcon style={{ ...iconStyle, fill: nodeColor }} />;
      } else if (eventType === 'Email') {
        return <EmailIcon style={{ ...iconStyle, fill: nodeColor }} />;
      } else if (eventType === 'Meeting') {
        return <CameraIcon style={{ ...iconStyle, fill: nodeColor }} />;
      } else if (eventType === 'Task') {
        return <TaskIcon style={{ ...iconStyle, fill: nodeColor }} />;
      } else if (eventType === 'Thread') {
        return <ThreadIcon style={{ ...iconStyle, fill: nodeColor }} />;
      } else if (eventType === 'Note') {
        return <NoteIcon style={{ ...iconStyle, fill: nodeColor }} />;
      } else {
        return <GenericIcon style={{ ...iconStyle, fill: nodeColor }} />;
      }
    },
    [nodeColor],
  );

  const getTooltip = useCallback(
    (type: string) => {
      if (type === 'Email') return <EmailTooltip migrationId={migrationId} taskId={data.id} isOpen={showTooltip} />;
      if (type === 'Call') return <CallTooltip migrationId={migrationId} taskId={data.id} isOpen={showTooltip} />;
      if (type === 'Event') return <EventTooltip migrationId={migrationId} eventId={data.id} isOpen={showTooltip} />;
      if (type === 'Thread') return <ThreadTooltip migrationId={migrationId} thread={data} isOpen={showTooltip} />;
      return <></>;
    },
    [migrationId, data, showTooltip],
  );

  useEffect(() => {
    setScale(1.5)
    setTimeout(() => {
      setScale(1)
    },300)
  }, [])

  const tooltip = useMemo(() => getTooltip(eventType), [eventType, getTooltip]);

  const icon = useMemo(() => getIcon(eventType), [eventType, getIcon]);

  return (
    <Box
      sx={{
        backgroundColor: nodeColor,
        height: '100%',
        width: '100%',
        borderRadius: '100%',
        zIndex: 1,
        transition: 'transform 0.3s ease',
        transform: `scale(${scale})`,
        ...(showTooltip || selected
          ? {
              outline: `1px solid ${nodeColor}`,
              outlineOffset: '2px',
            }
          : {}),
      }}
      onMouseOver={() => setShowTooltip(true)}
      onMouseOut={(e) => {
        //@ts-expect-error
        if (e.relatedTarget === null || !e.currentTarget.contains(e.relatedTarget)) {
          setTimeout(() => setShowTooltip(false), 300);
        }
      }}
    >
      <Box
        width={'100%'}
        height={'100%'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {icon}
      </Box>
        <ToolBar nodeProps={props} renderLockUnlockWhiteEdgeButton />
        {tooltip}
        <Handle position={Position.Right} type="source" style={HandlerStyle} />
        <Handle position={Position.Left} type="target" style={HandlerStyle} />
    </Box>
  );
};
