import React from 'react';
import NoteCard from '../ActivityCard/NoteCard';
import InteractionCard from '../ActivityCard/InteractionCard';
import EventTaskCard from './EventTaskCard';

interface ActivityCardProps {
  activity: any;
  node: any;
  contact: any;
  handleClick: (id: string, activity?: any) => void;
}
const ActivityCard: React.FC<ActivityCardProps> = ({ activity, node, contact, handleClick }: ActivityCardProps) => {
  if (activity?.TaskSubtype === 'Task')
    return <EventTaskCard type="task" activity={activity} node={contact ?? node} handleClick={handleClick} />;
  if (activity?.EventSubtype === 'Event')
    return <EventTaskCard type="event" activity={activity} node={contact ?? node} handleClick={handleClick} />;
  if (activity?.EventSubtype === 'Call' || activity?.TaskSubtype === 'Call')
    return <InteractionCard type="call" activity={activity} node={contact ?? node} handleClick={handleClick} />;
  if (activity?.TaskSubtype === 'Email')
    return <InteractionCard type="email" activity={activity} node={contact ?? node} handleClick={handleClick} />;
  if (activity?.TaskSubtype === 'Thread')
    return (
      <InteractionCard type="email" isThread activity={activity} node={contact ?? node} handleClick={handleClick} />
    );

  return <></>;
};

export default ActivityCard;
