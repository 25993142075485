import React from 'react';
import { Stack, Typography, Avatar, CircularProgress, AvatarGroup, Tooltip } from '@mui/material';
import format from 'date-fns/format';
import { useGetMigrationTableDataByIdQuery } from 'store/migration/api';
import { NodeToolbar, Position } from 'reactflow';

interface IProps {
  migrationId: string;
  thread: any;
  isOpen?: boolean;
}
const ThreadTooltip = ({ thread, migrationId, isOpen = false }: IProps) => {
  const [tooltipVisible, setTooltipVisible] = React.useState<boolean>(false);

  const allPersonsIds = React.useMemo(() => {
    const allRelations = [
      ...thread.Relations,
      ...thread.ThreadElements.map((singleElement: any) => singleElement?.Relations || []).flat(),
    ];

    const usersIds = allRelations
      .filter((singleRelation) => singleRelation.RelationObjectType === 'User')
      .map((singleRelation) => singleRelation?.RelationId);
    const contactsIds = allRelations
      .filter((singleRelation) => singleRelation.RelationObjectType === 'Contact')
      .map((singleRelation) => singleRelation?.RelationId);
    return {
      //@ts-expect-error
      users: [...new Set(usersIds)],
      //@ts-expect-error
      contacts: [...new Set(contactsIds)],
    };
  }, [thread]);

  const { data: contacts = [], isFetching: contactsLoading } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'Contact',
      skip: 0,
      take: 0,
      conditions: [
        { field: 'Id', operator: 'IN', value: `(${allPersonsIds.contacts.map((id) => `'${id}'`).join(', ')})` },
      ],
    },
    {
      skip: !allPersonsIds.contacts.length,
    },
  );

  const { data: users = [], isFetching: usersLoading } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'User',
      skip: 0,
      take: 0,
      conditions: [
        { field: 'Id', operator: 'IN', value: `(${allPersonsIds.users.map((id) => `'${id}'`).join(', ')})` },
      ],
    },
    {
      skip: !allPersonsIds.users?.length,
    },
  );

  const lastInteractionFrom = React.useMemo(() => {
    const sortedElements = thread.ThreadElements.sort(
      (a: any, b: any) => new Date(b?.CreatedDate).getTime() - new Date(a?.CreatedDate).getTime(),
    );

    const lastInteraction = sortedElements.length
      ? sortedElements[0].Relations.find((singleRelation: any) => singleRelation.RelationType === 'FromAddress')
          ?.RelationId
      : thread.Relations.find((singleRelation: any) => singleRelation.RelationType === 'FromAddress')?.RelationId;

    return lastInteraction;
  }, [thread]);

  const allPersons = [...contacts, ...users];

  const personsLoading = contactsLoading || usersLoading;

  const isVisible = React.useMemo(() => tooltipVisible || isOpen, [tooltipVisible, isOpen]);
  return (
    <NodeToolbar
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
      isVisible={isVisible}
      position={Position.Left}
    >
      <Stack
        sx={{
          zIndex: 999,
          p: 1,
          backgroundColor: 'blue.dark',
          borderRadius: '5px',
          color: 'common.white',
          width: '230px',
        }}
        gap={0.5}
      >
        <Stack direction="row" alignItems="center" gap={1} mb={1}>
          <Typography variant="b12" color="common.white" >
            {thread?.Subject}
          </Typography>
          <Stack  sx={{ px: 1, backgroundColor: '#354970', borderRadius: '8px' }}>
            <Typography sx={{fontSize: '10px'}} color="common.white" >
              {thread?.ThreadCount}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Last reply from: </Typography>
          <Typography variant="labelMedium12">
            {allPersons.find((singlePerson) => singlePerson?.Id === lastInteractionFrom)?.FirstName +
              ' ' +
              allPersons.find((singlePerson) => singlePerson?.Id === lastInteractionFrom)?.LastName}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Received on: </Typography>
          <Typography variant="labelMedium12">
            {thread?.CreatedDate ? format(new Date(thread?.CreatedDate), 'MMM dd, h:mm aa') : null}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Recipients:</Typography>
          {personsLoading ? (
            <CircularProgress sx={{ color: 'white', width: '15px', height: '15px' }} />
          ) : (
            <Typography variant="labelMedium12">
              <AvatarGroup>
                {(allPersons || []).map((singleContact) => (
                  <Tooltip
                    key={`avatar_${singleContact?.Id}`}
                    title={singleContact?.Name ?? `${singleContact?.FirstName} ${singleContact?.LastName}`}
                  >
                    <Avatar sx={{ width: '18px', height: '18px', fontSize: '12px' }}>
                      {singleContact?.Name?.[0] ?? singleContact?.FirstName?.[0]}
                    </Avatar>
                  </Tooltip>
                ))}
              </AvatarGroup>
            </Typography>
          )}
        </Stack>
      </Stack>
    </NodeToolbar>
  );
};

export default ThreadTooltip;
