import { Box, Typography } from '@mui/material';
import theme from 'core/theme';
import { getConfidenceScoreColor } from 'core/utils';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Handle, Position, NodeProps, useReactFlow } from 'reactflow';
import { ComponentDesign } from '../../types';
import { useGraphRender } from 'store/graphRender/hooks';
import DuplicateNameLabel from './components/DuplicateNameLabel';
import { DuplicateToolTip } from '../ToolTips/DuplicateToolTip';
import { ToolBar } from './components/ToolBar';
import { useGraphRenderLayoutUtils } from '../../hooks/utils/useGraphRenderLayoutUtils';
import { ReactComponent as CollapseIcon } from 'assets/icons/graph/graphCollapsedIcon.svg';
import { ReactComponent as UnCollapseIcon } from 'assets/icons/graph/graphUncollapsedIcon.svg';
import _ from 'lodash';
import { GraphRenderView } from 'store/graphRender/types';

const edgeWithNameRender = ['Contact', 'Lead', 'Opportunity'];

export const RecordNode: FC<NodeProps> = (props: NodeProps) => {
  const reactFlow = useReactFlow();
  const { data, id, selected } = props;
  const { _collapsed } = data;
  const collapsed = _collapsed !== false;
  const confidence_score = data.confidence_score || 'undefined';
  const name = _.isEmpty(data.Name) ? `${data.FirstName || ''} ${data.LastName || ''}` : data.Name;
  const label = data.label || '';

  const [hoverState, setHoverState] = useState(false);
  const [timeoutRef, setTimeoutRef] = useState<any>();
  const [scale, setScale] = useState(0.5);
  const {
    data: {
      hoverNodeId,
      duplicatedNodeIds,
      renderDuplicateNodesOnHover,
      defaultDesign,
      customDesignMap,
      view: graphView,
      searching,
    },
    setSelectedNode,
    setSelectedNodeFromSearchBar,
    setHoverNodeId,
  } = useGraphRender();

  const { showNodeActivities } = useGraphRenderLayoutUtils();

  const handleShowEvents = useCallback(
    (isCollapsed: boolean) => {
      showNodeActivities(id, isCollapsed, { desiredEdgedLength: 45 });
    },
    [id, showNodeActivities],
  );

  useEffect(() => {
    if (selected) {
      const node = reactFlow.getNode(id);
      const nodeTmp = _.cloneDeep(node);
      setSelectedNode(nodeTmp);
      setSelectedNodeFromSearchBar(nodeTmp);
    }
  }, [id, reactFlow, selected, setSelectedNode, setSelectedNodeFromSearchBar]);

  useEffect(() => {
    setScale(1.5);
    setTimeout(() => {
      setScale(1);
    }, 300);
  }, []);

  const shouldRenderOnDuplicateHover = useMemo(() => {
    if (hoverNodeId != null && duplicatedNodeIds.includes(hoverNodeId) && !renderDuplicateNodesOnHover.includes(id)) {
      return false;
    }
    return true;
  }, [duplicatedNodeIds, hoverNodeId, id, renderDuplicateNodesOnHover]);

  let design = defaultDesign ? defaultDesign : ComponentDesign.STANDARD;

  if (customDesignMap[id]) {
    design = customDesignMap[id];
  }

  const confidenceScoreColor = useMemo(() => {
    let color = '';
    if (design === ComponentDesign.DUPLICATE_INACTIVE) {
      color = theme.palette.neutral.subtone2 || '';
    } else if (design === ComponentDesign.DUPLICATE_ACTIVE) {
      if (shouldRenderOnDuplicateHover) {
        color = theme.palette.orange.main;
      } else {
        color = theme.palette.neutral.subtone2 || '';
      }
    } else {
      color = getConfidenceScoreColor(confidence_score) || '';
    }
    return color;
  }, [confidence_score, design, shouldRenderOnDuplicateHover]);

  const HandlerStyle = { display: 'none' };

  const shouldRenderName = useMemo(
    () =>
      (design === ComponentDesign.STANDARD ||
        design === ComponentDesign.SEARCHING ||
        design === ComponentDesign.HIGHLIGH ||
        (design === ComponentDesign.DUPLICATE_ACTIVE && shouldRenderOnDuplicateHover)) &&
      edgeWithNameRender.includes(label),
    [design, label, shouldRenderOnDuplicateHover],
  );

  const shouldRenderDuplicateToolbar = useMemo(() => {
    return design === ComponentDesign.DUPLICATE_ACTIVE && hoverState === true;
  }, [design, hoverState]);

  const shouldRenderDuplicateHoverFlowEffect = useMemo(() => {
    return design === ComponentDesign.DUPLICATE_ACTIVE && hoverState === true;
  }, [design, hoverState]);

  const glowEffectCSS = useMemo(() => {
    if (shouldRenderDuplicateHoverFlowEffect) {
      return {
        '&::after': {
          content: "''",
          position: 'absolute',
          top: '-4px',
          left: '-4px',
          right: '-4px',
          bottom: '-4px',
          borderRadius: '50%',
          zIndex: -1,
          boxShadow: `0px 0px 5px 1.25px ${theme.palette.orange.subtone2}, 0px -0px 1.25px 5px ${theme.palette.orange.subtone2}`,
        },
      };
    }
    return {};
  }, [shouldRenderDuplicateHoverFlowEffect]);

  const getLabelComponent = useCallback(() => {
    if (design === ComponentDesign.DUPLICATE_ACTIVE) {
      return <DuplicateNameLabel name={name} nodeLabel={label} nodeId={id} />;
    }
    return <Typography variant="labelRegular10">{name}</Typography>;
  }, [design, name, label, id]);

  const handleOnHoverTrigger = useCallback(
    (value: boolean) => {
      if (graphView === GraphRenderView.LEADS) return;
      if (hoverState !== value) {
        if (timeoutRef) clearTimeout(timeoutRef);
        if (value === true) {
          setHoverState(value);
          setHoverNodeId(id);
        } else {
          const timeoutRef = setTimeout(() => {
            setHoverState(value);
            setHoverNodeId('');
          }, 100);
          setTimeoutRef(timeoutRef);
        }
      }
    },
    [graphView, hoverState, id, setHoverNodeId, timeoutRef],
  );

  return (
    <Box
      sx={{
        backgroundColor: confidenceScoreColor,
        height: '100%',
        width: '100%',
        borderRadius: '100%',
        transition: 'transform 0.3s ease',
        transform: `scale(${scale})`,
        zIndex: 1,
        ...(design === ComponentDesign.HIGHLIGH
          ? {
              outline: `5px solid ${confidenceScoreColor}80`,
              fontWeight: '600',
            }
          : {}),
        ...glowEffectCSS,
      }}
      onMouseLeave={() => handleOnHoverTrigger(false)}
      onMouseOver={() => handleOnHoverTrigger(true)}
      onMouseDownCapture={() => {
        if (selected) handleShowEvents(!collapsed);
      }}
    >
      <ToolBar nodeProps={props} renderLockUnlockWhiteEdgeButton />
     {/*  <motion.div
        animate={{ scale: [0, 1.5, 1] }}
        style={{
          backgroundColor: confidenceScoreColor,
          height: '100%',
          width: '100%',
          borderRadius: '100%',
          zIndex: 1,
          ...(design === ComponentDesign.HIGHLIGH
            ? {
                outline: `5px solid ${confidenceScoreColor}80`,
                fontWeight: '600',
              }
            : {}),
          ...glowEffectCSS,
        }}
        onMouseLeave={() => handleOnHoverTrigger(false)}
        onMouseOver={() => handleOnHoverTrigger(true)}
        onMouseDownCapture={() => {
          if (selected) handleShowEvents(!collapsed);
        }}
      > */}
        <Box
          position={'absolute'}
          bottom={collapsed ? '-50%' : '-60%'}
          left={collapsed ? '-8%' : '-10%'}
          sx={{ color: confidenceScoreColor, stroke: confidenceScoreColor }}
        >
          {collapsed === true ? (
            <CollapseIcon width={'6px'} height={'6px'} />
          ) : (
            <UnCollapseIcon width={'6px'} height={'6px'} />
          )}
        </Box>
        <DuplicateToolTip open={shouldRenderDuplicateToolbar} record={data} id={id} />
        <Handle position={Position.Right} type="source" style={HandlerStyle} />
        <Handle position={Position.Left} type="target" style={HandlerStyle} />
      {/* </motion.div> */}
      {shouldRenderName && (
        <Box
          position={'absolute'}
          top={'0'}
          left={'100%'}
          maxWidth={'150%'}
          sx={{
            color:
              searching && design !== ComponentDesign.HIGHLIGH && design !== ComponentDesign.STANDARD
                ? '#00000060'
                : theme.palette.blue.dark,
            lineHeight: '0.2',
          }}
        >
          {getLabelComponent()}
        </Box>
      )}
    </Box>
  );
};
