import { useMemo } from 'react';
import { getStraightPath, BaseEdge, EdgeLabelRenderer } from 'reactflow';

import { getEdgeParams } from '../../utils/EdgeUtils';
import { Box } from '@mui/material';
import { getEdgeConfidenceScoreColor, getConfidenceScoreColor } from 'core/utils';
import theme from 'core/theme';
import { ComponentDesign } from '../../types';
import { useGraphRender } from 'store/graphRender/hooks';
import { generateCustomEdgePath } from './utils';
import { useReactFlow } from 'reactflow';

export function FloatingEdge({ source, target, style, data, id }: any) {
  const reactFlow = useReactFlow();

  const sourceNode = reactFlow.getNode(source);
  const targetNode = reactFlow.getNode(target);

  const shouldRenderEdge = useMemo(() => {
    if (sourceNode && sourceNode.hidden === false && targetNode && targetNode.hidden === false) {
      return true;
    }
    return false;
  }, [sourceNode, targetNode]);
  const {
    data: { customDesignMap, defaultDesign, searching },
  } = useGraphRender();

  const design = useMemo(() => {
    let design = defaultDesign ? defaultDesign : ComponentDesign.STANDARD;
    if (customDesignMap[id]) {
      design = customDesignMap[id];
    }
    return design;
  }, [customDesignMap, defaultDesign, id]);

  const toBridge = useMemo(() => !!targetNode?.data.bridge, [targetNode?.data.bridge]);
  const confidenceScore = useMemo(() => targetNode?.data.confidence_score, [targetNode?.data.confidence_score]);

  const [confidenceEdgeScoreColor, confidenceScoreColor] = useMemo(() => {
    let confidenceEdgeScoreColor = getEdgeConfidenceScoreColor(confidenceScore);
    let confidenceScoreColor = getConfidenceScoreColor(confidenceScore);

    if (design === ComponentDesign.SEARCHING || design === ComponentDesign.DUPLICATE_INACTIVE) {
      confidenceEdgeScoreColor = theme.palette.neutral.subtone1;
      confidenceScoreColor = theme.palette.neutral.subtone1;
    } else if (design === ComponentDesign.HIGHLIGH) {
      confidenceEdgeScoreColor = theme.palette.primary.n200;
      confidenceScoreColor = theme.palette.primary.n200;
    }
    return [confidenceEdgeScoreColor, confidenceScoreColor];
  }, [confidenceScore, design]);

  const shouldRenderLabel = useMemo(
    () =>
      toBridge !== true && (design == ComponentDesign.STANDARD || (searching && design === ComponentDesign.HIGHLIGH)),
    [design, toBridge],
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  const sourceNodePosition = sourceNode.position;
  const targetNodePosition = targetNode.position;

  const { width: sourceWidth, height: sourceHeight } = sourceNode;
  const { width: targetWidth, height: targetHeight } = targetNode;

  const sourceCenterNodePosition = [
    sourceNodePosition.x + (sourceWidth || 0) / 2,
    sourceNodePosition.y + (sourceHeight || 0) / 2,
  ];
  const targetCenterNodePosition = [
    targetNodePosition.x + (targetWidth || 0) / 2,
    targetNodePosition.y + (targetHeight || 0) / 2,
  ];

  const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);

  // const path = generatePath(sx, sy, tx, ty);
  const [path, rad] = generateCustomEdgePath(
    sourceCenterNodePosition[0],
    sourceCenterNodePosition[1],
    targetCenterNodePosition[0],
    targetCenterNodePosition[1],
    sourceWidth || 0,
    targetWidth || 0,
  );

  const [_edgePath, labelX, labelY] = getStraightPath({
    sourceX: sx,
    sourceY: sy,
    targetX: tx,
    targetY: ty,
  });

  return (
    <>
      {shouldRenderEdge === true && (
        <>
          <BaseEdge
            interactionWidth={0}
            path={path}
            markerEnd={undefined}
            style={{
              ...style,
              strokeWidth: 2,
              fill: confidenceEdgeScoreColor,
              stroke: confidenceEdgeScoreColor,
              transform: `rotate(${rad}rad)`,
              transformOrigin: `${sourceCenterNodePosition[0]}px ${sourceCenterNodePosition[1]}px`,
            }}
          />
          {shouldRenderLabel && (
            <EdgeLabelRenderer>
              <Box
                style={{
                  position: 'absolute',
                  transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                  fontSize: 5,
                  pointerEvents: 'all',
                  borderBlockColor: confidenceScoreColor,
                  color: confidenceScoreColor,
                  backgroundColor: theme.palette.neutral.white,
                  paddingLeft: 4,
                  paddingRight: 4,
                  paddingTop: 1,
                  border: '1px solid',
                  borderRadius: '10px',
                }}
                className="nodrag nopan"
              >
                <span style={{ color: '#000' }}>{`${confidenceScore}%`}</span>
              </Box>
            </EdgeLabelRenderer>
          )}
        </>
      )}
    </>
  );
}
