import React from 'react';
import { Stack, SvgIconProps, SvgIconTypeMap, Typography } from '@mui/material';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

const elements = [
  {
    value: 'notifications',
    label: 'Notifications',
    icon: (props: SvgIconProps) => <NotificationsOutlinedIcon {...props} />,
  },
  {
    value: 'settings',
    label: 'Settings',
    icon: (props: SvgIconProps) => <SettingsOutlinedIcon {...props} />,
  },
];

interface IProps {
  selected: 'notifications' | 'settings';
  handleSelect: (entry: string) => void;
}

const Sidebar = ({ selected, handleSelect }: IProps) => {
  return (
    <Stack sx={{ height: '100%' }} gap={1} p={0.5}>
      {elements.map((entry) => (
        <Stack
          key={`sidebar_element_${entry.value}`}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          sx={{
            borderRadius: '8px',
            cursor: 'pointer',
            p: 1,
            ...(entry.value === selected
              ? {
                  border: '1px solid',
                  borderColor: 'primary.main',
                }
              : {}),
          }}
          onClick={() => handleSelect(entry.value)}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                ...(entry.value === selected
                  ? { border: '1px solid', borderColor: 'primary.main' }
                  : { backgroundColor: '#00000010' }),
              }}
            >
              {entry.icon({
                sx: {
                  fontSize: '18px',
                  color: entry.value === selected ? 'primary.main' : '',
                },
              })}
            </Stack>

            <Typography variant="p14" color={entry.value === selected ? 'primary.main' : ''}>
              {entry.label}
            </Typography>
          </Stack>
          <ChevronRightOutlinedIcon sx={{ fontSize: '16px', color: entry.value === selected ? 'primary.main' : '' }} />
        </Stack>
      ))}
    </Stack>
  );
};

export default Sidebar;
