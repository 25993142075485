import { ChangeEvent, FC, useEffect, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Box, Grid, IconButton, InputAdornment, Slider, TextField, Tooltip, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { ReactComponent as SearchIcon } from 'assets/icons/searchGray.svg';
import {
  AddAllButton,
  Container,
  DragContainer,
  DragContainerHeader,
  DragDropArea,
  DragFields,
  DragSideBarFields,
  DropContainer,
  DropIcon,
  SearchFields,
  StyledSwitch,
  WeightScore,
} from './ui';
import { ReactComponent as DragHandleIcon } from 'assets/icons/drag-handle.svg';
import { ReactComponent as Lock } from 'assets/icons/lock.svg';
import { ReactComponent as Forward } from 'assets/icons/forward.svg';
import { ReactComponent as LockOpen } from 'assets/icons/lockOpen.svg';
import { ReactComponent as OnIcon } from 'assets/icons/onIcon.svg';
import { VerticalDivider } from 'components/VerticalDivider';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import AddIcon from '@mui/icons-material/Add';
import {
  ConfigurationProps,
  DraggableFieldProps,
  DroppableAreaProps,
  DroppableFieldProps,
  Field,
} from 'store/duplicateConfig/types';
import { PrimaryButton } from 'components/ui';
// DND Types
const ItemType = {
  FIELD: 'field',
};

// Main Configuration Component
const Configuration: FC<ConfigurationProps> = ({
  availableFields = [],
  fetchedFields,
  setAvailableFields,
  onSelectField,
  totalWeight,
  editObjectFields,
}) => {
  const [selectSalesForce, setSelectSalesForce] = useState('');
  const [selectedFields, setSelectedFields] = useState<Field[]>([]);
  const [searchFields, setSearchFields] = useState<Field[]>([]);

  const weightDistribute = (fields: Field[]) => {
    const lockedFields = fields.filter((field) => field.locked);
    const unlockedFields = fields.filter((field) => !field.locked);

    const remainingWeight = totalWeight - lockedFields.reduce((sum, field) => sum + field.weight, 0);
    const meanWeight = (remainingWeight / unlockedFields.length).toFixed(2);
    const droppedFields = fields.map((field) =>
      field.locked
        ? field // Keep locked fields as is
        : { ...field, weight: Number(meanWeight) },
    );
    return droppedFields;
  };

  const DroppableField: FC<DroppableFieldProps> = ({
    field,
    index,
    moveField,
    handleWeightChange,
    handleLockToggle,
    handleLockSegmentation,
    handleRemoveField,
    weightErrorMessage,
    errorMessageIndex,
  }) => {
    const [, dragRef] = useDrag(() => ({
      type: ItemType.FIELD,
      item: field,
    }));

    const [, dropRef] = useDrop(() => ({
      accept: ItemType.FIELD,
      hover: (draggedItem: { index: number }) => {
        if (draggedItem.index !== index) {
          moveField(draggedItem.index, index);
          draggedItem.index = index;
        }
      },
    }));
    const MIN = 0;

    return (
      <Grid container ref={(node) => dragRef(dropRef(node))} className="card-header">
        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton sx={{ cursor: 'move' }}>
            <DragHandleIcon />
          </IconButton>
          <Typography color={'#1E232C'}>{field.name}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <VerticalDivider />
          <Tooltip title={errorMessageIndex === index ? weightErrorMessage : ''}>
            <Slider
              value={field.weight}
              onChange={(_, value) => handleWeightChange(index, value as number)}
              max={100}
              sx={{ maxWidth: '17rem' }}
              disabled={field.locked}
              min={MIN}
            />
          </Tooltip>
          <Tooltip title={errorMessageIndex === index ? weightErrorMessage : ''}>
            <TextField
              value={field.weight ? field.weight : ''}
              onChange={(e) => handleWeightChange(index, Number(e.target.value))}
              inputProps={{ min: 0, max: 100, type: 'number', 'aria-labelledby': 'input-slider' }}
              sx={{ maxWidth: '5.2rem' }}
              disabled={field.locked}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={3} sx={{ textAlign: 'center' }}>
          <StyledSwitch
            checked={field.locked}
            onChange={() => handleLockToggle(index)}
            inputProps={{ 'aria-label': 'Lock Weight' }}
            icon={
              <LockOpen
                style={{
                  width: '18px',
                  height: ' 18px',
                  background: '#fff',
                  margin: '8px 0px 0px 4px',
                  border: ' 1px',
                  borderRadius: ' 15px',
                  padding: ' 2px',
                }}
              />
            }
            checkedIcon={
              <Lock
                style={{
                  width: '18px',
                  height: ' 18px',
                  background: '#fff',
                  margin: '8px 0px 0px 2px',
                  border: ' 1px',
                  borderRadius: ' 15px',
                  padding: ' 2px',
                }}
              />
            }
          />

          <Tooltip
            title={!field.checkSegmentation ? 'Enable segmentation weight check' : 'Disable segmentation weight check'}
          >
            <StyledSwitch
              checked={field.checkSegmentation}
              onChange={() => handleLockSegmentation(index)}
              inputProps={{ 'aria-label': 'Lock Weight' }}
              icon={
                <OnIcon
                  style={{
                    width: '18px',
                    height: ' 18px',
                    background: '#fff',
                    margin: '8px 0px 0px 2px',
                    border: ' 1px',
                    borderRadius: ' 15px',
                    padding: ' 2px',
                  }}
                />
              }
              checkedIcon={
                <OnIcon
                  style={{
                    width: '18px',
                    height: ' 18px',
                    background: '#fff',
                    margin: '8px 0px 0px 2px',
                    border: ' 1px',
                    borderRadius: ' 15px',
                    padding: ' 2px',
                  }}
                />
              }
            />
          </Tooltip>

          <IconButton onClick={() => handleRemoveField(index)}>
            <GridCloseIcon sx={{ color: '#9DA1B5' }} />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const DroppableArea: FC<DroppableAreaProps> = ({ fields, setFields, setAvailableFields, availableFields }) => {
    const [weightErrorMessage, setWeightErrorMessage] = useState('');
    const [errorMessageIndex, setErrorMessageIndex] = useState<number | undefined>();
    const [{ isOver }, dropRef] = useDrop(() => ({
      accept: ItemType.FIELD,
      drop: (item: Field) => {
        if (!fields.some((f) => f.Id === item.Id)) {
          const newFields = [...fields, { ...item, weight: 0 }];

          setFields(weightDistribute(newFields));
          setAvailableFields((prev) => prev.filter((field) => field.name !== item.name));
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }));

    const handleWeightChange = (index: number, value: number) => {
      const updatedFields = [...fields];

      // Optionally, include the logic to calculate remaining weight
      const lockedWeight = updatedFields.reduce((sum, field) => (field.locked ? sum + field.weight : sum), 0);

      if (value <= totalWeight - lockedWeight) {
        // Update the selected field's weight
        updatedFields[index].weight = value;
      }

      const nonLockedFields = updatedFields.filter((field) => !field.locked);
      const nonLockedCount = nonLockedFields.length - 1;
      const remainingWeight = totalWeight - (lockedWeight + value);
      const weightPerField = nonLockedCount > 0 ? remainingWeight / nonLockedCount : 0;
      const weightPresent = totalWeight - lockedWeight;

      if (remainingWeight >= 0) {
        updatedFields.forEach((field) => {
          if (!field.locked && field.name !== updatedFields[index].name) {
            const nonNegativeWeight = Math.abs(Number(weightPerField.toFixed(2))); // Ensures it's non-negative
            field.weight = nonNegativeWeight;
          }
        });
        setFields(updatedFields);
      } else {
        setWeightErrorMessage(`The maximum available weight is ${weightPresent.toFixed(2)}.
              If you want to add a higher weight for 
              this field you can choose to lower the 
              values for locked fields.`);

        setErrorMessageIndex(index);
      }
    }; // 300ms debounce delay

    const handleLockToggle = (index: number) => {
      const updatedFields = [...fields];
      updatedFields[index].locked = !updatedFields[index].locked;
      setFields(updatedFields);
    };

    const handleLockSegmentation = (index: number) => {
      const updatedFields = [...fields];
      updatedFields[index].checkSegmentation = !updatedFields[index].checkSegmentation;
      setFields(updatedFields);
    };

    const handleRemoveField = (index: number) => {
      const updatedFields = fields.filter((_, i) => i !== index);
      setAvailableFields((prev) => [...prev, { ...fields[index], weight: 0, locked: false, checkSegmentation: false }]);
      setFields(weightDistribute(updatedFields));
    };

    const moveField = (dragIndex: number, hoverIndex: number) => {
      const updatedFields = [...fields];
      const [draggedField] = updatedFields.splice(dragIndex, 1);
      if (draggedField) {
        updatedFields.splice(hoverIndex, 0, draggedField);
        setFields(updatedFields);
      }
    };

    const dragContainer = { border: isOver ? '1px dashed #1554FF' : '', background: isOver ? '#DBE9FF' : '#F6F8FB' };

    const dragArea = { color: isOver ? '#1554FF' : '#CDD2DF' };

    const handleRemoveAllField = () => {
      const updatedFields = [...availableFields];

      fields?.forEach((item) => {
        updatedFields.splice(item?.index, 0, { ...item, weight: 0, locked: false });
      });
      setAvailableFields(updatedFields);
      setSearchFields(updatedFields);
      setFields([]);
    };

    return (
      <DragContainer ref={dropRef} sx={dragContainer}>
        {fields.length > 0 ? (
          <Box sx={{ width: '100%', height: '100%', color: '#898EA1' }}>
            <DragContainerHeader>
              <Box>
                <Typography fontSize={14}>Set field importance with the slider.</Typography>
                <Typography fontSize={14}>
                  The sum of the weights assigned for each field should not exceed 100.
                </Typography>
              </Box>
              <PrimaryButton variant="text" onClick={handleRemoveAllField}>
                {' '}
                Remove All{' '}
              </PrimaryButton>
            </DragContainerHeader>
            <WeightScore>
              <Typography fontSize={16}>Total weight scores</Typography>
              <Typography>{totalWeight}</Typography>
            </WeightScore>
            <DropContainer>
              {fields.map((field, index) => (
                <DroppableField
                  key={field?.name}
                  field={field}
                  index={index}
                  moveField={moveField}
                  handleWeightChange={handleWeightChange}
                  handleLockToggle={handleLockToggle}
                  handleLockSegmentation={handleLockSegmentation}
                  handleRemoveField={handleRemoveField}
                  weightErrorMessage={weightErrorMessage}
                  errorMessageIndex={errorMessageIndex}
                />
              ))}
            </DropContainer>
          </Box>
        ) : (
          <DragDropArea>
            <DropIcon>
              <AddIcon sx={dragArea} className="plus-icon" />
            </DropIcon>
            <Typography variant="h6">Drag and drop fields here</Typography>
          </DragDropArea>
        )}
      </DragContainer>
    );
  };

  // Draggable Field Component
  const DraggableField: FC<DraggableFieldProps> = ({ field, isScroll }) => {
    const [, dragRef] = useDrag(() => ({
      type: ItemType.FIELD,
      item: field,
    }));

    return (
      <Tooltip open={isScroll ? false : undefined} title={'Drag and drop the field you need'} arrow>
        <DragFields ref={dragRef}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton sx={{ cursor: 'move', p: 0 }}>
              <DragHandleIcon />
            </IconButton>
            <Typography variant="body1">{field.name}</Typography>
          </Box>
          <PlusIcon className="plus-icon" />
        </DragFields>
      </Tooltip>
    );
  };

  const AvailableFieldsArea: FC<{
    fields: Field[];
    setFields: React.Dispatch<React.SetStateAction<Field[]>>;
    setSelectedFields: React.Dispatch<React.SetStateAction<Field[]>>;
  }> = ({ fields, setFields, setSelectedFields }) => {
    const [isScroll, setIsScroll] = useState(false);
    const [{ isOver }, dropRef] = useDrop(() => ({
      accept: ItemType.FIELD,
      drop: (item: Field) => {
        if (!fields.some((f) => f.Id === item.Id)) {
          setFields((prev) => {
            // Create a copy of the array, modify it, and return the updated array
            const updatedFields = [...prev];
            updatedFields.splice(item?.index, 0, item); // Insert the item at the correct index
            return updatedFields;
          });
          /* eslint-disable react/prop-types */
          setSelectedFields((prev) => prev?.filter((field) => field?.name !== item.name));
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }));

    return (
      <DragSideBarFields
        ref={dropRef}
        sx={{ border: isOver ? '1px dashed #1554FF' : '', background: isOver ? '#DBE9FF' : '#F6F8FB' }}
        className="drag-field-container"
        onScroll={() => setIsScroll(true)}
      >
        {fields.map((field, index) => (
          <DraggableField key={index} field={field} ItemType={ItemType} isScroll={isScroll} />
        ))}
      </DragSideBarFields>
    );
  };

  const handleSearchField = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectSalesForce(event?.target.value);
    setSearchFields(availableFields?.filter((field) => field?.name.toLowerCase().includes(event?.target.value)));
  };

  useEffect(() => {
    onSelectField(selectedFields);
  }, [selectedFields]);

  const handleAllFields = () => {
    // Calculate mean weight
    const meanWeight = +(totalWeight / availableFields.length).toFixed(2);

    // Map through fields and update weights or lock status
    const updatedFields = availableFields.map((field) => {
      // Update field based on lock status
      return { ...field, weight: meanWeight };
    });

    // Set updated fields
    setSelectedFields(weightDistribute([...updatedFields, ...selectedFields]));
    setAvailableFields([]);
  };

  useEffect(() => {
    if (editObjectFields?.length) {
      setSelectedFields(
        editObjectFields.map((item, index) => {
          return {
            name: item.fieldName,
            weight: item?.weightValue,
            locked: item?.isLocked,
            checkSegmentation: item?.checkSegmentation,
            label: item?.fieldName,
            value: item?.fieldName,
            index: index,
            Id: item?.id,
          };
        }),
      );

      setAvailableFields(fetchedFields?.filter((item) => !editObjectFields?.some((field) => field?.id === item?.Id)));
    }
  }, [editObjectFields, fetchedFields]);

  useEffect(() => {
    setSearchFields(availableFields);
    setSelectSalesForce('');
  }, [availableFields]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Container sx={{ display: 'flex', padding: '0px', mt: 2, flexDirection: 'column', gap: '20px', height: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <Box sx={{ background: '#F6F8FB', padding: 2, height: '100%' }}>
              <Typography variant="h6">Prioritize the importance of fields</Typography>
              <Typography variant="caption" fontSize={14} sx={{ color: '#898EA1' }}>
                Drag fields to assign importance.
              </Typography>
              <SearchFields
                id="searchField"
                name="searchField"
                variant="outlined"
                placeholder="Find a field"
                fullWidth
                value={selectSalesForce}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                className=""
                onChange={handleSearchField}
                disabled={fetchedFields?.length === selectedFields?.length}
              />
              {fetchedFields?.length !== selectedFields?.length && (
                <AddAllButton variant="text" onClick={handleAllFields} startIcon={<Forward />}>
                  Add All Fields
                </AddAllButton>
              )}
              <AvailableFieldsArea
                fields={searchFields}
                setFields={setAvailableFields}
                setSelectedFields={setSelectedFields}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={9}>
            <DroppableArea
              fields={selectedFields}
              setFields={setSelectedFields}
              availableFields={searchFields}
              setAvailableFields={setAvailableFields}
            />
          </Grid>
        </Grid>
      </Container>
    </DndProvider>
  );
};

export default Configuration;
