import { FC, useState, useEffect } from 'react';
import { Avatar, Grid, IconButton, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';

import {
  updateUser as updateUserApi,
  deleteUser as deleteUserApi,
  resendInvite,
  cancelInvite,
  inactivateUser as inactivateUserApi,
  reactivateUser as reactivateUserApi,
} from 'http/user';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { TableFooter } from 'components/TableFooter';
import { InvitationStatus, UserRole, UserStatus } from 'core/types';
import { PrimaryButton, SecondaryButton } from 'components/ui';
import { CustomSelect } from 'components/CustomSelect';
import { USER_TENANT_ROLE_OPTIONS } from 'core/constants';
import { User, UserStatusCellProps } from 'store/user/types';
import { userSelector, useUser } from 'store/user/hooks';
import { RootState } from 'store/types';
import {
  Container,
  BaseCheckbox,
  ColumnSortedAscendingIcon,
  ColumnSortedDescendingIcon,
  ColumnUnsortedIcon,
  TitleContainer,
  ActiveButton,
  DeactiveButton,
  PendingInvitationButton,
  InvitationCancelledButton,
  HighlightName,
  HighlightEmail,
  AcceptedInvitationButton,
} from './ui';
import { AddNewUserModal, UserDetailsModal } from './components';
import InviteUserModal from './components/InviteUserModal/InviteUserModal';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import theme from 'core/theme';
import { ReactComponent as DotsIcon } from 'assets/icons/dots.svg';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useTenant } from 'store/tenant/hooks';

const TeamFooter: FC = () => {
  const { user, getUsers } = useUser();

  return (
    <TableFooter
      entity="user"
      pluralEntity="users"
      idProp="userId"
      hideDeleteButton={true}
      onDelete={() => Promise.resolve()}
      onSuccess={getUsers}
    />
  );
};

const UserRoleCell: FC<{ userId: number }> = ({ userId }) => {
  const { updateUser } = useUser();
  const user = useSelector((state: RootState) => userSelector(state, userId));

  return (
    <CustomSelect<UserRole>
      value={user?.tenantRole as UserRole}
      options={USER_TENANT_ROLE_OPTIONS}
      onSelect={async (value) => {
        if (!user?.userId) return;
        await updateUserApi(user.userId, { tenantRole: value });
        updateUser({ userId: user.userId, user: { tenantRole: value } });
      }}
    />
  );
};

const UserStatusCell: FC<UserStatusCellProps> = ({ invitationStatus, created_at_time, userStatus }) => {
  const invitationDetail = invitationStatus?.length ? invitationStatus : [];
  const status = invitationDetail?.[0]?.status;

  // Styling constants
  const pendingContainerStyle = { display: 'flex', alignItems: 'center' };
  const sentTextStyle = { marginLeft: 30 };

  const renderInvitationStatus = () => {
    switch (status) {
      case InvitationStatus.ACTIVE:
        return <ActiveButton>Active</ActiveButton>;
      case InvitationStatus.ACCEPTED:
        return <AcceptedInvitationButton>Invitation Accepted</AcceptedInvitationButton>;
      case InvitationStatus.INACTIVE:
        return <DeactiveButton>Deactivated</DeactiveButton>;
      case InvitationStatus.PENDING:
        return (
          <div style={pendingContainerStyle}>
            <PendingInvitationButton>Pending Invitation</PendingInvitationButton>
            <div style={sentTextStyle}>
              <p>
                Sent: <strong>{moment(created_at_time).fromNow()}</strong>
              </p>
            </div>
          </div>
        );
      case InvitationStatus.CANCELLED:
        return <InvitationCancelledButton>Invitation Cancelled</InvitationCancelledButton>;
      default:
        return <DeactiveButton>Deactivated</DeactiveButton>; // Fallback for undefined or unknown status
    }
  };

  const renderUserStatus = () => {
    return userStatus ? <ActiveButton>Active</ActiveButton> : <DeactiveButton>Deactivated</DeactiveButton>;
  };

  return (
    <div>
      {userStatus ? (
        renderUserStatus()
      ) : !status && userStatus ? (
        <DeactiveButton>Deactivated</DeactiveButton>
      ) : (
        renderInvitationStatus()
      )}
    </div>
  );
};
const Team: FC = () => {
  const [modalOpen, setModalOpened] = useState(false);
  const [inviteModalOpened, setInviteModalOpened] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(undefined);
  const { loading, error, users, getUsers } = useUser();
  const [inviteLoading, setInviteLoading] = useState(false);
  const { tenant, getTenants } = useTenant();

  const ActionStatusCell: FC<{ params: GridRenderCellParams<User> }> = ({ params }) => {
    const { userEmail, userId, tenantInvitation, userActive } = params.row;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const invitationDetail = tenantInvitation?.length ? tenantInvitation : [];
    const invitationStatus = invitationDetail?.[0]?.status;

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleResendInvite = async () => {
      setAnchorEl(null);
      const result = await Swal.fire({
        title: 'Resend Invitation',
        text: `Are you sure you want to resend invitation to ${userEmail}`,
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
      });
      if (result.isConfirmed) {
        setInviteLoading(true);
        try {
          await resendInvite(`${userId}/${invitationDetail?.[0]?.id}`)
            .then(() => {
              getUsers();
            })
            .finally(() => {
              Swal.fire({
                title: 'Resend Invitation',
                text: 'The invitation was successfully resend.',
                icon: 'success',
                confirmButtonText: 'Got it!',
              });
              setInviteLoading(false);
            });
        } catch (error) {
          setInviteLoading(false);
          Swal.fire({
            title: 'Resend Invitation',
            text: 'Failed to resend invitation!',
            icon: 'error',
            confirmButtonText: 'Got it!',
          });
        }
      }
    };

    const handleCancelInvitation = async () => {
      setAnchorEl(null);
      const result = await Swal.fire({
        title: 'Cancel Invitation',
        text: `Are you sure you want to cancel invitation for ${userEmail}`,
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
      });
      if (result.isConfirmed) {
        setInviteLoading(true);
        try {
          await cancelInvite(`${userId}/${invitationDetail?.[0]?.id}`)
            .then(() => {
              getUsers();
            })
            .finally(() => {
              Swal.fire({
                title: 'Invitation Cancelled',
                text: 'The invitation was successfully cancelled.',
                icon: 'success',
                confirmButtonText: 'Got it!',
              });
              setInviteLoading(false);
            });
        } catch (error) {
          Swal.fire({
            title: 'Invitation Cancelled',
            text: 'Failed to cancel invitation!',
            icon: 'success',
            confirmButtonText: 'Got it!',
          });
          setInviteLoading(false);
        }
      }
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const isDeactiveUser = !userActive && !invitationStatus;

    const handleStatusChangeUser = async (type: string) => {
      setAnchorEl(null);

      const result = await Swal.fire({
        title: `${type === UserStatus.INACTIVE ? 'Deactivate' : 'Activate'} User`,
        text: `Are you sure you want to ${type === UserStatus.INACTIVE ? 'deactivate' : 'activate'} user`,
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
      });
      if (result.isConfirmed) {
        setInviteLoading(true);
        try {
          const action = type === UserStatus.INACTIVE ? inactivateUserApi : reactivateUserApi;
          await action(userId)
            .then((respone) => {
              Swal.fire({
                title: `User ${type === UserStatus.INACTIVE ? 'Deactivated' : 'Activated'}`,
                text: `The user was successfully ${type === UserStatus.ACTIVE ? 'activated' : 'deactivated'}.`,
                icon: 'success',
                confirmButtonText: 'Got it!',
              });
              getUsers();
            })
            .finally(() => {
              setInviteLoading(false);
            });
        } catch (err) {
          console.error(err);
        }
      }
    };

    return !userActive && !isDeactiveUser ? (
      <div className="flex items-center justify-end w-full">
        {/* Three Dots Icon */}
        <IconButton aria-label="more" aria-controls="three-dots-menu" aria-haspopup="true" onClick={handleClick}>
          <DotsIcon />
        </IconButton>
        {/* Menu */}
        <Menu
          id="three-dots-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {/* Menu Items */}
          <MenuItem
            onClick={handleResendInvite}
            sx={{
              color: theme.palette.green.dark,
              padding: 3,
              margin: 1,
              border: '1px solid #05a302',
            }}
          >
            Resend Invitation
          </MenuItem>
          <MenuItem
            onClick={handleCancelInvitation}
            sx={{
              color: theme.palette.red.dark,
              padding: 3,
              margin: 1,
              border: '1px solid #BA1313',
            }}
            disabled={invitationStatus === InvitationStatus?.ACTIVE || invitationStatus === InvitationStatus?.CANCELLED}
          >
            Cancel Invitation
          </MenuItem>
        </Menu>
      </div>
    ) : (
      <div className="flex items-center justify-end w-full">
        {/* Three Dots Icon */}
        <IconButton aria-label="more" aria-controls="three-dots-menu" aria-haspopup="true" onClick={handleClick}>
          <DotsIcon />
        </IconButton>
        {/* Menu */}
        <Menu
          id="three-dots-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {/* Menu Items */}
          {userActive ? (
            <MenuItem
              onClick={() => handleStatusChangeUser(UserStatus.INACTIVE)}
              sx={{
                color: theme.palette.red.dark,
                padding: 3,
                margin: 1,
                border: '1px solid #BA1313',
              }}
            >
              Deactivate
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => handleStatusChangeUser(UserStatus.ACTIVE)}
              sx={{
                color: theme.palette.green.dark,
                padding: 3,
                margin: 1,
                border: '1px solid #05a302',
              }}
            >
              Activate
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  };

  const UserName: FC<{ params: GridRenderCellParams<User>; userName: string }> = ({ params, userName }) => {
    // Adapt params to onRowClick if necessary
    const handleClick = () => {
      const rowParams: GridRowParams = {
        ...params,
        columns: [], // Provide columns if required, or fetch them appropriately
      };
      onRowClick(rowParams);
    };

    return (
      <HighlightName onClick={handleClick}>
        <Avatar sx={{ width: 30, height: 30 }} src="/broken-image.jpg" />
        {userName}
      </HighlightName>
    );
  };
  const columns: GridColDef[] = [
    {
      field: 'userName',
      headerName: 'Name',
      flex: 1,
      renderCell: (params: GridRenderCellParams<User>) => <UserName params={params} userName={params.row.userName} />,
    },
    {
      field: 'userEmail',
      headerName: 'Email',
      flex: 1,
      renderCell: (params: GridRenderCellParams<User>) => <HighlightEmail>{params.row.userEmail}</HighlightEmail>,
    },
    {
      field: 'tenantRole',
      headerName: 'Role',
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => <UserRoleCell userId={params.row.userId} />,
    },
    {
      field: 'status',
      headerName: 'Stat',
      flex: 2,
      renderCell: (params: GridRenderCellParams<{ userUpdatedAt: string }>) => {
        return (
          <UserStatusCell
            invitationStatus={params.row.tenantInvitation}
            created_at_time={params?.row?.tenantInvitation[0]?.updatedAt}
            userStatus={params.row.userActive}
          />
        );
      },
    },

    {
      field: 'actions',
      headerName: '',
      flex: 2,
      sortable: false,
      renderCell: (params: GridRenderCellParams<User>) => <ActionStatusCell params={params} />,
      align: 'right',
    },
  ];

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTenants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleModal = () => setModalOpened((prevState) => !prevState);

  const inviteModal = () => setInviteModalOpened((prevState) => !prevState);

  const toggleDetails = () => {
    if (detailsOpen) setSelectedUserId(undefined);
    setDetailsOpen((prevState) => !prevState);
  };

  const toggleInvite = () => {
    setInviteModalOpened((prevState) => !prevState);
  };

  const onRowClick = (params: GridRowParams) => {
    setSelectedUserId(params.row.userId);
    toggleDetails();
  };

  const modifiedUserData = users?.length
    ? users?.map((user: User) => {
        const inviteStatus = user?.tenantInvitation[0]?.status?.toLowerCase().replace(/\s+/g, '');

        return {
          ...user,
          status: user?.userActive
            ? 'active'
            : !user?.tenantInvitation?.length
            ? 'deactivated'
            : inviteStatus === 'accepted'
            ? 'invitationaccepted'
            : inviteStatus === 'cancelled'
            ? 'invitationcancelled'
            : inviteStatus,
        };
      })
    : [];

  return (
    <Container position="relative">
      <Grid container spacing={2} sx={{ background: 'white', margin: '0' }}>
        <Grid item xs={12} sx={{ paddingRight: '16px' }}>
          <TitleContainer>
            <Typography variant="h3" sx={{ color: 'neutral.main' }}>
              Users
            </Typography>

            <TitleContainer>
              <SecondaryButton startIcon={<PlusIcon />} onClick={inviteModal}>
                Invite User
              </SecondaryButton>
              <PrimaryButton onClick={toggleModal}>Manage Teams</PrimaryButton>
            </TitleContainer>
          </TitleContainer>
        </Grid>

        <Grid item xs={12} sx={{ height: 480, width: '100%', padding: '16px' }} data-testid="team-table">
          <DataGrid
            rows={modifiedUserData}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 25, 50]}
            checkboxSelection
            disableSelectionOnClick
            headerHeight={40}
            rowHeight={64}
            loading={loading || inviteLoading}
            components={{
              Footer: TeamFooter,
              BaseCheckbox,
              ColumnSortedAscendingIcon,
              ColumnSortedDescendingIcon,
              ColumnUnsortedIcon,
            }}
            disableColumnMenu
            disableVirtualization
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            getRowId={(row: User) => row.userId as number}
          />
        </Grid>

        {!!error && (
          <Grid item xs={12}>
            <Typography variant="caption" color="red">
              {typeof error === 'string' ? error : 'Something went wrong!'}
            </Typography>
          </Grid>
        )}
      </Grid>

      <AddNewUserModal open={modalOpen} toggleOpen={toggleModal} />

      <UserDetailsModal open={detailsOpen} toggleOpen={toggleDetails} userId={selectedUserId} />

      <InviteUserModal tenant={tenant} open={inviteModalOpened} toggleOpen={toggleInvite} />
    </Container>
  );
};

export default Team;
