import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { verifyEmail as verifyEmailApi } from 'http/auth';
import { PUBLIC_ABS_ROUTE_PATHS } from 'core/constants';

const VerifyEmail = () => {
  const [status, setStatus] = useState('pending'); // Tracks verification status

  const location = useLocation(); // Get current URL
  const navigate = useNavigate();
  useEffect(() => {
    const verifyEmail = async () => {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');
      console.log('token ', token);
      if (!token) {
        setStatus('error');
        return;
      }

      try {
        const response = await verifyEmailApi({ token });
        console.log(9, response);
        navigate(PUBLIC_ABS_ROUTE_PATHS.signup3, { state: response });
      } catch (error) {
        console.error('Verification failed:', error);
        navigate(PUBLIC_ABS_ROUTE_PATHS.signup3, { state: { verified: false, message: 'Verification failed' } });

        setStatus('error');
      }
    };

    verifyEmail();
  }, [location, navigate]);

  return (
    <div>
      {status === 'pending' && <p>Verifying your email...</p>}
      {status === 'success' && <p>Email verified successfully! Redirecting to login...</p>}
      {status === 'error' && <p>Verification failed. Please try again or contact support.</p>}
    </div>
  );
};

export default VerifyEmail;
