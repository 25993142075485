import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import Filter from './components/Filter';
import PeriodSelector from './components/PeriodSelector';
import { chartOptions } from './utils';
import CustomTooltip from './components/CustomTooltip';

ChartJS.register(CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);

type BubbleChartEntry = {
  x: string;
  y: string;
  r: number;
};
interface EngagementHistoryChartProps {
  data: {
    yLabels: string[];
    xLabels: string[];
    calls: BubbleChartEntry[];
    emails: BubbleChartEntry[];
    meetings: BubbleChartEntry[];
  };
  getTooltipInfos: (
    id: string,
    type: 'email' | 'call' | 'meeting',
  ) => {
    title: string;
    type: string;
    attendee: string;
    date: string;
    to: string;
  };
}
const EngagamentHistoryChart = ({ data, getTooltipInfos }: EngagementHistoryChartProps) => {
  const [tooltipData, setTooltipData] = React.useState<any>(null);

  const { yLabels = [], xLabels = [], calls = [], emails = [], meetings = [] } = data;

  const renderTooltip = (position: { x: number; y: number }, index: number, id: string, hide = false) => {
    if (hide) {
      setTooltipData(null);
      return;
    }
    setTooltipData({
      position,
      dataIndex: index,
      id,
    });
  };
  const chartData = React.useMemo<ChartData<'bubble'>>(
    () => ({
      yLabels: yLabels,
      datasets: [
        {
          label: 'Emails',
          //@ts-ignore
          data: emails,
          backgroundColor: '#1554FF',
        },
        {
          label: 'Calls',
          //@ts-ignore
          data: calls,
          backgroundColor: '#00CEDB',
        },
        {
          label: 'Meetings',
          //@ts-ignore
          data: meetings,
          backgroundColor: '#AA00FF',
        },
      ],
    }),
    [calls, emails, meetings, yLabels],
  );

  return (
    <Box p={1} bgcolor={'#F6F8FB'}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="labelMedium12" color="neutral.n400">
          Engagement history {'(last year)'}
        </Typography>
        <Filter />
      </Stack>
      <PeriodSelector />
      <Box sx={{ height: '150px', width: '100%', position: 'relative', overflow: 'visible' }}>
        <Bubble options={chartOptions(yLabels, xLabels, renderTooltip)} data={chartData} />
        {tooltipData && (
          <CustomTooltip
            position={tooltipData.position}
            // @ts-ignore
            type={['email', 'call', 'meeting'][tooltipData?.dataIndex] ?? 'email'}
            //@ts-ignore
            data={getTooltipInfos(tooltipData.id, ['email', 'call', 'meeting'][tooltipData?.dataIndex])}
          />
        )}
      </Box>
    </Box>
  );
};

export default EngagamentHistoryChart;
