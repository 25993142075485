import { FC, useEffect, useState } from 'react';
import { FormHelperText, Typography, TextField, Box, Divider, FormControlLabel, Checkbox, Link } from '@mui/material';
import { PUBLIC_ABS_ROUTE_PATHS } from 'core/constants';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ReactComponent as SalesforceIcon } from 'assets/icons/salesforce.svg';
import { Form, LoadingButton, SecondaryLoadingButton } from 'components/ui';
import { useAuth } from 'store/auth/hooks';
import { AuthLayout } from 'components/AuthLayout';
import { store } from 'store/index';
import { resetApp } from 'store/actions';
import { requestIntegrationAuth } from 'http/auth';
import { openAuthWindow } from 'pages/IntegrationRedirect/popup';
import { useSnackbar } from 'notistack';
import { ReactComponent as BoxChecked } from 'assets/icons/boxChecked.svg';
import { ReactComponent as BoxUnchecked } from 'assets/icons/boxUnchecked.svg';
import { saveToLocalStorage, loadFromLocalStorage } from './utils';

interface FormValues {
  firstName: string;
  lastName: string;
  company: string;
  jobTitle: string;
  agree: boolean;
}

const formSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  company: yup.string(), // Optional field
  jobTitle: yup.string(), // Optional field
  agree: yup.boolean().oneOf([true], 'You need to agree our terms & conditions'),
});

const Signup: FC = () => {
  const { loading, error, setError, setSuccess, loginWithAccessToken } = useAuth();
  const [initialData, setInitialData] = useState<FormValues>({
    firstName: '',
    lastName: '',
    company: '',
    jobTitle: '',
    agree: true,
  });

  const snackbar = useSnackbar();
  const navigate = useNavigate();
  useEffect(() => {
    const bc = new BroadcastChannel('integration-events');
    bc.onmessage = (event) => {
      if (event.data.type === 'auth' && event.data.data.accessToken) {
        loginWithAccessToken(event.data.data.accessToken);
      }
      if (event.data.type === 'error' && event.data.data) {
        setError(event.data.data);
        snackbar.enqueueSnackbar(event.data.data, { variant: 'error' });
      }
    };
    return () => {
      bc.close();
    };
  }, [loginWithAccessToken, setError, snackbar]);
  const handleSignInWithSalesforce = () => {
    const getOAuthUrl = async () => {
      const oauthUrl = await requestIntegrationAuth('salesforce');
      return oauthUrl;
    };
    getOAuthUrl().then((url) => {
      openAuthWindow(url, 'authpopup');
    });
  };

  useEffect(() => {
    const data = loadFromLocalStorage('signupData');
    if (data) {
      setInitialData({
        firstName: data.firstName,
        lastName: data.lastName,
        company: data.company,
        jobTitle: data.jobTitle,
        agree: true,
      });
    }
    setError(false);
    setSuccess(false);
    store.dispatch(resetApp());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values: FormValues) => {
    saveToLocalStorage('signupData', {
      firstName: values.firstName,
      lastName: values.lastName,
      company: values.company,
      jobTitle: values.jobTitle,
    });
    navigate(PUBLIC_ABS_ROUTE_PATHS.signup2);
  };

  return (
    <AuthLayout backButtonEnabled={true} currentStep={2} totalSteps={4}>
      <Box>
        <Typography variant="h2" marginBottom={4}>
          Join Us!
        </Typography>
        <Typography
          sx={{ fontSize: 12, color: 'neutral.n400' }}
          marginBottom={4}
          variant="labelRegular12"
          component="p"
        >
          Fill in the registration data. It will take a couple of minutes.
          <br />
          All you need is an e-mail
        </Typography>
        <Formik initialValues={initialData} enableReinitialize={true} validationSchema={formSchema} onSubmit={onSubmit}>
          {({ values, isValid, errors, touched, handleChange, handleSubmit, handleBlur }) => (
            <Form noValidate>
              <Box>
                {/* First Name and Last Name */}
                <Box display="flex" gap={2} flexWrap="wrap" marginBottom={3}>
                  <Box flex="1">
                    <Typography variant="body2" sx={{ fontSize: 12 }} gutterBottom>
                      First Name
                      <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                      id="firstName"
                      name="firstName"
                      placeholder="Enter first name"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      fullWidth
                      variant="outlined"
                    />
                  </Box>
                  <Box flex="1">
                    <Typography variant="body2" sx={{ fontSize: 12 }} gutterBottom>
                      Last Name
                      <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                      id="lastName"
                      name="lastName"
                      placeholder="Enter last name"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      fullWidth
                      variant="outlined"
                    />
                  </Box>
                </Box>

                {/* Company Name */}
                <Box marginBottom={3}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="body2" sx={{ fontSize: 12 }} gutterBottom>
                      Company Name
                    </Typography>
                  </Box>
                  <TextField
                    id="company"
                    name="company"
                    placeholder="Enter company name"
                    value={values.company}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    variant="outlined"
                  />
                </Box>

                {/* Job Title */}
                <Box marginBottom={3}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="body2" sx={{ fontSize: 12 }} gutterBottom>
                      Job Title
                    </Typography>
                  </Box>
                  <TextField
                    id="jobTitle"
                    name="jobTitle"
                    placeholder="Enter job title"
                    value={values.jobTitle}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    variant="outlined"
                  />
                </Box>
              </Box>
              <FormControlLabel
                sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}
                control={
                  <Checkbox
                    id="agree"
                    name="agree"
                    checked={values.agree}
                    onChange={handleChange}
                    icon={<BoxUnchecked />}
                    checkedIcon={<BoxChecked />}
                  />
                }
                label={
                  <Typography variant="p12" sx={{ color: 'neutral.n400' }}>
                    I agree to{' '}
                    <Link
                      href="/terms-and-conditions"
                      target="_blank"
                      rel="noopener"
                      underline="none"
                      sx={{ display: 'inline-flex', alignItems: 'center' }}
                    >
                      terms{' '}
                      <Typography component="span" variant="p12" sx={{ color: 'gray', margin: '0 4px' }}>
                        &
                      </Typography>{' '}
                      conditions
                    </Link>
                  </Typography>
                }
              />
              {touched.agree && errors.agree && (
                <FormHelperText error sx={{ marginTop: 1 }}>
                  {errors.agree}
                </FormHelperText>
              )}
              <LoadingButton
                loading={loading}
                disabled={!isValid}
                sx={{ marginTop: 4 }}
                type="submit"
                onClick={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                Next
              </LoadingButton>

              {!!error && (
                <FormHelperText sx={{ color: 'red.main', textAlign: 'center', marginTop: 2 }}>
                  {typeof error === 'string' ? error : 'Something went wrong!'}
                </FormHelperText>
              )}

              <Box marginTop="32px">
                <Divider>or</Divider>
              </Box>

              <SecondaryLoadingButton
                onClick={handleSignInWithSalesforce}
                variant="outlined"
                sx={{ marginTop: 3 }}
                endIcon={<SalesforceIcon />}
              >
                Sign Up with Salesforce
              </SecondaryLoadingButton>
            </Form>
          )}
        </Formik>
      </Box>
    </AuthLayout>
  );
};

export default Signup;
