import React from 'react';
import { Stack, Typography, Divider } from '@mui/material';
import format from 'date-fns/format';
import { NodeToolbar, Position } from 'reactflow';
import ErrorIcon from '@mui/icons-material/Error';

interface IProps {
  isOpen?: boolean;
  type: 'year' | 'month' | 'day' | 'week';
  activities: any[];
}
const BridgeTooltip = ({ isOpen = false, type, activities }: IProps) => {
  const [tooltipVisible, setTooltipVisible] = React.useState<boolean>(false);

  const isVisible = React.useMemo(() => tooltipVisible || isOpen, [tooltipVisible, isOpen]);

  const year = React.useMemo(
    () => (type === 'year' && activities[0]?.CreatedDate ? format(new Date(activities[0]?.CreatedDate), 'MMMM') : null),
    [activities, type],
  );

  const activitiesByMonth = React.useMemo(() => {
    if (!activities?.length) return {};
    return activities.reduce((acc, activity) => {
       const month = activity?.CreatedDate ? format(new Date(activity.CreatedDate), 'MMMM') : null;
      if (!month) return acc;
      const eventType = activity.Type || activity.TaskSubtype || activity.label;
      if (!acc[month])
        return {
          ...acc,
          [month]: {
            [eventType]: 1,
          },
        };

      if (acc[month] && !acc[month][eventType])
        return {
          ...acc,
          [month]: {
            ...acc[month],
            [eventType]: 1,
          },
        };
      return {
        ...acc,
        [month]: {
          ...acc[month],
          [eventType]: acc[month][eventType] + 1,
        },
      };
    }, {});
  }, [activities]);
  return (
    <NodeToolbar
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
      isVisible={isVisible}
      position={Position.Left}
    >
      <Stack
        sx={{
          zIndex: 999,
          p: 1,
          backgroundColor: 'blue.dark',
          borderRadius: '5px',
          color: 'common.white',
          width: '230px',
        }}
        gap={0.5}
      >
        <Typography variant="b12" color="common.white" mb={1}>
          {type === 'year' ? year : ''}
          {type === 'month' && activities[0]?.CreatedDate ? format(new Date(activities[0].CreatedDate), 'MMMM yyyy') : ''}
        </Typography>
        {type === 'year' && (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="p12" color="neutral.n300">
                Total Activities:
              </Typography>
              <Typography variant="labelMedium12">{activities.length}</Typography>
            </Stack>

            <Divider sx={{ height: '1px', borderColor: 'neutral.n400' }} />
          </>
        )}

        {Object.keys(activitiesByMonth).map((month) => (
          <Stack direction="column" gap={0.5} key={`month_${month}`}>
            {type === 'year' && <Typography variant="labelMedium12">{month}</Typography>}
            {Object.keys(activitiesByMonth[month]).map((eventType) => (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                key={`eventType_${eventType}_for_${month}`}
              >
                <Typography sx={{ fontSize: '10px' }} color="neutral.n300">
                  {eventType}
                </Typography>
                <Typography variant="labelMedium12">{activitiesByMonth[month][eventType]}</Typography>
              </Stack>
            ))}
            <Stack direction="row" alignItems="center" justifyContent="space-between" mt={0.5}>
              <Typography sx={{ fontSize: '10px' }} color="neutral.n300">
                Total Activities:
              </Typography>
              <Typography variant="labelMedium12">
                {(Object.values(activitiesByMonth[month]).reduce((acc: any, curr: any) => acc + curr, 0) as string) ||
                  ''}
              </Typography>
            </Stack>
            <Divider sx={{ height: '1px', borderColor: 'neutral.n400' }} />
          </Stack>
        ))}

        <Stack direction="row" alignItems="center" gap={1}>
          <ErrorIcon sx={{ fontSize: '14px', color: 'white' }} />
          <Typography variant="p12">Click a node to zoom in </Typography>
        </Stack>
      </Stack>
    </NodeToolbar>
  );
};

export default BridgeTooltip;
