import _ from 'lodash';
import { useCallback } from 'react';
import { MarkerType } from 'reactflow';
import { FormattedEdge, GraphObject } from 'store/graphData/types';
import { useGraphRender } from 'store/graphRender/hooks';
import { BridgeNode, BridgeNodeTypeEnum } from '../../classes/BridgeNode';
import { NodeRecordTypeEnum, RecordNode } from '../../classes/RecordNode';
import { Edge, EdgeType } from '../../classes/Edge';
import { getEventTypeClassification } from '../../utils/EnumerationUtils';
import {
  nodeFilterFn,
  nodePostProcessingFn,
  edgeFilterFn,
  edgePostProcessingFn,
} from '../../utils/ComponentPostProcessing/PostProcessing.Interface';
import { format, getWeekOfMonth } from 'date-fns';
import { useLazyGetEmailsDataByTasksIdQuery } from 'store/migration/api';
import { useMigration } from 'store/migration/hooks';

export const useGraphRenderDataHandlerUtils = () => {
  const {
    setRenderComponents,
    data: { renderNodes, renderEdges, nodeHashMap, edgesHashMap },
    addToEdgesHashMap,
    addToNodesHashMap,
  } = useGraphRender();

  const {
    data: { migrationId },
  } = useMigration();

  const [getEmailsData] = useLazyGetEmailsDataByTasksIdQuery();

  const _getNodeTypeByLabel = useCallback((label: string) => {
    switch (label) {
      case 'Account':
        return NodeRecordTypeEnum.AccountNode;
      case 'Event':
        return NodeRecordTypeEnum.EventRecordNode;
      case 'Task':
        return NodeRecordTypeEnum.EventRecordNode;
      default:
        return NodeRecordTypeEnum.RecordNode;
    }
  }, []);

  const _returnNodeObject = useCallback(
    (node: GraphObject, defaultHidden?: boolean) => {
      const nodeProperties = { ...node };
      const label = node.label.split('.')[1];
      const type = _getNodeTypeByLabel(label);
      const draggable = label === 'Account' ? false : true;

      let hidden;
      if (defaultHidden !== undefined) {
        hidden = defaultHidden;
      } else {
        hidden = label === 'Account' ? false : true;
      }
      const nodeTmp = new RecordNode(node.id, node.label, {
        data: nodeProperties,
        properties: { hidden, draggable, type },
      });

      return { data: nodeTmp.data, properties: nodeTmp.properties };
    },
    [_getNodeTypeByLabel],
  );

  const _getFinalNodes = useCallback((nodeHashMap: any) => {
    const finalNodes = Object.keys(nodeHashMap).map((key) => {
      if (!nodeHashMap[key]) return nodeHashMap[key];
      const id = nodeHashMap[key].data.id;
      const data = nodeHashMap[key].data;
      const properties = nodeHashMap[key].properties;
      const labelComponents = data.label.split('.');
      const label = labelComponents[1] ? labelComponents[1] : labelComponents[0];
      data.id = data.id.split('.')[1];
      return {
        id: id,
        data: { ...data, label: label },
        position: { x: 0, y: 0 },
        ...properties,
      };
    });

    const finalNodeHashMap: any = {};
    finalNodes.forEach((node) => node?.id && (finalNodeHashMap[node.id] = _.cloneDeep(node)));
    return finalNodeHashMap;
  }, []);

  const _getFinalEdges = useCallback((edgesHashMap: any) => {
    const finalEdges = Object.keys(edgesHashMap).map((key) => {
      const edge = edgesHashMap[key];
      return {
        id: edge.data.id,
        type: edge.data.type || 'floating',
        markerEnd: {
          type: MarkerType.Arrow,
        },
        ...edge.properties,
        data: { ...edge.data },
      };
    });

    const finalEdgeHashMap: any = {};
    finalEdges.forEach((edge) => (finalEdgeHashMap[edge.id] = _.cloneDeep(edge)));
    return finalEdgeHashMap;
  }, []);

  const _processPaths = useCallback(
    (paths: GraphObject[][], hideExtendedNodes: boolean | undefined, edgesHashMap: any, nodeHashMap: any) => {
      paths.forEach((path, pathsIndex) => {
        path.forEach((object, objectIndex) => {
          if (object.relationship) {
            if (edgesHashMap[object.id] === undefined) {
              const edge = { ...object };
              const source = paths[pathsIndex][objectIndex - 1].id;
              const target = paths[pathsIndex][objectIndex + 1].id;
              edgesHashMap[object.id] = {
                data: { ...edge },
                properties: { source, target, hidden: hideExtendedNodes === false ? false : true },
              };
            }
          } else {
            const nodePayload = _returnNodeObject(object, hideExtendedNodes === false ? false : undefined);
            nodeHashMap[object.id] = nodePayload;
          }
        });
      });
    },
    [_returnNodeObject],
  );
  const _getThreadsNodes = useCallback(
    async (edgesHashMap: any, nodeHashMap: any) => {
      const prefix = Object.keys(nodeHashMap)?.[0]?.split('.')?.[0];
      const emailsNodes = [
        ...Object.values(nodeHashMap).filter((singleNode: any) => singleNode?.data?.TaskSubtype === 'Email'),
      ];
      if (!migrationId) return;

      const res = await getEmailsData({
        migrationId,
        tasksIds: emailsNodes?.map((node: any) => node?.data?.id?.split('.')[1]) || [],
      });

      const data = res.data || [];

      const emailDetailsWithThread = data.map((singleEmail) => {
        const threadCount = data.reduce((acc, curr) => {
          if (
            singleEmail.ThreadIdentifier === curr.ThreadIdentifier &&
            !!curr.ThreadIdentifier &&
            singleEmail.Id !== curr.Id
          )
            return acc + 1;
          return acc;
        }, 0);
        return !threadCount
          ? singleEmail
          : {
              ...singleEmail,
              HasThread: true,
              ThreadCount: threadCount,
            };
      });
      //const noThreadsEmails = emailDetailsWithThread.filter((singleEmail) => !singleEmail.HasThread);
      const threads = [
        //@ts-expect-error
        ...new Set(
          emailDetailsWithThread
            .filter((singleEmail) => !!singleEmail.HasThread)
            .map((singleEmail) => singleEmail.ThreadIdentifier),
        ),
      ];

      const finalThreads = threads.map((threadId) => {
        const emails = emailDetailsWithThread.filter((e) => e.ThreadIdentifier === threadId);
        const mainEmail = emails.sort(
          (a, b) => new Date(a?.CreatedDate || 0).getTime() - new Date(b?.CreatedDate || 0).getTime(),
        )[0];
        return {
          ...mainEmail,
          ThreadElements: emails.filter((singleEmail) => singleEmail.Id !== mainEmail.Id),
          TaskSubtype: 'Thread',
        };
      });

      const nodesToDelete = finalThreads
        .map((emailThread) => emailThread.ThreadElements)
        .flat()
        .map((email) => `${prefix}.${email.ActivityId}`);

      nodesToDelete.forEach((id) => delete nodeHashMap[id]);

      finalThreads.forEach((email) => {
        const id = email.ActivityId;
        nodeHashMap[`${prefix}.${id}`].data = {
          ...nodeHashMap[`${prefix}.${id}`].data,
          ...email,
          label: `${prefix}.Task`,
        };
      });

      Object.keys(edgesHashMap).forEach((key) => {
        const edge = edgesHashMap[key];
        if (nodesToDelete.includes(edge.properties.target)) {
          delete edgesHashMap[key];
        }
        if (nodesToDelete.includes(edge.properties.source)) {
          delete edgesHashMap[key];
        }
      });

      finalThreads.forEach((singleThread) => {
        const id = singleThread.ActivityId;
        const allContacts = [
          // @ts-expect-error
          ...new Set([
            ...(singleThread?.Relations?.map((singleRelation: any) => singleRelation?.RelationId) || []),
            ...(singleThread?.ThreadElements?.map((thread: any) =>
              thread?.Relations?.map((singleRelation: any) => singleRelation?.RelationId),
            ).flat() || []),
          ]),
        ];
        let count = 0;
        if (nodeHashMap[`${prefix}.${id}`]) {
          const node = { ...nodeHashMap[`${prefix}.${id}`] };
          allContacts.forEach((contactId) => {
            if (!nodeHashMap[`${prefix}.${contactId}`]) return;
            const edgeId = `${prefix}.Who.Task.${prefix}.${id}.Contact.${prefix}.${contactId}`;
            if (!edgesHashMap[edgeId]) {
              count += 1;
              const newEdgeId = `${prefix}.Who.Task.${prefix}.${id}_${count}.Contact.${prefix}.${contactId}`;
              const newNodeId = `${node.data.id}_${count}`;

              const newEdge = {
                data: {
                  id: newEdgeId,
                  label: `${prefix}.Task.Who.Contact`,
                  relationship: 'Who',
                  type: 'EventEdge',
                  metaData: {
                    directConnection: true,
                  },
                },
                properties: {
                  hidden: true,
                  source: `${prefix}.${contactId}`,
                  target: newNodeId,
                },
              };
              edgesHashMap[newEdgeId] = newEdge;

              const newNode = _.cloneDeep(node);

              newNode.data.id = newNodeId;

              nodeHashMap[newNodeId] = newNode;
            }
          });
        }
      });
    },
    [migrationId, getEmailsData],
  );

  const _addEventBridgeNodeAndEdges = useCallback(
    ({ fromId, toId, edge, nodeHashMap, edgesToAdd, bridgeEdges }: any) => {
      const eventNode = nodeHashMap[toId];
      const eventTypeClassification = getEventTypeClassification(
        eventNode.data.Type || eventNode.data.TaskSubtype || eventNode.data.EventSubtype,
      );
      const bridgeId = `${fromId}-EventBridge-${eventTypeClassification}`;

      // Create the Bridge Node
      if (nodeHashMap[bridgeId] === undefined) {
        const newBridgeNode = new BridgeNode(bridgeId, bridgeId, {
          data: { connectionsCount: 1 },
          bridgeLabel: eventTypeClassification,
          properties: { hidden: true, draggable: true, type: BridgeNodeTypeEnum.EventBridgeNode },
          metaData: { eventType: eventTypeClassification },
        });
        nodeHashMap[bridgeId] = newBridgeNode;
      } else {
        nodeHashMap[bridgeId].data.connectionsCount += 1;
      }

      // Create the Edge from the Record Node to the Bridge Node
      if (!bridgeEdges.has(`${fromId}-${bridgeId}`)) {
        const newFromEdge = new Edge(`${fromId}-${bridgeId}-edge-bridge`, EdgeType.EventEdge, fromId, bridgeId, {
          hidden: true,
        });
        edgesToAdd.push(newFromEdge);
        bridgeEdges.add(`${fromId}-${bridgeId}`);
      }

      // Create the Edge from the Bridge Node to the Event Node
      if (!bridgeEdges.has(`${bridgeId}-${toId}`)) {
        const newToEdge = new Edge(edge.data.id + '_2', EdgeType.EventEdge, bridgeId, toId, { hidden: true });
        edgesToAdd.push(newToEdge);
        bridgeEdges.add(`${bridgeId}-${toId}`);
      }

      edge.data.type = EdgeType.EventEdge;
      edge.data.metaData = { directConnection: true };
    },
    [],
  );
  const _addEventBridgeMonthNodeAndEdges = useCallback(
    ({ fromId, toId, edge, nodeHashMap, edgesToAdd, bridgeEdges }: any) => {
      const eventNode = nodeHashMap[toId];
      const eventYear = format(new Date(eventNode?.data?.CreatedDate), 'yyyy');
      const yearBridgeId = `${fromId}-YearBridge-${eventYear}`;
      const eventMonth = format(new Date(eventNode?.data?.CreatedDate), 'MMMM');
      const monthBridgeId = `${yearBridgeId}-MonthBridge-${eventMonth}`;
      const weekOfMonth = getWeekOfMonth(new Date(eventNode?.data?.CreatedDate));
      const weekBridgeId = `${monthBridgeId}-WeekBridge-${weekOfMonth}`;
      const eventDay = format(new Date(eventNode?.data?.CreatedDate), 'E');
      const dayBridgeId = `${weekBridgeId}-DayBridge-${eventDay}`;

      // Create the Bridge Node
      if (nodeHashMap[yearBridgeId] === undefined) {
        const newBridgeNode = new BridgeNode(yearBridgeId, yearBridgeId, {
          data: { connectionsCount: 1 },
          bridgeLabel: eventYear,
          properties: { hidden: true, draggable: true, type: BridgeNodeTypeEnum.EventBridgeNode },
          metaData: {
            bridgeType: 'year',
            // date: eventNode?.data?.CreatedDate
          },
        });
        nodeHashMap[yearBridgeId] = newBridgeNode;
      }
      if (nodeHashMap[monthBridgeId] === undefined) {
        const newBridgeNode = new BridgeNode(monthBridgeId, monthBridgeId, {
          data: { connectionsCount: 1 },
          bridgeLabel: eventMonth,
          properties: { hidden: true, draggable: true, type: BridgeNodeTypeEnum.EventBridgeNode },
          metaData: { date: eventNode?.data?.CreatedDate, bridgeType: 'month' },
        });
        nodeHashMap[monthBridgeId] = newBridgeNode;
      }

      if (nodeHashMap[weekBridgeId] === undefined) {
        const newBridgeNode = new BridgeNode(weekBridgeId, weekBridgeId, {
          data: { connectionsCount: 1 },
          bridgeLabel: `W${weekOfMonth}`,
          properties: { hidden: true, draggable: true, type: BridgeNodeTypeEnum.EventBridgeNode },
          metaData: {
            bridgeType: 'week',
            // date: eventNode?.data?.CreatedDate
          },
        });
        nodeHashMap[weekBridgeId] = newBridgeNode;
      }
      if (nodeHashMap[dayBridgeId] === undefined) {
        const newBridgeNode = new BridgeNode(dayBridgeId, dayBridgeId, {
          data: { connectionsCount: 1 },
          bridgeLabel: `${eventDay}`,
          properties: { hidden: true, draggable: true, type: BridgeNodeTypeEnum.EventBridgeNode },
          metaData: {
            bridgeType: 'day',
            // date: eventNode?.data?.CreatedDate
          },
        });
        nodeHashMap[dayBridgeId] = newBridgeNode;
      }

      // Create the Edge from the Record Node to the year Bridge Node
      if (!bridgeEdges.has(`${fromId}-${yearBridgeId}`)) {
        const newFromEdge = new Edge(
          `${fromId}-${yearBridgeId}-edge-bridge`,
          EdgeType.EventEdge,
          fromId,
          yearBridgeId,
          {
            hidden: true,
          },
        );
        edgesToAdd.push(newFromEdge);
        bridgeEdges.add(`${fromId}-${yearBridgeId}`);
      }

      // create edge from year bridge to month bridge
      if (!bridgeEdges.has(`${yearBridgeId}-${monthBridgeId}`)) {
        const newFromEdge = new Edge(
          `${yearBridgeId}-${monthBridgeId}-edge-bridge`,
          EdgeType.EventEdge,
          yearBridgeId,
          monthBridgeId,
          {
            hidden: true,
          },
        );
        edgesToAdd.push(newFromEdge);
        bridgeEdges.add(`${yearBridgeId}-${monthBridgeId}`);
      }

      // create edge from month bridge to week bridge
      if (!bridgeEdges.has(`${monthBridgeId}-${weekBridgeId}`)) {
        const newFromEdge = new Edge(
          `${monthBridgeId}-${weekBridgeId}-edge-bridge`,
          EdgeType.EventEdge,
          monthBridgeId,
          weekBridgeId,
          {
            hidden: true,
          },
        );
        edgesToAdd.push(newFromEdge);
        bridgeEdges.add(`${monthBridgeId}-${weekBridgeId}`);
      }

      // create edge from week bridge to day bridge
      if (!bridgeEdges.has(`${weekBridgeId}-${dayBridgeId}`)) {
        const newFromEdge = new Edge(
          `${weekBridgeId}-${dayBridgeId}-edge-bridge`,
          EdgeType.EventEdge,
          weekBridgeId,
          dayBridgeId,
          {
            hidden: true,
          },
        );
        edgesToAdd.push(newFromEdge);
        bridgeEdges.add(`${weekBridgeId}-${dayBridgeId}`);
      }

      // Create the Edge from the week Bridge Node to the Event Node
      if (!bridgeEdges.has(`${dayBridgeId}-${toId}`)) {
        const newToEdge = new Edge(edge.data.id + '_2', EdgeType.EventEdge, dayBridgeId, toId, { hidden: true });
        edgesToAdd.push(newToEdge);
        bridgeEdges.add(`${dayBridgeId}-${toId}`);
      }

      edge.data.type = EdgeType.EventEdge;
      edge.data.metaData = { directConnection: true };
    },
    [],
  );

  const _addMainBridgeNodeAndEdges = useCallback(
    ({
      fromId,
      toId,
      edge,
      nodeHashMap,
      edgesToAdd,
      edgesToDelete,
      bridgeEdges,
      hideExtendedNodes,
      toNodeTable,
    }: any) => {
      const bridgeId = `${fromId}-${toNodeTable}-bridge`;
      const label = `${fromId}-${toNodeTable}-bridge`;
      if (!nodeHashMap[bridgeId]) {
        const newBridgeNode = new BridgeNode(bridgeId, label, {
          data: { connectionsCount: 1 },
          bridgeLabel: toNodeTable,
        });
        nodeHashMap[bridgeId] = newBridgeNode;
      } else {
        nodeHashMap[bridgeId].data.connectionsCount += 1;
      }

      if (!bridgeEdges.has(`${fromId}-${bridgeId}`)) {
        const newFromEdge = {
          data: {
            id: `${fromId}-${bridgeId}-edge-bridge`,
          },
          properties: {
            source: fromId,
            target: bridgeId,
            hidden: false,
          },
        };
        edgesToAdd.push(newFromEdge);
        bridgeEdges.add(`${fromId}-${bridgeId}`);
      }

      if (!bridgeEdges.has(`${bridgeId}-${toId}`)) {
        const newToEdge = {
          data: {
            id: edge.data.id + '_2',
          },
          properties: {
            source: bridgeId,
            target: toId,
            hidden: hideExtendedNodes === false ? false : true,
          },
        };
        edgesToAdd.push(newToEdge);
        bridgeEdges.add(`${bridgeId}-${toId}`);
      }
      edgesToDelete.push(edge.data.id);
    },
    [],
  );

  const _processEdges = useCallback(
    (edgesHashMap: any, nodeHashMap: any, hideExtendedNodes: boolean | undefined) => {
      const edgesToDelete: any[] = [];
      const edgesToAdd: any[] = [];
      const bridgeEdges = new Set<string>();

      Object.keys(edgesHashMap).forEach((key) => {
        const edge = edgesHashMap[key];
        const relationship = edge.data.relationship;
        let fromId = edge.properties.source;
        let toId = edge.properties.target;
        let fromNode = nodeHashMap[fromId];
        let toNode = nodeHashMap[toId];
        if (!fromNode || !toNode) {
          return;
        }
        let toNodeTable = toNode.data.label.split('.')[1];
        let fromNodeTable = fromNode.data.label.split('.')[1];
        if (
          (fromNodeTable === 'Account' || toNodeTable === 'Account') &&
          relationship &&
          toNodeTable &&
          fromNodeTable
        ) {
          if (toNodeTable === 'Account') {
            //switch up let values
            fromId = edge.properties.target;
            toId = edge.properties.source;
            fromNode = nodeHashMap[fromId];
            toNode = nodeHashMap[toId];
            toNodeTable = toNode.data.label.split('.')[1];
            fromNodeTable = fromNode.data.label.split('.')[1];
          }
          _addMainBridgeNodeAndEdges({
            fromId,
            toId,
            edge,
            nodeHashMap,
            edgesToAdd,
            edgesToDelete,
            bridgeEdges,
            hideExtendedNodes,
            fromNodeTable,
            toNodeTable,
          });
        }
        if (
          ((['Task', 'Event', 'Thread'].includes(fromNodeTable) && toNodeTable !== 'Account') ||
            (fromNodeTable !== 'Account' && ['Task', 'Event', 'Thread'].includes(toNodeTable))) &&
          relationship &&
          toNodeTable &&
          fromNodeTable
        ) {
          if (toNodeTable !== 'Event' && toNodeTable !== 'Task' && toNodeTable !== 'Thread') {
            //switch up let values
            fromId = edge.properties.target;
            toId = edge.properties.source;
            fromNode = nodeHashMap[fromId];
            toNode = nodeHashMap[toId];
            toNodeTable = toNode.data.label.split('.')[1];
            fromNodeTable = fromNode.data.label.split('.')[1];
          }
          _addEventBridgeMonthNodeAndEdges({
            fromId,
            toId,
            edge,
            nodeHashMap,
            edgesHashMap,
            edgesToAdd,
            edgesToDelete,
            bridgeEdges,
            hideExtendedNodes,
            fromNodeTable,
            toNodeTable,
          });
        }
      });

      edgesToAdd.forEach((edge: any) => {
        edgesHashMap[edge.data.id] = edge;
      });

      edgesToDelete.forEach((id) => {
        delete edgesHashMap[id];
      });
    },
    [_addEventBridgeMonthNodeAndEdges, _addMainBridgeNodeAndEdges],
  );

  const addComponentsToGraph = useCallback(
    (
      nodes: GraphObject[] = [],
      edges: FormattedEdge[] = [],
      bridgeNodes: BridgeNode[] = [],
      formattedNodes: any[] = [],
    ) => {
      const nodeHashMap: any = _.cloneDeep(renderNodes);
      const edgesHashMap: any = _.cloneDeep(renderEdges);
      const newNodes: any = {};
      const newEdges: any = {};

      nodes.forEach((node) => {
        if (nodeHashMap[node.id] === undefined) {
          const nodePayload = _returnNodeObject(node, false);
          newNodes[node.id] = nodePayload;
        }
      });

      formattedNodes.forEach((node) => {
        if (nodeHashMap[node.data.id] === undefined) {
          newNodes[node.data.id] = node;
        }
      });

      edges?.forEach((edge) => {
        if (edgesHashMap[edge.data.id] === undefined) {
          newEdges[edge.data.id] = edge;
        }
      });

      bridgeNodes.forEach((node) => {
        if (nodeHashMap[node.data.id] === undefined) {
          newNodes[node.data.id] = node;
        }
      });

      let finalNodeHashMap;
      let finalEdgeHashMap;
      if (Object.keys(newNodes).length > 0) {
        const newNodeHashMap = _getFinalNodes(newNodes);
        finalNodeHashMap = { ...renderNodes, ...newNodeHashMap };
      }
      if (Object.keys(newEdges).length > 0) {
        const newEdgesHashMap = _getFinalEdges(newEdges);
        finalEdgeHashMap = { ...renderEdges, ...newEdgesHashMap };
      }

      setRenderComponents({
        nodes: finalNodeHashMap,
        edges: finalEdgeHashMap,
      });
    },
    [_getFinalEdges, _getFinalNodes, _returnNodeObject, renderEdges, renderNodes, setRenderComponents],
  );

  const handleGraphComponentsLoad = useCallback(
    async (
      paths: GraphObject[][],
      accounts: GraphObject[],
      individualNodes: GraphObject[],
      options: {
        formattedAdditionalEdges?: FormattedEdge[];
        formattedAdditionalNode?: any[];
        hideExtendedNodes?: boolean;
        nodePostProcessing?: {
          filterFn: nodeFilterFn;
          postProcessingFn: nodePostProcessingFn;
        };
        edgePostProcessing?: {
          filterFn: edgeFilterFn;
          postProcessingFn: edgePostProcessingFn;
        };
      } = {},
    ) => {
      const {
        formattedAdditionalEdges,
        hideExtendedNodes,
        formattedAdditionalNode,
        nodePostProcessing,
        edgePostProcessing,
      } = options;
      const nodeHashMap: any = {};
      const edgesHashMap: any = {};
      const orphanNodes = [...accounts, ...individualNodes];

      orphanNodes.forEach((node) => {
        if (nodeHashMap[node.id] === undefined) {
          const nodePayload = _returnNodeObject(node, false);
          nodeHashMap[node.id] = nodePayload;
        }
      });

      _processPaths(paths, hideExtendedNodes, edgesHashMap, nodeHashMap);
      await _getThreadsNodes(edgesHashMap, nodeHashMap);

      formattedAdditionalEdges?.forEach((edge) => {
        edgesHashMap[edge.data.id] = edge;
      });

      formattedAdditionalNode?.forEach((node) => {
        nodeHashMap[node.data.id] = node;
      });

      _processEdges(edgesHashMap, nodeHashMap, hideExtendedNodes);
      const finalNodeHashMap = _getFinalNodes(nodeHashMap);
      const finalEdgeHashMap = _getFinalEdges(edgesHashMap);

      if (nodePostProcessing) {
        Object.keys(finalNodeHashMap).forEach((id) => {
          if (nodePostProcessing.filterFn(finalNodeHashMap[id], id, finalNodeHashMap)) {
            nodePostProcessing.postProcessingFn(finalNodeHashMap[id], id, finalNodeHashMap);
          }
        });
      }

      if (edgePostProcessing) {
        Object.keys(finalEdgeHashMap).forEach((id) => {
          if (edgePostProcessing.filterFn(finalEdgeHashMap[id], id, finalEdgeHashMap, finalNodeHashMap)) {
            edgePostProcessing.postProcessingFn(finalEdgeHashMap[id], id, finalEdgeHashMap, finalNodeHashMap);
          }
        });
      }

      setRenderComponents({
        nodes: finalNodeHashMap,
        edges: finalEdgeHashMap,
      });
      return { nodes: finalNodeHashMap, edges: finalEdgeHashMap };
    },
    [
      _getFinalEdges,
      _getFinalNodes,
      _processEdges,
      _processPaths,
      _returnNodeObject,
      setRenderComponents,
      _getThreadsNodes,
    ],
  );

  const addComponentsToHashMap = useCallback(
    (nodes: any = {}, edges: any = {}) => {
      const finalNodeHashMap = _getFinalNodes(nodes);
      const finalEdgeHashMap = _getFinalEdges(edges);
      addToNodesHashMap(finalNodeHashMap);
      addToEdgesHashMap(finalEdgeHashMap);
    },
    [_getFinalEdges, _getFinalNodes, addToEdgesHashMap, addToNodesHashMap],
  );

  return {
    handleGraphComponentsLoad,
    addComponentsToGraph,
    addComponentsToHashMap,
  };
};
