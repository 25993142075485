import React from 'react';
import { Stack, Typography, Avatar, CircularProgress, Tooltip, AvatarGroup } from '@mui/material';
import format from 'date-fns/format';
import { useGetMigrationTableDataByIdQuery } from 'store/migration/api';

interface IProps {
  migrationId: string;
  task: any;
  isOpen?: boolean;
  position: {
    top: number;
    left: number;
  };
}
const CallTooltip = ({ task, migrationId, isOpen = false, position }: IProps) => {
  const [tooltipVisible, setTooltipVisible] = React.useState<boolean>(false);

  const { data: contacts = [], isFetching: contactsLoading } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'Contact',
      skip: 0,
      take: 0,
      conditions: [{ field: 'Id', operator: 'IN', value: `('${task?.WhoId}','${task?.WhatId}','${task?.OwnerId}')` }],
    },
    {
      skip: !task || (!task?.WhoId && !task?.WhatId && !task.OwnerId),
    },
  );

  const isVisible = React.useMemo(() => tooltipVisible || isOpen, [tooltipVisible, isOpen]);
  if (isVisible)
    return (
      <Stack
        sx={{
          zIndex: 999,
          p: 1,
          backgroundColor: 'blue.dark',
          borderRadius: '5px',
          color: 'common.white',
          width: '230px',
          position: 'absolute',
          top: position.top,
          left: 0,
          transform: 'translate(-80%,-10%)',
        }}
        gap={0.5}
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        <Typography variant="b12" color="common.white" mb={1}>
          Call
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Date</Typography>

          <Typography variant="labelMedium12">
            {task?.CreatedDate ? format(new Date(task?.CreatedDate), 'MMM dd, h:mm aa') : null}
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Duration</Typography>
          <Typography variant="labelMedium12"></Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Organizer</Typography>
          {contactsLoading ? (
            <CircularProgress sx={{ color: 'white' }} size={14} />
          ) : (
            <Typography variant="labelMedium12">
              {contacts.find((single) => single?.Id === task?.OwnerId)?.Name}
            </Typography>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Attendee</Typography>
          {contactsLoading ? (
            <CircularProgress sx={{ color: 'white' }} size={14} />
          ) : (
            <Typography variant="labelMedium12">
              <AvatarGroup>
                {(contacts || [])
                  .filter((single) => single?.Id !== task?.OwnerId)
                  .map((singleContact) => (
                    <Tooltip
                      key={`avatar_${singleContact?.Id}`}
                      title={singleContact?.Name ?? `${singleContact?.FirstName} ${singleContact?.LastName}`}
                    >
                      <Avatar sx={{ width: '18px', height: '18px', fontSize: '12px' }}>
                        {singleContact?.Name?.[0] ?? singleContact?.FirstName?.[0]}
                      </Avatar>
                    </Tooltip>
                  ))}
              </AvatarGroup>
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  return <></>;
};

export default CallTooltip;
