import { styled, Box, Card, Chip, Typography, Menu } from '@mui/material';

export const Container = styled(Box)(() => ({
  padding: 20,
  height: '100%',
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  width: '30rem',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[1],
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

export const FieldChip = styled(Chip)(({ theme }) => ({
  borderRadius: '5px',
  background: '#E3F4FF',
}));

export const AdditionalItem = styled(Chip)(({ theme }) => ({
  borderRadius: '5px',
  background: '#E3F4FF',
  display: 'flex',
  alignItems: 'center',
  '& .MuiChip-label': {
    paddingLeft: '6px',
  },
}));

export const VerticalDivider = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  width: 1,
  height: 16,
  position: 'relative',
  '&::before': {
    content: '" "',
    height: 16,
    position: 'absolute',
    top: '-50%',
    width: 1,
    backgroundColor: theme.palette.neutral.n200,
    marginBottom: -4,
  },
}));

export const TitleItem = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.darkGray,
  fontSize: '12px',
}));

export const CardLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
