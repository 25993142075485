import { FC, useState, useRef, useEffect } from 'react';
import { Grid, Typography, Divider, IconButton, FormHelperText, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { TextButton, Modal, ModalContainer, ModalHeader, ModalMain, ModalFooter } from 'components/ui';
import { CustomDropdown } from 'components/CustomDropdown';
import { UserRole } from 'core/types';
import { CustomInput } from 'components/CustomInput';
import { useUser } from 'store/user/hooks';
import { ReactComponent as SendInvitationIcon } from 'assets/icons/sendInvitation.svg';
import { ReactComponent as StatusFailed } from 'assets/icons/status-failed.svg';
import { InvitationContainer, RequiredLabel } from './ui';
import { inviteUser } from 'http/user';
import { Loader } from 'components/Loader';
import { Tenant } from 'store/tenant/types';
import { DISALLOWED_DOMAINS } from 'core/constants';
import { checkEmailPendingStatusApi } from 'http/auth';

interface InviteUserModalProps {
  open: boolean;
  toggleOpen: () => void;
  tenant: Tenant | null;
}

interface FormValues {
  firstName: string;
  lastName: string;
  userEmail: string;
  userRole: string;
  tenantId: number;
}

const validationSchema = yup.object({
  userEmail: yup.string().required('Email is Required!'),
  userRole: yup.string().required('Role is Required'),
});

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  userEmail: '',
  userRole: '',
  tenantId: 0,
};

enum status {
  RESET = 'reset',
}

const InviteUserModal: FC<InviteUserModalProps> = ({ open, toggleOpen, tenant }) => {
  const [loading, setLoading] = useState(false);
  const [emailValidateLoader, setEmailValidateLoader] = useState(false);
  const [invitationSent, setInvitationSent] = useState(false);
  const [invitationFailed, setInvitationFailed] = useState(false);
  const [invitationUserEmail, setInvitationUserEmail] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('');
  const [inviteUserData, setInviteUserData] = useState<FormValues>(initialValues);
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const { getUsers } = useUser();

  const debouncedCheckEmailStatus = async (email: string): Promise<string> => {
    try {
      setEmailErrorMessage('');
      const response = await checkEmailPendingStatusApi(`/${email}/${tenant?.tenantId}`);
      return response.message || ''; // Return the message or an empty string
    } catch (error) {
      console.error('Error during email status check:', error);
      return ''; // Fallback value in case of error
    } finally {
      setEmailValidateLoader(false); // Always executed
    }
  };

  const closeModal = (type?: string) => {
    if (type === status.RESET || !errorMessage) {
      formRef.current?.resetForm();
      setInviteUserData(initialValues);
      toggleOpen();
      setErrorMessage('');
      setInvitationSent(false);
      setEmailErrorMessage('');
      setEmailError(false);
    } else {
      setInvitationSent(false);
    }
  };

  const onSubmit = async (values: FormValues) => {
    // Include tenantId in the form values
    setInvitationUserEmail(values?.userEmail);
    const updatedValues = {
      ...values,
      tenantId: tenant?.tenantId, // Fallback to an empty string if tenantId is not available
    };
    setInviteUserData({
      ...values,
      userRole: '',
    });
    setLoading(true);
    try {
      await inviteUser(updatedValues)
        .then(() => {
          setInvitationSent(true);
          setInvitationFailed(false);
          getUsers();
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
        setInvitationSent(true);
        setInvitationFailed(true);
      }
      setInvitationFailed(true);
      setError(true);
      setLoading(false);
    }
  };
  const validateEmail = async (email: string) => {
    let errors = '';
    setEmailValidateLoader(true);

    // Check if email is provided
    if (!email) {
      errors = 'Email is required.';
      setEmailValidateLoader(false);
      return errors;
    }

    // Validate email format using regular expression
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      errors = 'Please enter a valid email address.';
      setEmailValidateLoader(false);
      return errors;
    }

    // Validate disallowed domains
    const domainPart = email.split('@')[1];
    if (domainPart && DISALLOWED_DOMAINS.some((domain) => domainPart.toLowerCase().includes(domain))) {
      errors = 'Personal email domains like Gmail, Yahoo, etc., are not allowed.';
      setEmailValidateLoader(false);
      return errors;
    }

    // Perform debounced API validation
    try {
      const apiMessage = await debouncedCheckEmailStatus(email);
      if (apiMessage) {
        errors = apiMessage; // Add API error message if present
        return errors;
      }
    } catch (error) {
      console.error('Error during email validation:', error);
    }
  };

  return (
    <Modal open={open} onClose={() => closeModal(status.RESET)}>
      <ModalContainer>
        <ModalHeader>
          <Typography variant="h3" sx={{ color: 'neutral.main', textAlign: 'center' }}>
            {'Invite User'}
          </Typography>

          <IconButton onClick={toggleOpen}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>

        <Divider />
        {loading ? (
          <Loader />
        ) : invitationSent ? (
          <>
            <InvitationContainer>
              {invitationFailed ? (
                <StatusFailed stroke="#898EA1" strokeWidth={'0.3px'} />
              ) : (
                <SendInvitationIcon stroke="#898EA1" strokeWidth={'0.3px'} />
              )}
              <Typography variant="h3" sx={{ color: 'neutral.main', margin: '30px 10px', textAlign: 'center' }}>
                {errorMessage
                  ? errorMessage
                  : invitationFailed
                  ? 'Failed to send Invitation. Please try again later.'
                  : `Invitation sent to ${invitationUserEmail}`}
              </Typography>
            </InvitationContainer>
            <ModalFooter>
              <LoadingButton variant="contained" onClick={() => closeModal()} type="submit">
                Cancel
              </LoadingButton>
            </ModalFooter>
          </>
        ) : (
          <Formik
            initialValues={inviteUserData}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            innerRef={formRef}
          >
            {({ values, isValid, errors, touched, dirty, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              const handleEmailChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;

                handleChange(event); // Call Formik's handleChange

                const validationErrors = await validateEmail(value);

                if (validationErrors) {
                  setEmailError(true);
                  setEmailErrorMessage(validationErrors); // Join multiple error messages into one string
                } else {
                  setEmailError(false);
                  setEmailValidateLoader(false);
                  setEmailErrorMessage('');
                }
              };
              return (
                <>
                  <ModalMain>
                    <form noValidate>
                      <Grid container spacing={2}>
                        <Grid item xs={24} container spacing={2}>
                          <Grid item xs={12}>
                            <CustomInput
                              id="userEmail"
                              name="userEmail"
                              type="email"
                              label={
                                <RequiredLabel>
                                  Email <span>*</span>
                                </RequiredLabel>
                              }
                              placeholder="Add user email"
                              fullWidth
                              required
                              value={values.userEmail}
                              onChange={handleEmailChange}
                              onBlur={handleBlur}
                              error={emailError}
                              helperText={emailErrorMessage} // Display error message
                              InputProps={{
                                endAdornment: emailValidateLoader ? <CircularProgress size={24} /> : null, // Conditionally render the loader
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={12} container spacing={2}>
                          <Grid item xs={6}>
                            <CustomInput
                              id="firstName"
                              name="firstName"
                              label="First Name"
                              placeholder="First Name"
                              fullWidth
                              value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.firstName && !!errors.firstName}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <CustomInput
                              id="lastName"
                              name="lastName"
                              label="Last Name"
                              placeholder="Last Name"
                              fullWidth
                              value={values.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.lastName && !!errors.lastName}
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={12} container spacing={2}>
                          <Grid item xs={12}>
                            <CustomDropdown<UserRole>
                              id="userRole"
                              label={
                                <RequiredLabel>
                                  {' '}
                                  Role <span>*</span>
                                </RequiredLabel>
                              }
                              placeholder="Select user role"
                              value={values.userRole}
                              options={[
                                { label: 'Data Creator', value: UserRole.DATA_CREATOR },
                                { label: 'Data Explorer', value: UserRole.DATA_EXPLORER },
                                { label: 'Data Viewer', value: UserRole.DATA_VIEWER },
                              ]}
                              onSelect={(value) => setFieldValue('userRole', value)}
                            />
                            {errors && touched.userRole && (
                              <Typography variant="body2" sx={{ fontSize: 12, color: '#BD1E13' }}>
                                {errors?.userRole}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  </ModalMain>

                  <Divider />

                  <ModalFooter>
                    <TextButton sx={{ marginRight: 3 }} onClick={() => closeModal(status.RESET)}>
                      Cancel
                    </TextButton>

                    <LoadingButton
                      variant="contained"
                      disabled={!(isValid && dirty && !emailErrorMessage)}
                      loading={loading}
                      onClick={(event) => {
                        event.preventDefault();
                        handleSubmit();
                      }}
                      type="submit"
                    >
                      Send Invitation
                    </LoadingButton>
                  </ModalFooter>
                </>
              );
            }}
          </Formik>
        )}
      </ModalContainer>
    </Modal>
  );
};

export default InviteUserModal;
