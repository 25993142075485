import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import format from 'date-fns/format';
import { parseEmailText } from '../../utils/parseEmailText';
import EmailTooltip from '../Tooltips/EmailTooltip';
import { useMigration } from 'store/migration/hooks';
import CallTooltip from '../Tooltips/CallTooltip';

interface InteractionCardProps {
  type: 'email' | 'call';
  activity: any;
  isThread?: boolean;
  node: any;
  handleClick: (id: string, activity?: any) => void;
}
const InteractionCard = ({ type, activity, node, handleClick, isThread = false }: InteractionCardProps) => {
  const { ActivityDate, Description, CreatedDate } = activity;
  const { Name } = node;

  const {
    data: { migrationId },
  } = useMigration();

  const [isTooltipVisible, setIsTooltipVisible] = React.useState<boolean>(false);

  const containerRef = React.useRef<HTMLDivElement>(null);

  const emailData = React.useMemo(() => {
    if (!Description) return null;
    const data = parseEmailText(Description);
    return data;
  }, [Description]);

  return (
    <Stack direction="row" ref={containerRef}>
      {type === 'email' && (
        <EmailTooltip
          isOpen={isTooltipVisible}
          migrationId={migrationId}
          task={activity}
          position={{
            top: (containerRef.current?.getBoundingClientRect().top || 0) - 280,
            left: 0,
          }}
        />
      )}
      {type === 'call' && (
        <CallTooltip
          isOpen={isTooltipVisible}
          migrationId={migrationId}
          task={activity}
          position={{
            top: (containerRef.current?.getBoundingClientRect().top || 0) - 280,
            left: 0,
          }}
        />
      )}
      <Box sx={{ width: '1px', border: '1px solid #00000020', mx: 3, position: 'relative' }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            borderRadius: '100%',
            width: '30px',
            height: '30px',
            bgcolor: 'violet.main',
            position: 'absolute',
            top: -1,
            zIndex: 5,
            transform: 'translateX(-50%)',
          }}
        >
          {type === 'call' ? (
            <CallIcon sx={{ fontSize: '16px', color: 'white' }} />
          ) : (
            <EmailOutlinedIcon sx={{ fontSize: '16px', color: 'white' }} />
          )}
        </Stack>
      </Box>
      <Stack
        spacing={1.5}
        sx={{
          border: '1px solid #00000020',
          backgroundColor: 'white',
          flex: '1',
          maxWidth: '350px',
          cursor: 'pointer',
        }}
        p={1}
        onMouseOver={() => setIsTooltipVisible(true)}
        onMouseOut={() => setIsTooltipVisible(false)}
        onClick={() => {
          if (activity.ThreadCount) {
            const data = {
              label: 'Thread',
              threadData: activity,
            };
            handleClick(activity.ActivityId, data);
          } else {
            handleClick(activity.Id);
          }
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" gap={'5px'} alignItems="baseline">
            <Typography variant="labelMedium14">{Name}</Typography>
            <Typography variant="p12" color="neutral.n400">
              {type === 'call' ? 'Accepted the call' : 'Replied to email'}
            </Typography>
          </Stack>

          <Typography variant="p12" color="neutral.n400">
            {type === 'call' && ActivityDate ? format(new Date(ActivityDate), 'dd MMM, hh:mm aa') : null}
            {type === 'email' && CreatedDate ? format(new Date(CreatedDate), 'dd MMM, hh:mm aa') : null}
          </Typography>
        </Stack>
        <Stack gap={0.5} sx={{ bgcolor: '#00000005', p: 1 }}>
          {type === 'email' && (
            <>
              <Stack direction="row" alignItems="center" gap={1}>
                <AccountCircleOutlinedIcon />
                <Typography variant="labelBold14">{activity?.ToAddress || emailData?.to}</Typography>
                {isThread && (
                  <Box
                    sx={{
                      width: '18px',
                      height: '18px',
                      display: 'flex',
                      borderRadius: '50%',
                      px: '4px',
                      py: '1px',
                      fontSize: '12px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'neutral.n300',
                      color: 'black',
                    }}
                  >
                    {activity.ThreadCount}
                  </Box>
                )}
              </Stack>
              <Typography variant="labelMedium14">{activity?.Subject || emailData?.subject}</Typography>
              <Typography variant="caption" noWrap>
                {activity?.TextBody || emailData?.body}
              </Typography>
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default InteractionCard;
