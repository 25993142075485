import { GroupCountResponse } from 'http/migration/dto';
import { FunctionValue } from 'http/migration/dto';
import { ComponentDesign } from 'pages/DataGraph/components/GraphRender/types';
import { Condition } from 'store/dataRaptorRule/dto/rule.dto';
import { FormattedEdge, GraphEdge, GraphFilter, GraphObject, GraphPathRecord } from 'store/graphData/types';
import { ActionPromiseArgs } from 'store/types';

export type BridgePathMapByRecordId = { [recordId: string]: string[] };

export enum AccountPanelMenuViewsOld {
  Action = 'Action',
  Details = 'Details',
  Activity = 'Activity',
  Engagement = 'Engagament',
  Data = 'Data',
  DataAnomaly = 'Anomalies',
}
export enum AccountPanelMenuViews {
  Details = 'Details',
  Action = 'Action',
  Insights = 'Insights',
  Activities = 'Activities',
  DataAnomaly = 'Anomalies',
}

export enum ContactPanelMenuViews {
  Details = 'Details',
  Action = 'Action',
  Activities = 'Activities',
  Insights = 'Insights',
  DataAnomaly = 'Anomalies',
}

export enum OpportunityPanelMenuViews {
  Details = 'Details',
  Action = 'Action',
  Activities = 'Activities',
  Insights = 'Insights',
  Contacts = 'Contacts',
}

export enum LeadPanelMenuViews {
  Details = 'Details',
  Action = 'Action',
  Activities = 'Activities',
  Insights = 'Insights',
  DataAnomaly = 'Anomalies',
}

export interface GetMigrationTableRecordsParams extends ActionPromiseArgs<any[]> {
  migrationId: string;
  tableId: string;
  skip: number;
  take: number;
  conditions: Condition[];
}

export interface GetFuzzySearchParams extends ActionPromiseArgs<any[]> {
  migrationId: string;
  tableId: string;
  label: string;
  options: { field: string; value: string; limit?: number; minPercentage?: number; fallBackSearchField?: string[] };
}

export interface SearchFilterData {
  label: string;
  active: boolean;
  tableName: string;
  searchField: string;
  fallBackSearchField?: string[];
  loading?: boolean;
  error?: string;
  disabled?: boolean;
}

export enum GraphRenderView {
  ACCOUNT = 'ACCOUNT_VIEW',
  DUPLICATES = 'DUPLICATES_VIEW',
  LEADS = 'LEADS_VIEW',
}

interface AccountPanelMenuState {
  menu: AccountPanelMenuViews;
  open: boolean;
}

interface LeadViewState {
  initialGroupData?: GroupCountResponse[];
  groupByFields?: string[];
  groupByFieldsSelected: (string | FunctionValue)[];
  dateFields: string[];
  maxNodeCountToExpand: number;
  loadedBridgeNodes: { [id: string]: boolean };
  sortByField: string;
  sortPanelState: {
    open: boolean;
  };
  expandSearchNodes: BridgePathMapByRecordId;
}

interface NodePanelState {
  activityTab: {
    excludedTypes: string[];
    excludedStatuses: string[];
  };
}

export enum DuplicateTablesQuickFilterOptions {
  Account = 'Account',
  Opportunity = 'Opportunity',
  Contact = 'Contact',
  Lead = 'Lead',
}

export interface DuplicateQuickFilter {
  selectedTables: string[];
}

export interface GraphRendererState {
  loading: boolean | string;
  error: boolean | string;
  success: boolean | string;
  data: {
    tableRecords: any;
    showDuplicationSnackBar: boolean;
    showNeptuneStatusModal: boolean;
    renderNodes: MapObject<GraphObject>;
    renderEdges: MapObject<GraphEdge>;
    nodeHashMap: MapObject<GraphObject>;
    edgesHashMap: MapObject<GraphEdge>;
    searchFilterOption: SearchFilterData[];
    searchFilterResult: SearchFilterResult;
    searchTerm: string;
    searching: boolean;
    edgesToExpand: string[];
    customDesignMap: MapObject<ComponentDesign>;
    whiteEdgeBlockNodeId: string;
    selectedNode: any;
    selectedNodeFromSearchBar: any;
    view: GraphRenderView;
    hoverAccountId: string;
    deduplicationPreloadData?: GraphPreloadData;
    duplicatedNodeIds: string[];
    defaultDesign: ComponentDesign;
    schemaName?: string;
    masterRecordIds: string[];
    hoverNodeId: string;
    duplicationCounterMap: MapObject<number>;
    renderDuplicateEdgesOnHover: string[];
    renderDuplicateNodesOnHover: string[];
    graphSearchResult?: GraphPathRecord;
    graphAccountViewResult?: GraphPathRecord;
    graphOpenLeadResult?: GraphObject[];
    graphOpenLeadResultCounter?: number;
    openMergeModal: boolean;
    showCreateActivityModal: boolean;
    recordsToMerge?: RecordReference[];
    showSearchDetail: boolean;
    graphFilter: GraphFilter;
    quickFilterTmp: GraphFilter;
    customFilterTmp: GraphFilter;
    duplicateQuickFilter: DuplicateQuickFilter;
    graphObjectCounter: GraphObjectCounter;
    AccountPanelView: AccountPanelMenuState;
    leadViewState: LeadViewState;
    nodePanelState: NodePanelState;
  };
}

export interface SearchFilterOptionUpdate {
  option: string;
  value: boolean;
  disabled?: boolean;
}

export interface GraphObjectCounter {
  [x: string]: number;
}

export interface SearchFilterResult {
  [x: string]: any[];
}

export interface MapObject<T> {
  [x: string]: T;
}

export interface UpdatePayload {
  id: string;
  [x: string]: any;
}

export interface GraphPreloadData {
  nodes: GraphObject[];
  paths: GraphObject[][];
  formattedAdditionalEdges: FormattedEdge[];
}

export interface RecordReference {
  tableName: string;
  recordId: string;
}

export interface PartialUpdateGraphFilterParams {
  path: string;
  value: any;
}

export interface GraphRendererReturnHook extends GraphRendererState {
  setError: (error: string | boolean) => void;
  setSuccess: (success: string | boolean) => void;
  setLoading: (loading: boolean) => void;
  getMigrationTableRecord: (args: GetMigrationTableRecordsParams) => void;
  setRenderNodes: (args: MapObject<GraphObject>) => void;
  setRenderEdges: (args: MapObject<GraphEdge>) => void;
  setRenderComponents: (args: { nodes?: MapObject<GraphObject>; edges?: MapObject<GraphEdge> }) => void;
  setNodesHashMap: (args: MapObject<GraphObject>) => void;
  addToNodesHashMap: (args: MapObject<GraphObject>) => void;
  setEdgesHashMap: (args: MapObject<GraphEdge>) => void;
  addToEdgesHashMap: (args: MapObject<GraphEdge>) => void;
  updateNodes: (args: UpdatePayload[]) => void;
  updateEdges: (args: UpdatePayload[]) => void;
  cleanState: () => void;
  setSearchFilterOption: (args: SearchFilterOptionUpdate[]) => void;
  getFuzzySearch: (args: GetFuzzySearchParams) => void;
  cleanFilterData: () => void;
  setSearchTerm: (value: string) => void;
  setEdgesToExpand: (value: string[]) => void;
  setCustomDesignMap: (value: MapObject<ComponentDesign>) => void;
  setSelectedNode: (value: any) => void;
  setWhiteEdgeBlockedNodeId: (value: string) => void;
  setSelectedNodeFromSearchBar: (value: any) => void;
  setAccountHoverId: (value: string) => void;
  setGraphView: (value: GraphRenderView) => void;
  setShowDuplicationSnackBar: (value: boolean) => void;
  setShowNeptuneStatusModal: (value: boolean) => void;
  setDeduplicationPreloadData: (value: GraphPreloadData | undefined) => void;
  setDuplicatedNodeIds: (value: string[]) => void;
  setDefaultDesign: (value: ComponentDesign) => void;
  setSchemaName: (value: string) => void;
  setMasterRecordIds: (value: string[]) => void;
  setHoverNodeId: (value: string) => void;
  setDuplicationCounterMap: (value: MapObject<number>) => void;
  setRenderDuplicatedEdgesOnHover: (value: string[]) => void;
  setRenderDuplicatedNodesOnHover: (value: string[]) => void;
  setGraphSearchResult: (value: GraphPathRecord) => void;
  setGraphAccountViewResult: (value: GraphPathRecord) => void;
  setGraphOpenLeadResult: (value: GraphObject[]) => void;
  setOpenMergeModal: (value: boolean) => void;
  setRecordsToMerge: (value: RecordReference[]) => void;
  setShowRecordPanel: (value: boolean) => void;
  setShowSearchDetail: (value: boolean) => void;
  setGraphFilter: (value: GraphFilter) => void;
  partialUpdateGraphFilter: (value: PartialUpdateGraphFilterParams) => void;
  setQuickGraphFilter: (value: GraphFilter) => void;
  partialUpdateQuickGraphFilter: (value: PartialUpdateGraphFilterParams) => void;
  setGraphCustomFilter: (value: GraphFilter) => void;
  partialUpdateCustomGraphFilter: (value: PartialUpdateGraphFilterParams) => void;
  setGraphObjectCounter: (value: GraphObjectCounter) => void;
  setShowCreateActivityModal: (value: boolean) => void;
  setSelectedAccountPanelView: (value: AccountPanelMenuViews) => void;
  setGraphOpenLeadResultCounter: (value: number) => void;
  leadViewReducers: {
    setLeadViewInitialGroupData: (value: GroupCountResponse[]) => void;
    setLeadViewGroupByFields: (value: string[]) => void;
    setLeadViewGroupByFieldsSelected: (value: (string | FunctionValue)[]) => void;
    setLeadViewLoadedBridgeNode: (value: { key: string; value: boolean }) => void;
    clearLeadViewLoadedBridgeNode: () => void;
    setLeadViewSortPanelShow: (value: boolean) => void;
    setLeadViewDateFields: (value: string[]) => void;
    setLeadViewSortByField: (value: string) => void;
    setLeadViewSetExpandSearchNodes: (value: BridgePathMapByRecordId) => void;
  };
  duplicateQuickFilterReducers: {
    duplicateQuickFilterSetSelectedTables: (value: string[]) => void;
  };
  nodePanelReducer: {
    setExcludedTypes: (value: string[]) => void;
    setExcludedStatuses: (value: string[]) => void;
  };
}
