import React from 'react';
import { Stack, Typography, Grid, Avatar, Tooltip, AvatarGroup } from '@mui/material';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { parseEmailText } from '../../../../AccountPanel/components/MenuViews/Activity/utils/parseEmailText';
import {
  useGetEmailsDataByTasksIdQuery,
  useGetMigrationTableDataByIdQuery,
  useLazyDownloadEmailAttachmentQuery,
} from 'store/migration/api';
import { format } from 'date-fns';
import { last } from 'lodash';
import { isHtml } from '../../utils';

interface IProps {
  nodeData: any;
  migrationId: string;
}
const EmailDetailsContainer = ({ nodeData = {}, migrationId }: IProps) => {
  const { Description, id } = nodeData;

  const emailData = parseEmailText(Description);

  const [triggerDownload] = useLazyDownloadEmailAttachmentQuery();

  const { data: emailsMessages = [] } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'EmailMessage',
      skip: 0,
      take: 0,
      conditions: [
        {
          field: 'ActivityId',
          operator: '=',
          value: `'${id}'`,
        },
      ],
    },
    {
      skip: !id,
    },
  );

  const { data: emailsM = [] } = useGetEmailsDataByTasksIdQuery(
    {
      migrationId,
      tasksIds: [id],
    },
    {
      skip: !id,
    },
  );

  const email = React.useMemo(() => emailsM[0], [emailsM]);

  const handleEmailAttachmentDownload = (id: string) => {
    if (!email) return;
    triggerDownload({
      messageId: email?.MessageSourceId,
      dataSource: email?.dataSourceId,
      attachmentId: id,
    });
  };

  const { data: contactsData = [] } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'Contact',
      skip: 0,
      take: 0,
      conditions: [
        {
          field: 'Id',
          operator: 'in',
          value: `(${email?.Relations?.map((relation: any) => `'${relation.RelationId}'`).join(', ')})`,
        },
      ],
    },
    {
      skip: !email || !email?.Relations || email?.Relations?.length === 0,
    },
  );
  const { data: usersData = [] } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'User',
      skip: 0,
      take: 0,
      conditions: [
        {
          field: 'Id',
          operator: 'in',
          value: `(${email?.Relations?.map((relation: any) => `'${relation.RelationId}'`).join(', ')})`,
        },
      ],
    },
    {
      skip: !email || !email?.Relations || email?.Relations?.length === 0,
    },
  );

  const allPersons = [...contactsData, ...usersData];
  if (email)
    return (
      <Grid direction="row" container columns={2} rowGap={2} p={1}>
        <Grid item xs={2}>
          <Stack gap={0.5}>
            <Typography variant="p12" color="neutral.n400">
              From
            </Typography>

            <Typography variant="p14">
              {email?.FromName} {`(${email?.FromAddress})`}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack gap={0.5}>
            <Typography variant="p12" color="neutral.n400">
              Date
            </Typography>
            {email?.MessageDate && (
              <Typography variant="p14">{format(new Date(email?.MessageDate), 'MMM dd, hh:mm aa')}</Typography>
            )}
          </Stack>
        </Grid>

        <Grid item xs={1}>
          <Stack gap={0.5}>
            <Typography variant="p12" color="neutral.n400">
              Recipient
            </Typography>
            <AvatarGroup sx={{ width: 'min-content' }}>
              {email?.Relations?.filter((relation: any) => relation.RelationType !== 'FromAddress').map(
                (relation: any) => {
                  const relatedUser = allPersons.find((person) => person.Id === relation.RelationId);
                  return (
                    <Tooltip
                      key={`user_${relation.RelationId}`}
                      // title={`${relatedUser?.Name} \n ${email[relation.RelationType]}`}
                      title={
                        <Stack gap={0.5}>
                          <Typography variant="p12">{relatedUser?.Name}</Typography>
                          <Typography variant="p12">{relatedUser?.Email}</Typography>
                        </Stack>
                      }
                    >
                      <Avatar sx={{ width: '25px', height: '25px' }}> {relatedUser?.Name[0]} </Avatar>
                    </Tooltip>
                  );
                },
              )}
            </AvatarGroup>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack gap={0.5}>
            <Typography variant="p12" color="neutral.n400">
              Description
            </Typography>
            {email?.HtmlBody && isHtml(email?.HtmlBody) ? (
              <div
                style={{ border: '1px solid #00000010', padding: '5px' }}
                dangerouslySetInnerHTML={{ __html: email.HtmlBody }}
              />
            ) : (
              <Typography variant="p14">{email?.TextBody}</Typography>
            )}
          </Stack>
        </Grid>
        {email?.Attachment && (
          <Grid item xs={2}>
            <Stack gap={0.5}>
              <Typography variant="p12" color="neutral.n400">
                Attachments
              </Typography>

              {email?.Attachment?.map((singleAttachment: any, index: number) => {
                const fileExtension = last(singleAttachment?.name?.split('.') as any[]);
                const fileName = (singleAttachment?.name?.split('.') as string[])?.reduce(
                  (acc, current, index, array) => {
                    if (index < array?.length - 1) return acc + '.' + current;
                    return acc;
                  },
                  '',
                );

                let fileSize = '0';
                let unit = 'b';
                if (singleAttachment.size > 1000) {
                  fileSize = (singleAttachment.size / 1000).toFixed(2);
                  unit = 'KB';
                }
                if (singleAttachment.size > 1000000) {
                  fileSize = (singleAttachment.size / 1000000).toFixed(2);
                  unit = 'MB';
                }
                return (
                  <Tooltip key={`attachment_box_${index}`} title="Click to download.">
                    <Stack
                      bgcolor="#00000015"
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ maxWidth: '300px', cursor: 'pointer' }}
                      p={0.5}
                      onClick={() => handleEmailAttachmentDownload(singleAttachment.id)}
                    >
                      <Stack direction="row" alignItems="center" gap={0.5} sx={{ width: '70%' }}>
                        <PictureAsPdfOutlinedIcon sx={{ fontSize: '16px' }} />
                        <Typography variant="p12" noWrap flexGrow={1}>
                          {fileName}
                        </Typography>
                        <Typography variant="p12">.{fileExtension}</Typography>
                      </Stack>
                      <Typography variant="p14" color="neutral.n400">
                        {fileSize} {unit}
                      </Typography>
                    </Stack>
                  </Tooltip>
                );
              })}
            </Stack>
          </Grid>
        )}
      </Grid>
    );
  return (
    <Grid direction="row" container columns={2} rowGap={2} p={1}>
      <Grid item xs={2}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            From
          </Typography>

          <Typography variant="p14">
            {emailsMessages[0]?.FromName} {`(${emailsMessages[0]?.FromAddress})`}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            Date
          </Typography>
          {emailsMessages[0]?.MessageDate && (
            <Typography variant="p14">
              {format(new Date(emailsMessages[0]?.MessageDate), 'MMM dd, hh:mm aa')}
            </Typography>
          )}
        </Stack>
      </Grid>

      <Grid item xs={1}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            Recipient
          </Typography>

          <Typography variant="p14"> {emailsMessages[0]?.ToAddress}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            Description
          </Typography>

          <Typography variant="p14">{emailsMessages[0]?.TextBody}</Typography>
        </Stack>
      </Grid>
      {emailsMessages[0]?.HasAttachment && (
        <Grid item xs={2}>
          <Stack gap={0.5}>
            <Typography variant="p12" color="neutral.n400">
              Attachments
            </Typography>

            <Stack
              bgcolor="#00000015"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ maxWidth: '200px' }}
              p={0.5}
            >
              <Stack direction="row" alignItems="center" gap={0.5}>
                <PictureAsPdfOutlinedIcon sx={{ fontSize: '16px' }} />
                <Typography variant="p12">{emailData.attachments}</Typography>
              </Stack>
              <Typography variant="p14" color="neutral.n400">
                2.1 MB
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default EmailDetailsContainer;
