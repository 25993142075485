import React from 'react';
import { Stack, Typography, Tooltip } from '@mui/material';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { last } from 'lodash';

interface IProps {
  attachments: any[];
  triggerDownload: (id: string) => void;
}
const AttachmentSection = ({ attachments, triggerDownload }: IProps) => {
  return (
    <Stack gap={0.5}>
      <Typography variant="p12" color="neutral.n400">
        Attachments
      </Typography>

      {attachments?.map((singleAttachment: any, index: number) => {
        const fileExtension = last(singleAttachment?.name?.split('.') as any[]);
        const fileName = (singleAttachment?.name?.split('.') as string[])?.reduce((acc, current, index, array) => {
          if (index < array?.length - 1) return acc + '.' + current;
          return acc;
        }, '');

        let fileSize = '0';
        let unit = 'b';
        if (singleAttachment.size > 1000) {
          fileSize = (singleAttachment.size / 1000).toFixed(2);
          unit = 'KB';
        }
        if (singleAttachment.size > 1000000) {
          fileSize = (singleAttachment.size / 1000000).toFixed(2);
          unit = 'MB';
        }
        return (
          <Tooltip title={'Click to download'} arrow key={`attachment_box_${index}`}>
            <Stack
              bgcolor="#00000015"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ maxWidth: '300px', cursor: 'pointer' }}
              p={0.5}
              onClick={() => triggerDownload(singleAttachment?.id)}
            >
              <Stack direction="row" alignItems="center" gap={0.5} sx={{ width: '70%' }}>
                <PictureAsPdfOutlinedIcon sx={{ fontSize: '16px' }} />
                <Typography variant="p12" noWrap flexGrow={1}>
                  {fileName}
                </Typography>
                <Typography variant="p12">.{fileExtension}</Typography>
              </Stack>
              <Typography variant="p14" color="neutral.n400">
                {fileSize} {unit}
              </Typography>
            </Stack>
          </Tooltip>
        );
      })}
    </Stack>
  );
};

export default AttachmentSection;
