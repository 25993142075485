import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

interface IProps {
  user: any;
}
const UserTooltip = ({ user }: IProps) => {
  return (
    <Stack bgcolor="white" p={0.5} gap={1}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Stack
          sx={{ width: '45px', height: '45px', borderRadius: '100%', backgroundColor: '#F6F8FB' }}
          alignItems="center"
          justifyContent="center"
        >
          <PersonIcon sx={{ color: '#00000050', fontSize: '28px' }} />
        </Stack>
        <Stack gap={0.1}>
          <Typography variant="labelMedium14">{user?.Name}</Typography>
          <Typography
            variant="p12"
            sx={{ px: 2, py: 0.5, backgroundColor: '#00000020', borderRadius: '3px', color: 'black' }}
          >
            {user?.Title || user?.Type}
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      <Stack direction="row" gap={2}>
        <Stack direction="row" sx={{ width: '90px' }}>
          <Typography variant="p12" color="neutral.n400">
            work email:
          </Typography>
        </Stack>
        <Typography variant="labelMedium12">{user?.Email}</Typography>
      </Stack>
      <Stack direction="row" gap={2}>
        <Stack direction="row" sx={{ width: '90px' }}>
          <Typography variant="p12" color="neutral.n400">
            work phone:
          </Typography>
        </Stack>
        <Typography variant="labelMedium12">{user?.Phone}</Typography>
      </Stack>
    </Stack>
  );
};

export default UserTooltip;
