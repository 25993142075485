import { FC, useEffect, useState } from 'react';
import { DISALLOWED_DOMAINS, PUBLIC_ABS_ROUTE_PATHS } from 'core/constants';
import { FormHelperText, Typography, Box, Divider, FormControlLabel, Checkbox, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ReactComponent as SalesforceIcon } from 'assets/icons/salesforce.svg';
import { Form, AuthInput, LoadingButton, SecondaryLoadingButton } from 'components/ui';
import { useAuth } from 'store/auth/hooks';
import { AuthLayout } from 'components/AuthLayout';
import { store } from 'store/index';
import { resetApp } from 'store/actions';
import { requestIntegrationAuth } from 'http/auth';
import { openAuthWindow } from 'pages/IntegrationRedirect/popup';
import { useSnackbar } from 'notistack';
import { ReactComponent as BoxChecked } from 'assets/icons/boxChecked.svg';
import { ReactComponent as BoxUnchecked } from 'assets/icons/boxUnchecked.svg';
import { saveToLocalStorage, loadFromLocalStorage } from './utils';
import { getUserDetailById } from 'http/user';
interface FormValues {
  email: string;
  agree: boolean;
}

const formSchema = yup.object({
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email.')
    .test('is-business-email', 'Personal email domains like Gmail, Yahoo, etc., are not allowed.', (value) => {
      if (!value) return false;
      const domainPart = value.split('@')[1]; // Get the part after '@'
      if (!domainPart) return false;
      const domain = domainPart.split('.')[0]; // Extract the first part of the domain
      return !DISALLOWED_DOMAINS.includes(domain.toLowerCase());
    }),
  agree: yup.boolean().oneOf([true], 'You need to agree our terms & conditions'),
});

const Signup: FC = () => {
  const { loading, error, setError, setSuccess, loginWithAccessToken, setEmailExist, checkEmail, emailExist } =
    useAuth();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<FormValues>({
    email: '',
    agree: false,
  });
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  useEffect(() => {
    const bc = new BroadcastChannel('integration-events');
    bc.onmessage = (event) => {
      if (event.data.type === 'auth' && event.data.data.accessToken) {
        loginWithAccessToken(event.data.data.accessToken);
      }
      if (event.data.type === 'error' && event.data.data) {
        setError(event.data.data);
        snackbar.enqueueSnackbar(event.data.data, { variant: 'error' });
      }
    };
    return () => {
      bc.close();
    };
  }, [loginWithAccessToken, setError, snackbar]);

  const handleSignInWithSalesforce = () => {
    const getOAuthUrl = async () => {
      const oauthUrl = await requestIntegrationAuth('salesforce');
      return oauthUrl;
    };
    getOAuthUrl().then((url) => {
      openAuthWindow(url, 'authpopup');
    });
  };

  useEffect(() => {
    if (!token) setError(false);
    setSuccess(false);
    setEmailExist('');
    const data = loadFromLocalStorage('signupData');
    if (data && data.email) {
      setInitialData({
        email: data.email,
        agree: true,
      });
      setEmail(data.email);
    }
    store.dispatch(resetApp());
  }, [token]);
  const onSubmit = async (values: FormValues) => {
    setEmail(values.email);
    checkEmail(values.email);
  };
  useEffect(() => {
    if (email && emailExist === email) {
      saveToLocalStorage('signupData', { email });
      navigate(PUBLIC_ABS_ROUTE_PATHS.signup1);
    } else if (emailExist === 'emailExist') {
      setEmailError(true);
    }
  }, [emailExist, email]);

  const getUserDataById = async () => {
    await getUserDetailById(String(token)).then((response) => {
      const [firstName, lastName] = response.userName.split(' ');
      saveToLocalStorage('signupData', {
        email: response?.userEmail,
        firstName: firstName,
        lastName: lastName,
      });
      setEmail(response?.userEmail);
      setInitialData({
        email: response.userEmail,
        agree: true,
      });
    });
  };

  useEffect(() => {
    if (token) {
      getUserDataById();
    }
  }, [token]);

  return (
    <AuthLayout backButtonEnabled={true} currentStep={1} totalSteps={4}>
      <Box>
        <Typography variant="h2" marginBottom={4}>
          Join Us!
        </Typography>
        <Typography
          sx={{ fontSize: 12, color: 'neutral.n400' }}
          marginBottom={4}
          variant="labelRegular12"
          component="p"
        >
          Fill in the registration data. It will take a couple of minutes.
          <br />
          All you need is an e-mail
        </Typography>
        <Formik initialValues={initialData} validationSchema={formSchema} onSubmit={onSubmit} enableReinitialize={true}>
          {({ values, isValid, errors, touched, handleChange, handleSubmit, handleBlur }) => {
            const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(event);
              setEmailExist('');
              setEmailError(false);
            };
            return (
              <Form noValidate>
                <Box>
                  <Typography variant="body2" sx={{ fontSize: 12 }}>
                    Email address
                  </Typography>
                  <AuthInput
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Enter email address"
                    value={values.email}
                    onChange={handleEmailChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={Boolean(touched.email && errors.email)} // Show error state
                    helperText={touched.email && errors.email} // Display error message
                  />
                </Box>
                {emailError && (
                  <Typography variant="body2" sx={{ fontSize: 12, color: '#BD1E13', marginTop: '16px' }}>
                    Email address exists
                  </Typography>
                )}
                <FormControlLabel
                  sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}
                  control={
                    <Checkbox
                      id="agree"
                      name="agree"
                      checked={values.agree}
                      onChange={handleChange}
                      icon={<BoxUnchecked />}
                      checkedIcon={<BoxChecked />}
                    />
                  }
                  label={
                    <Typography variant="p12" sx={{ color: 'neutral.n400' }}>
                      I agree to{' '}
                      <Link
                        href="/terms-and-conditions"
                        target="_blank"
                        rel="noopener"
                        underline="none"
                        sx={{ display: 'inline-flex', alignItems: 'center' }}
                      >
                        terms{' '}
                        <Typography component="span" variant="p12" sx={{ color: 'gray', margin: '0 4px' }}>
                          &
                        </Typography>{' '}
                        conditions
                      </Link>
                    </Typography>
                  }
                />
                {touched.agree && errors.agree && (
                  <FormHelperText error sx={{ marginTop: 1 }}>
                    {errors.agree}
                  </FormHelperText>
                )}
                <LoadingButton
                  loading={loading}
                  disabled={!isValid}
                  sx={{ marginTop: 4 }}
                  type="submit"
                  onClick={(event) => {
                    event.preventDefault();
                    handleSubmit();
                  }}
                >
                  Next
                </LoadingButton>

                {!!error && (
                  <FormHelperText sx={{ color: 'red.main', textAlign: 'center', marginTop: 2 }}>
                    {typeof error === 'string' ? error : 'Something went wrong!'}
                  </FormHelperText>
                )}

                <Box marginTop="32px">
                  <Divider>
                    <Typography variant="body2" sx={{ fontSize: 12, color: '#898EA1' }}>
                      or
                    </Typography>
                  </Divider>
                </Box>

                <SecondaryLoadingButton
                  onClick={handleSignInWithSalesforce}
                  variant="outlined"
                  sx={{ marginTop: 3 }}
                  endIcon={<SalesforceIcon />}
                >
                  Sign Up with Salesforce
                </SecondaryLoadingButton>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </AuthLayout>
  );
};

export default Signup;
