import React from 'react';
import { Grid, Box, Typography, Stack } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import EmailCard from './EmailCard';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useGetMigrationTableDataByIdQuery } from 'store/migration/api';
import { last } from 'lodash';

interface IProps {
  isFullscreen: boolean;
  toggleFullScreen: () => void;
  migrationId: string;
  thread: any;
}
const ThreadContainer = ({ isFullscreen, toggleFullScreen, thread, migrationId }: IProps) => {
  const [receivedOnly, setReceivedOnly] = React.useState<boolean>(false);
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  const data = React.useMemo(() => {
    const allMessages = [{ ...thread, ThreadElements: undefined }, ...(thread?.ThreadElements || [])].sort(
      (a, b) => new Date(a.CreatedDate).getTime() - new Date(b.CreatedDate).getTime(),
    );
    const allPersonsIds = allMessages
      .map((message) => message?.Relations?.map((singleRelation: any) => singleRelation?.RelationId))
      .flat(3);
    return {
      allMessages,
      allPersonsIds,
    };
  }, [thread]);

  const { allMessages = [], allPersonsIds = [] } = data;

  const length = allMessages.length;

  const { data: usersData = [] } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'User',
      skip: 0,
      take: 0,
      conditions: [
        {
          field: 'Id',
          operator: 'in',
          value: `(${allPersonsIds.map((id) => `'${id}'`).join(', ')})`,
        },
      ],
    },
    {
      skip: !allPersonsIds.length,
    },
  );
  const { data: contactsData = [] } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'Contact',
      skip: 0,
      take: 0,
      conditions: [
        {
          field: 'Id',
          operator: 'in',
          value: `(${allPersonsIds.map((id) => `'${id}'`).join(', ')})`,
        },
      ],
    },
    {
      skip: !allPersonsIds.length,
    },
  );

  const allPersons = [...contactsData, ...usersData];
  return (
    <Grid item container display={'flex'} direction={'column'} gap={1} p={1} bgcolor={'#F6F8FB'}>
      <Grid item display="flex" alignItems="center" justifyContent="space-between">
        <Box
          sx={{
            backgroundColor: 'primary.main',
            borderRadius: '20px',
            px: 2,
            py: '4px',
            opacity: receivedOnly ? 1 : 0.4,
            cursor: 'pointer',
          }}
          onClick={() => setReceivedOnly(!receivedOnly)}
        >
          <Typography variant="p14" color="neutral.white">
            Received
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} gap={0.5} onClick={toggleFullScreen}>
          {isFullscreen ? (
            <FullscreenExitIcon sx={{ fontSize: '16px', color: 'primary.main' }} />
          ) : (
            <FullscreenIcon sx={{ fontSize: '16px', color: 'primary.main' }} />
          )}
          {isFullscreen ? (
            <Typography color="primary.main" variant="p14">
              Go Back To Panel Mode
            </Typography>
          ) : (
            <Typography color="primary.main" variant="p14">
              View In Full Screen Mode
            </Typography>
          )}
        </Box>
      </Grid>

      <Grid item container sx={{ overflowY: 'auto' }}>
        {!isExpanded && length > 3 ? (
          <>
            <EmailCard email={allMessages[0]} persons={allPersons} />
            <Stack direction="row" alignItems="center" my={0.5}>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  borderRadius: '100%',
                  width: '30px',
                  height: '30px',
                  bgcolor: 'white',
                  mx: 2,
                  transform: 'translateX(-25%)',
                  cursor: 'pointer',
                  border: '1px solid #00000030',
                }}
                onClick={() => setIsExpanded((prev) => !prev)}
              >
                {isExpanded ? (
                  <KeyboardArrowUpOutlinedIcon sx={{ fontSize: '18px', color: 'primary.main' }} />
                ) : (
                  <KeyboardArrowDownOutlinedIcon sx={{ fontSize: '18px', color: 'primary.main' }} />
                )}
              </Stack>
              <Stack sx={{ flex: '1' }} direction="row" alignItems={'center'} gap="2px">
                {isExpanded ? (
                  <Typography
                    color="primary.main"
                    sx={{ cursor: 'pointer' }}
                    variant="p12"
                    onClick={() => setIsExpanded((prev) => !prev)}
                  >
                    Shrink
                  </Typography>
                ) : (
                  <>
                    <Typography
                      color="primary.main"
                      sx={{ cursor: 'pointer' }}
                      variant="p12"
                      onClick={() => setIsExpanded((prev) => !prev)}
                    >
                      Show {length - 2} more
                    </Typography>
                    <Typography variant="p12" color="neutral.n400">
                      emails from this thread
                    </Typography>
                  </>
                )}
              </Stack>
            </Stack>
            <EmailCard email={last(allMessages)} persons={allPersons} expandedProp />
          </>
        ) : (
          allMessages.map((singleMessage, index) => (
            <EmailCard
              email={singleMessage}
              persons={allPersons}
              key={`email_card_${index}`}
              expandedProp={index === allMessages.length - 1}
            />
          ))
        )}
      </Grid>
    </Grid>
  );
};

export default ThreadContainer;
