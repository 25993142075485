import {
  ConfigurationFields,
  ConfigurationList,
  ConfigurationResponse,
  TenantPayload,
  ThresholdPayload,
  ThresholdResponse,
} from 'store/duplicateConfig/types';
import { apiCall } from '../index';

export const getDuplicateConfigurationObjects = (params: string): Promise<ConfigurationList[]> =>
  apiCall({ method: 'get', url: `/duplicate-configuration/configuration-objects${params}` });

export const getDuplicateConfigurationFields = (params: string): Promise<ConfigurationFields[]> =>
  apiCall({ method: 'get', url: `/duplicate-configuration/configuration-fields${params}` });

export const createduplicationConfigApi = (data: TenantPayload) =>
  apiCall({ method: 'post', url: '/duplicate-configuration', data });

export const getConfigurationList = (id: string): Promise<ConfigurationResponse[]> =>
  apiCall({ method: 'get', url: `/duplicate-configuration/configuration-list/${id}` });

export const getDuplicationConfigurationById = (id: string): Promise<ConfigurationResponse> =>
  apiCall({ method: 'get', url: `/duplicate-configuration/configuration-list-details/${id}` });

export const updateConfigurationObject = (id: string, data: TenantPayload) =>
  apiCall({ method: 'put', url: `/duplicate-configuration/${id}`, data });

export const deleteConfigurationObject = (id: string) =>
  apiCall({ method: 'delete', url: `/duplicate-configuration/${id}` });

export const getConfigurationThreshold: (id: string) => Promise<ThresholdResponse> = (id: string) =>
  apiCall({ method: 'get', url: `/duplicate-configuration/configuration-threshold/${id}` });

export const createConfigurationThreshold = (data: ThresholdPayload): Promise<ConfigurationResponse> =>
  apiCall({ method: 'post', url: `/duplicate-configuration/threshold`, data });

export const updateConfigurationThreshold = (id: string, data: ThresholdPayload) =>
  apiCall({ method: 'put', url: `/duplicate-configuration/threshold/${id}`, data });
