import React from 'react';
import { Stack, Typography } from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

import format from 'date-fns/format';

import { isSameYear } from 'date-fns';
import ActivityCard from '../ActivityCard';
import { useGraphRender } from 'store/graphRender/hooks';

interface PeriodSectionProps {
  isCurrentMonth?: boolean;
  activities: any[];
  migrationId: string;
  node: any;
  contacts: any[];
}

const PeriodSection = ({ isCurrentMonth = false, activities = [], node, contacts }: PeriodSectionProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const {
    data: { nodeHashMap },
    setSelectedNode,
  } = useGraphRender();

  const handleActivityClick = (id: string, activity?: any) => {
    const hashKey = Object.keys(nodeHashMap).find((key) => key.includes(id));
    if (!hashKey) return;
    if (!activity) {
      setSelectedNode(nodeHashMap[hashKey]);
    } else {
      setSelectedNode({
        ...nodeHashMap[hashKey],
        data: {
          ...nodeHashMap[hashKey].data,
          ...activity,
        },
      });
    }
  };
  return (
    <Stack gap={1} my={1}>
      {!isCurrentMonth && (
        <Stack direction="row" alignItems="center">
          <Stack sx={{ width: '1px', border: '1px solid #00000020', mx: 3, position: 'relative' }}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                borderRadius: '100%',
                width: '30px',
                height: '30px',
                mx: 2,
                zIndex: 5,
                position: 'absolute',
                top: 0,
                transform: 'translate(-100%,-50%)',
                cursor: 'pointer',
                border: '1px solid #00000030',
                backgroundColor: isExpanded ? 'primary.subtone2' : 'white',
              }}
              onClick={() => setIsExpanded((prev) => !prev)}
            >
              {isExpanded ? (
                <KeyboardArrowUpOutlinedIcon sx={{ fontSize: '18px', color: 'primary.main' }} />
              ) : (
                <KeyboardArrowDownOutlinedIcon sx={{ fontSize: '18px', color: 'primary.main' }} />
              )}
            </Stack>
          </Stack>
          <Stack
            sx={{
              flex: '1',
              border: '1px solid #00000020',
              px: 2,
              py: 0.5,
              backgroundColor: isExpanded ? 'primary.subtone2' : 'white',
            }}
          >
            <Typography color="primary.main" variant="p14">
              {activities[0].CreatedDate &&
                format(
                  new Date(activities[0].CreatedDate),
                  isSameYear(new Date(activities[0].CreatedDate), new Date()) ? 'MMMM' : 'MMMM yyyy',
                )}
            </Typography>
          </Stack>
        </Stack>
      )}

      <Stack gap={1}>
        {(activities || [])
          .sort((a, b) => new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime())
          .slice(0, isExpanded ? activities.length : !isCurrentMonth ? 0 : 2)
          .map((activity) => (
            <ActivityCard
              key={activity?.id}
              activity={activity}
              node={node}
              contact={contacts.find((contact) => contact.Id === (activity.WhoId ? activity.WhoId : activity.WhatId))}
              handleClick={handleActivityClick}
            />
          ))}
      </Stack>
      {isCurrentMonth && activities.length > 2 && (
        <Stack direction="row" alignItems="center">
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: '100%',
              width: '30px',
              height: '30px',
              bgcolor: 'white',
              mx: 2,
              transform: 'translateX(-25%)',
              cursor: 'pointer',
              border: '1px solid #00000030',
            }}
            onClick={() => setIsExpanded((prev) => !prev)}
          >
            {isExpanded ? (
              <KeyboardArrowUpOutlinedIcon sx={{ fontSize: '18px', color: 'primary.main' }} />
            ) : (
              <KeyboardArrowDownOutlinedIcon sx={{ fontSize: '18px', color: 'primary.main' }} />
            )}
          </Stack>
          {activities.length > 2 && (
            <Stack sx={{ flex: '1' }} direction="row" alignItems={'center'} gap="2px">
              {isExpanded ? (
                <Typography
                  color="primary.main"
                  sx={{ cursor: 'pointer' }}
                  variant="p12"
                  onClick={() => setIsExpanded((prev) => !prev)}
                >
                  Shrink
                </Typography>
              ) : (
                <>
                  <Typography
                    color="primary.main"
                    sx={{ cursor: 'pointer' }}
                    variant="p12"
                    onClick={() => setIsExpanded((prev) => !prev)}
                  >
                    Show {activities.length - 2} more
                  </Typography>
                  <Typography variant="p12" color="neutral.n400">
                    events from this month
                  </Typography>
                </>
              )}
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default PeriodSection;
